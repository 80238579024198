export const InterviewRecordingEnglish = {
  title: 'Interview Recordings',
  browserNotSupported: 'Your browser does not support the video tag.',
  searchTranscripts: 'Search transcripts',
  noMatchingTranscripts: 'No matching transcripts found.',
  noTranscripts: 'No transcripts available.',
  noRecording: 'No recording available.',
  interviewRecording: 'Interview Recording',
  transcripts: 'Transcripts',
  summary: 'Summary',
  interviewQuestions: 'Interview Questions',
  noInterviewQuestions: 'No interview questions available.',
  speakerTalkTimes: 'Speaker Talk Times',
  downloadTranscripts: 'Download Transcripts',
  noTranscriptsToDownload: 'No transcripts available to download.',
  aiNotetaker: 'AI Notetaker',
  startRecording: 'Start Recording',
  showRecording: 'Show Recording',
  pauseRecording: 'Pause recording',
  resumeRecording: 'Resume recording',
  stopRecording: 'Stop recording',
  noteTaker: 'Note Taker',
  talkTime: 'Talk time'
}

export const InterviewRecordingArabic = {
  title: 'تسجيلات المقابلة',
  browserNotSupported: 'المتصفح الخاص بك لا يدعم عنصر الفيديو.',
  searchTranscripts: 'البحث في النصوص',
  noMatchingTranscripts: 'لا توجد نصوص مطابقة للبحث.',
  noTranscripts: 'لا توجد نصوص متاحة.',
  noRecording: 'لا يوجد تسجيل متاح.',
  interviewRecording: 'تسجيل المقابلة',
  transcripts: 'النصوص',
  summary: 'الملخص',
  interviewQuestions: 'أسئلة المقابلة',
  noInterviewQuestions: 'لا توجد أسئلة مقابلة متاحة.',
  speakerTalkTimes: 'أوقات تحدث المتحدثين',
  downloadTranscripts: 'تنزيل النصوص',
  noTranscriptsToDownload: 'لا تتوفر نصوص للتنزيل.',
  aiNotetaker: 'مدون الملاحظات الذكي',
  startRecording: 'بدء التسجيل',
  showRecording: 'عرض التسجيل',
  pauseRecording: 'إيقاف التسجيل مؤقتًا',
  resumeRecording: 'استئناف التسجيل',
  stopRecording: 'إيقاف التسجيل',
  noteTaker: 'مُدَوِّن الملاحظات',
  talkTime: 'وقت التحدث'
}
