export const NotificationPreferencesEnglish = {
  title: 'Notification preferences',
  subTitle: 'Get notified on your recruitment activities on candidates you own or jobs you own',
  tooltipText: 'Click here to learn more about notification preferences',
  emails: 'Emails',
  inbox: 'Inbox',
  homepage: 'Homepage',
  dailyDigest: {
    title: 'Daily email digest',
    description: 'Receive a daily email each morning summarizing your activities from the last 24 hours and today, and view them on your homepage',
    interviewReminder: 'Reminder for my interviews today',
    pendingScoreReminder: 'Reminder for pending interview feedbacks',
    updatedOfJobsWatchlist: 'Get updates on my job watchlist',
    unreadNotifications: 'Unread notifications about recruitment activity',
    latestReleases: 'New releases on platform in last 24 hrs'
  },
  notifications: {
    title: 'Notifications',
    description: 'Get notified on your recruitment activities on candidates you own or jobs you own'
  },
  interviewsAndScores: {
    title: 'Interviews and feedbacks',
    submittedInterviewScores: 'Someone submitted interview feedbacks for the candidate you own', // TODO Add 'or follow' after followers feature
    someoneAddedRemovedAsAttendee: 'Someone made or removed you as an attendee',
    someoneScheduledCancelledInterviewToCandidate: 'Someone scheduled or cancelled an interview for the candidate you own' // TODO Add 'or follow' after followers feature
  },
  notes: {
    title: 'Notes',
    someoneMentionedYouInNote: 'Someone mentioned you in a note',
    someoneAddedNotesToYouCandidate: 'Someone added a note for the candidate you own' // TODO Add 'or follow' after followers feature
  },
  emailsTab: {
    title: 'Emails',
    someoneSentMailToYourCandidate: 'Someone sent an email to the candidate you own' // TODO Add 'or follow' after followers feature
  },
  offersTab: {
    title: 'Offers',
    signedOrRejected: 'Candidate signed or rejected the offer letter you sent'
  },
  others: {
    title: 'Others',
    addedCandidateOwner: 'Someone assigned or removed you as a candidate owner',
    addedOrRemovedJobOwner: 'Someone added or removed you as a job owner',
    addedOrRemovedCollaborator: 'Someone added or removed you as a job collaborator'
  },
  successMessages: {
    saved: 'Successfully saved'
  }
}

export const NotificationPreferencesArabic = {
  title: 'تفضيلات الإشعارات',
  subTitle: 'الحصول على إشعارات حول أنشطة التوظيف الخاصة بالمرشحين الذين تمتلكهم أو الوظائف التي تمتلكها.',
  tooltipText: 'انقر هنا لمعرفة المزيد حول تفضيلات الإشعارات',
  emails: 'Emails',
  inbox: 'Inbox',
  homepage: 'الصفحة الرئيسية',
  dailyDigest: {
    title: 'ملخص البريد الإلكتروني اليومي',
    description: 'استلام بريد إلكتروني يومي كل صباح يلخص نشاطاتك خلال الـ 24 ساعة الماضية واليوم، وعرضها على صفحتك الرئيسية',
    interviewReminder: 'تذكير بمقابلاتي اليوم',
    pendingScoreReminder: 'تذكير للتعليقات المعلقة للمقابلة',
    updatedOfJobsWatchlist: 'الحصول على تحديثات على قائمة مراقبة الوظائف الخاصة بي',
    unreadNotifications: 'الإشعارات غير المقروءة حول نشاط التوظيف',
    latestReleases: 'الإصدارات الجديدة على المنصة في الـ 24 ساعة الأخيرة'
  },
  notifications: {
    title: 'الإشعارات',
    description: 'احصل على إشعارات حول أنشطتك في التوظيف على المرشحين الذين تمتلكهم أو الوظائف التي تمتلكها'
  },
  interviewsAndScores: {
    title: 'المقابلات والتعليقات',
    submittedInterviewScores: 'أرسل شخص ما تعليقات المقابلة للمرشح الذي تمتلكه',
    someoneAddedRemovedAsAttendee: 'قام شخص ما بإضافتك أو إزالتك كمشارك',
    someoneScheduledCancelledInterviewToCandidate: 'قام شخص ما بجدولة أو إلغاء مقابلة للمرشح الذي تمتلكه'
  },
  notes: {
    title: 'ملاحظات',
    someoneMentionedYouInNote: 'ذكرك شخص ما في مذكرة',
    someoneAddedNotesToYouCandidate: 'قام شخص ما بإضافة مذكرة للمرشح الذي تمتلكه'
  },
  emailsTab: {
    title: 'الرسائل البريدية',
    someoneSentMailToYourCandidate: 'قام شخص ما بإرسال بريد إلكتروني إلى المرشح الذي تمتلكه'
  },
  offersTab: {
    title: 'العروض',
    signedOrRejected: 'قام المرشح بتوقيع أو رفض رسالة العرض التي أرسلتها'
  },
  others: {
    title: 'الآخرين',
    addedCandidateOwner: 'قام شخص ما بتعيينك أو إزالتك كمالك للمرشح',
    addedOrRemovedJobOwner: 'قام شخص ما بإضافتك أو إزالتك كمالك للوظيفة',
    addedOrRemovedCollaborator: 'قام شخص ما بإضافتك أو إزالتك كشريك في العمل'
  },
  successMessages: {
    saved: 'تم الحفظ بنجاح'
  }
}
