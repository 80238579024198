export const userAssignmentEnglish = {
  title: 'Home assignment',
  jobDescription: 'Job description',
  additionalResources: 'Additional resources',
  timeGuidance: 'Home assignment time guidance',
  start: 'Once you start,',
  submission: 'Submission',
  setRemainder: 'Set a reminder',
  startAssignment: 'Start assignment',
  confirmation: 'Confirmation',
  confirmStart: 'I understand that I will have to submit my assignment in {time} hours after I begin.',
  pleaseConfirm: 'Please confirm to start the assignment.',
  beginAssignment: 'Begin assignment',
  days: 'days',
  hours: 'hours',
  min: 'min',
  timeout: 'Timeout',
  invitation: 'Invitation for GitLab repository',
  subjectFormat: 'Now, you must have received an email from GitLab with subject {subject}',
  acceptNow: 'You need to accept the invite to access the repository which contains the assignment. If you already accepted, please ignore this step.',
  mergeRequest: 'Creation of merge request with improvements',
  refresh: '(refresh to update)',
  confirm: 'Confirm below to notify the team',
  confirmSubmission: 'Confirm submission',
  autoSubmission: 'Assignment will be auto-submitted if the time runs out before you confirm.',
  notifyReviewteam: 'We’ve let the review team know that you’ve completed the home assignment.',
  requestExtension: 'To request an extension, please send an email to {email}.',
  infoToUser: 'You get evaluated based on the below points in this open-ended exercise.',
  timeLeft: 'Time left:',
  listItems: [
    '<span class=\'font-medium text-black\'>You will be sent an invitation to join the repository from GitLab with subject "GitLab Bot invited you to join GitLab".</span>',
    '<span class=\'font-medium text-black\'>You need to accept the invite to access the repository which contains the assignment.</span>',
    'Repository includes the evaluation criteria and other helpful instructions in the README.md file.',
    'You can make changes to the code base in the IDE of your preference.'
  ],
  createMergeRequest: 'You will create a merge request when you are ready to submit.',
  exerciseInfo: 'The exercise takes an average of {minTime} to complete, but you have {hours} hours to submit it.',
  reviewInDays: 'After your submission, you should be hearing back from us within {days} days.',
  evaluation: 'Evaluation',
  improvements: 'If you think of some improvements that are time-consuming and cannot be handled in the given time, you can skip them and share as comments.',
  notEvaluated: 'You do not get evaluated based on the time used for the assignment as long as you submit within the overall allotted time. We have allowed {hours} hours for this assignment but usually don’t expect a candidate to spend more than {time} on this assignment.',
  afterSubmission: 'After your submission, you should be hearing back from us within {days} days after submission.',
  goodLuck: 'Good luck!!',
  receivedSubmission: 'Your submission is received.',
  applicationFor: 'Your application for {role} is received',
  pleaseContact: '*If you face any technical issues, please contact {support}'
}

export const userAssignmentArabic = {
  title: 'مهمة المنزل',
  jobDescription: 'وصف الوظيفة',
  additionalResources: 'موارد إضافية',
  timeGuidance: 'إرشادات الوقت للمهمة المنزلية',
  start: 'بمجرد بدءك،',
  submission: 'تقديم',
  setRemainder: 'ضع تذكيرًا',
  startAssignment: 'بدء المهمة',
  confirmation: 'تأكيد',
  confirmStart: 'أنا أفهم أنه يجب علي تقديم مهمتي خلال {time} ساعة بعد بدء التنفيذ.',
  pleaseConfirm: 'يرجى تأكيد بدء المهمة.',
  beginAssignment: 'بدء المهمة',
  days: 'أيام',
  hours: 'ساعات',
  min: 'دقائق',
  timeout: 'انتهاء الوقت',
  invitation: 'دعوة لمستودع gitlab',
  subjectFormat: 'الآن، يجب أن تكون قد تلقيت بريدًا إلكترونيًا من GitLab بعنوان {subject}',
  acceptNow: 'تحتاج إلى قبول الدعوة للوصول إلى المستودع الذي يحتوي على المهمة. إذا قبلت بالفعل، يرجى تجاهل هذه الخطوة.',
  mergeRequest: 'إنشاء طلب دمج بتحسينات',
  refresh: '(تحديث للتحديث)',
  confirm: 'قم بتأكيد الأسفل لإخطار الفريق',
  confirmSubmission: 'تأكيد التقديم',
  autoSubmission: 'سيتم تقديم المهمة تلقائيًا إذا انتهى الوقت قبل تأكيدك.',
  notifyReviewteam: 'لقد أبلغنا فريق المراجعة بأنك قد أكملت مهمة المنزل.',
  requestExtension: 'لطلب تمديد، يرجى إرسال بريد إلكتروني إلى {email}.',
  infoToUser: 'سيتم تقييمك استنادًا إلى النقاط التالية في هذا التمرين المفتوح المنتهي.',
  timeLeft: 'الوقت المتبقي:',
  listItems: [
    '<b class=\'text-primary\' >سيتم إرسال دعوة لك للانضمام إلى المستودع من GitLab بعنوان "GitLab Bot invited you to join GitLab".</b>',
    '<b class=\'text-primary\'>تحتاج إلى قبول الدعوة للوصول إلى المستودع الذي يحتوي على المهمة.</b>',
    'يتضمن المستودع معايير التقييم وتعليمات أخرى مفيدة في ملف README.md.',
    'يمكنك إجراء تغييرات على قاعدة الشفرة في بيئة تطوير مفضلة لديك.'
  ],
  createMergeRequest: 'سوف تقوم بإنشاء طلب دمج عندما تكون جاهزًا لتقديمه.',
  exerciseInfo: 'يستغرق التمرين متوسطًا {minTime} لإكماله، ولكن لديك {hours} ساعة لتقديمه.',
  reviewInDays: 'بعد تقديمك، يجب أن نعاود الاستماع إليك خلال {days} أيام.',
  evaluation: 'التقييم',
  improvements: 'إذا كنت تفكر في بعض التحسينات التي تستغرق وقتًا ولا يمكن التعامل معها في الوقت المحدد، يمكنك تخطيها ومشاركتها كتعليقات.',
  notEvaluated: 'لا يتم تقييمك استنادًا إلى الوقت المستخدم للمهمة طالما أنك تقدمها خلال الوقت المحدد بشكل عام. لقد سمحنا بـ {hours} ساعة لهذه المهمة ولكن عادةً لا نتوقع من المرشح أن ينفق أكثر من {time} على هذه المهمة.',
  afterSubmission: 'بعد تقديمك، يجب أن نعاود الاستماع إليك خلال {days} أيام بعد التقديم.',
  goodLuck: 'حظًا سعيدًا!!',
  receivedSubmission: 'تم استلام تقديمك.',
  applicationFor: 'تم استلام طلبك لـ {role}',
  pleaseContact: '*إذا واجهت أي مشاكل تقنية، يرجى الاتصال بـ {support}'
}
