import request from '@/utils/request'
import { IInviteNewMember } from '@/views/dashboard/dashboard-model'

export const getUserInfo = () =>
  request({
    url: '/users/me',
    method: 'get'
  })

export const createUser = async(data: IInviteNewMember) => {
  return request({
    url: '/auth/company/createUser',
    method: 'post',
    data: data
  })
}

export const sendUserInviteAgain = (userId: string, partner: string) => {
  return request({
    url: '/auth/resend-invite/' + userId + '/' + partner,
    method: 'post'
  })
}

export const register = (data: any) => {
  return request({
    url: '/auth/register',
    method: 'post',
    data
  })
}

export const getOrCreate = (data: any) =>
  request({
    url: '/auth/candidate/get-or-create',
    method: 'post',
    data
  })

export const verifyEmail = (data) =>
  request({
    url: '/auth/user/verify',
    method: 'post',
    data
  })

export const verifyOTP = (data) =>
  request({
    url: '/auth/otp/login',
    method: 'post',
    data
  })

export const publicInfo = (userId) =>
  request({
    url: '/auth/info/user/' + userId,
    method: 'get'
  })
