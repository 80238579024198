
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'WcIconPencilSparkle'
})
export default class extends Vue {
  @Prop({ default: '#ffffff' }) color!: String;
  @Prop({ default: '20' }) width!: string
  @Prop({ default: '20' }) height!: string
  @Prop({ default: '1.5' }) strokeWidth!: string
}
