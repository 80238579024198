import Vue from 'vue'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import VueI18n from 'vue-i18n'
import elementEnLocale from 'element-ui/lib/locale/lang/en' // element-ui lang
import elementAeLocale from './element/ar'
import enLocale from './en'
import aeLocale from './ar'
import { LanguageEnum } from '@/store/modules/language'
import { getLanguage } from '@/utils/cookies'

Vue.use(VueI18n)

const messages = {
  en: {
    ...enLocale,
    ...elementEnLocale
  },
  ar: {
    ...aeLocale,
    ...elementAeLocale
  }
}

export function getLocalLanguage() {
  const chooseLanguage = getLanguage()
  if (chooseLanguage) {
    return chooseLanguage
  }

  // if it has not chosen language
  const language = (navigator.language).toLowerCase()
  const locales = Object.keys(messages)
  for (const locale of locales) {
    if (language.indexOf(locale) > -1) {
      return locale
    }
  }
  return LanguageEnum.ENGLISH
}
const i18n = new VueI18n({
  // set locale
  // options: en | ru | vi | zh
  locale: getLocalLanguage(),
  // set locale messages
  messages,
  fallbackLocale: 'en',
  dateTimeFormats: {
    en: {
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      },
      long: {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        weekday: 'long',
        hour: 'numeric',
        minute: 'numeric'
      }
    },
    ar: {
      short: {
        year: 'numeric',
        month: 'numeric', // or 'short'/'long' for textual representation
        day: 'numeric'
      },
      long: {
        year: 'numeric',
        month: 'long', // for full month name in Arabic
        day: 'numeric',
        weekday: 'long', // for full weekday name in Arabic
        hour: 'numeric',
        minute: 'numeric',
        hour12: true // or false for 24-hour format
      }
    }
  },
  numberFormats: {
    en: {
      currency: {
        style: 'currency',
        currency: 'USD',
        currencyDisplay: 'symbol' // could also be 'code' or 'name'
      },
      decimal: {
        style: 'decimal',
        minimumFractionDigits: 2 // you can vary this
      },
      percent: {
        style: 'percent',
        minimumFractionDigits: 2 // can be adjusted
      }
    },
    ar: {
      currency: {
        style: 'currency',
        currency: 'AED', // United Arab Emirates Dirham, change as needed
        currencyDisplay: 'symbol',
        notation: 'compact' // optional, for compact number representation
      },
      decimal: {
        style: 'decimal',
        minimumFractionDigits: 2,
        useGrouping: true // uses grouping separators, e.g., thousands
      },
      percent: {
        style: 'percent',
        minimumFractionDigits: 2
      }
    }
  }
})

export default i18n
