import Vue from 'vue'

import 'normalize.css'
import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale/lang/en'
import vueDebounce from 'vue-debounce'
import '@/styles/element-variables.scss'
import '@/styles/index.scss'
import '@/styles/_variables.scss'

import App from '@/App.vue'
import store from '@/store'
import router from '@/router'
import '@/assets/tailwind.css'
import '@/permission'
import vueAwesomeCountdown from 'vue-awesome-countdown'
import { PrismEditor } from 'vue-prism-editor'
import { configure, extend, ValidationObserver, ValidationProvider } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import './registerServiceWorker'
import { messages } from 'vee-validate/dist/locale/en.json'
import DashboardPlugin from './plugins/dashboard-plugin'
import VueMeta from 'vue-meta'
import i18n from '@/lang'
import { getSize } from '@/utils/cookies'
import { formatDate } from '@/utils/date'
import VueTelInput from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'
import './smartlook'
import { IJobCollaboarationPermission, UserModule } from '@/store/modules/user'
import { IJobPermission } from '@/permissions/models'
import { checkPermissionMatching } from '@/store/modules/permission'

Object.keys(rules).forEach(rule => {
  extend(rule, {
    ...rules[rule], // copies rule configuration
    message: messages[rule] // assign message
  })
})

configure({
  classes: {
    valid: 'is-valid',
    invalid: 'is-invalid',
    dirty: ['is-dirty', 'is-dirty'] // multiple classes per flag!
  }
})

Vue.component('PrismEditor', PrismEditor)
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

Vue.directive('full-url', {
  bind: function(el) {
    const href = el.getAttribute('href')
    if (href && !href.startsWith('http://') && !href.startsWith('https://')) {
      el.setAttribute('href', 'http://' + href)
    }
  }
})

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})

Vue.config.productionTip = false

Vue.use(vueDebounce)
// Listening to multiple events
Vue.use(vueDebounce, {
  listenTo: ['input', 'keyup']
})

// Setting a default timer This is set to '300ms' if not specified
Vue.use(vueDebounce, {
  defaultTime: '1000ms'
})
Vue.use(vueAwesomeCountdown, 'Vac')
Vue.use(ElementUI, { locale })
Vue.use(DashboardPlugin)
Vue.use(VueTelInput)

Vue.config.productionTip = false

Vue.filter('formatDateTime', function(value): string {
  return formatDate(value, 'MMM DD, YYYY h:mm A')
})

Vue.filter('formatDateToLocaleTime', function(value): string {
  return formatDate(value, 'MMM D, YY [at] h:mm A')
})

Vue.filter('monthDayYear', function(value): string {
  return formatDate(value, 'MMM DD, YY')
})

Vue.filter('shorten', function(value1, value2): string {
  return value1.length >= 60 && value2 ? `${value1.slice(0, 60)}...` : value1
})

Vue.use(ElementUI, {
  size: getSize() || 'medium', // set element-ui default size
  i18n: (key, value) => i18n.t(key, value)
})

Vue.directive('permissions', {
  inserted(el: HTMLElement, binding: { value: string[] }) {
    const requiredPermissions: string[] = binding.value
    if (requiredPermissions.length === 1 && requiredPermissions[0] === 'ALLOW') {
      return true
    }
    // IF required permission is PermissionEnum.COMPANY_JOB_EDIT ( COMPANY::JOB::EDIT ) here it will break into [COMPANY::JOB::* , COMPANY::JOB::EDIT]
    const userPermissions: string[] = UserModule.permissionV1
    const hasPermission = requiredPermissions.reduce((previousValue, permission) => {
      return previousValue || checkPermissionMatching(userPermissions, permission)
    }, false)

    if (!hasPermission) {
      const parentNode = el.parentNode as HTMLElement
      parentNode.removeChild(el)
    }
  }
})

Vue.directive('job-permission', {
  inserted(el: HTMLElement, binding: { value: IJobPermission }) {
    const { permissions, jobRoleId } = binding.value

    const userPermissions: string[] = UserModule.permissionV1
    let hasPermission: boolean = permissions.reduce((previousValue: boolean, permission: string) => {
      return previousValue || checkPermissionMatching(userPermissions, permission)
    }, false)

    if (!hasPermission) {
      const collaboratorPermissions: IJobCollaboarationPermission[] = UserModule.jobCollaborationPermissionsV1
      const jobPermission: IJobCollaboarationPermission | undefined = collaboratorPermissions.find((each) => each.jobRoleId === jobRoleId)
      if (jobPermission) {
        hasPermission = permissions.reduce((previousValue: boolean, permission: string) => {
          return previousValue || checkPermissionMatching(jobPermission.permissions, permission)
        }, false)
      }
    }

    if (!hasPermission) {
      const parentNode = el.parentNode as HTMLElement
      parentNode.removeChild(el)
    }
  }
})

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app')
