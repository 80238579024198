
import { Vue, Component, Prop } from 'vue-property-decorator'
import WcIconFilter from '@/components/UILibrary/Icons/WcIconFilter.vue'
import WcTypography from '@/components/UILibrary/Typography/WcTypography.vue'
import { InboxQuery } from '@/views/home-page/inbox/models/inbox.entity'
import WcTooltip from '@/components/UILibrary/WcTooltip.vue'
import WcIconDataTransfer from '@/components/UILibrary/Icons/WcIconDataTransfer.vue'

@Component({
  components: { WcIconDataTransfer, WcTooltip, WcTypography, WcIconFilter }
})
export default class PrimaryFilter extends Vue {
    @Prop({ required: true }) query!: InboxQuery

    handleChange(): void {
      this.$emit('handle-change')
    }
}
