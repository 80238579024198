export const MobileNavbarMenuArabic = {
  menu: 'القائمة',
  jobs: 'الوظائف',
  candidates: 'المرشحون',
  interviews: 'مقابلات التوظيف',
  referrals: 'الترشيحات والتزكية',
  employeePortal: 'بوابة الموظف'
}

export const MobileNavbarMenuEnglish = {
  menu: 'Menu',
  jobs: 'Jobs',
  candidates: 'Candidates',
  interviews: 'Interviews',
  referrals: 'Referrals',
  employeePortal: 'Employee portal'
}
