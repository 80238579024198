import Smartlook from 'smartlook-client'
import { isProduction } from '@/utils/constants'

const init = (): void => {
  if (!isProduction) {
    return
  }
  Smartlook.init(process.env.VUE_APP_SMART_LOOK_ID!)
}

export function setSmartlookUser(email: string, properties: {[p: string]: string | number | boolean}): void {
  Smartlook.record({
    emails: true,
    forms: true,
    numbers: true,
    api: true,
    ips: true
  })

  Smartlook.identify(email, properties)
}

init()
