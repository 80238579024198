
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'tooltip'
})
export default class extends Vue {
  @Prop({ type: String, default: 'top' }) placement!: 'top' | 'bottom' | 'left' | 'right'
  @Prop({ type: Boolean, default: false }) disable!: boolean
}
