<template>
  <Tooltip>
    <template #parent>
      <slot ></slot>
    </template>
    <template #content>
      {{text}}
    </template>
  </Tooltip>
</template>

<script>
import Tooltip from '@/components/UILibrary/tooltip.vue'

export default {
  components: { Tooltip },
  props: {
    text: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
 * {
   margin: 0;
   padding: 0;
 }
</style>
