
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'loading-animation'
})
export default class extends Vue {
  @Prop({ default: '5' }) height!: string
  @Prop({ default: '5' }) weight!: string
}
