import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import store from '@/store'
import { IUserManagement } from '@/views/recruiter/models/user-management'
import { RolesEnum } from '@/views/recruiter/models/roles-model'
import CompanyRolesResource from '@/api/companyRoles'

export interface IRecruiters {
  recruiters: IUserManagement[]
}

@Module({ dynamic: true, store, name: 'recruiters', namespaced: true })
class Recruiters extends VuexModule implements IRecruiters {
  recruiters: IUserManagement[] = []

  @Mutation
  public SET_RECRUITERS(recruiters: IUserManagement[]) {
    this.recruiters = recruiters
  }

  @Action
  async fetchRecruiters() {
    try {
      const companyRolesResource = new CompanyRolesResource()
      const recruiters = `${RolesEnum.COMPANY_ADMIN},${RolesEnum.COMPANY_INTERVIEWER}`
      return (await companyRolesResource.companyUsers('?accessType=' + recruiters)).data as IUserManagement[]
    } catch (e) {
      return []
    }
  }

  @Action
  public async setRecruiters(force = false) {
    try {
      if (force || this.recruiters.length === 0) {
        const rec = await this.context.dispatch('fetchRecruiters')

        this.context.commit('SET_RECRUITERS', rec)
      }
    } catch (error) {
    }
  }

  get getRecruiters(): IUserManagement[] {
    return this.recruiters
  }
}

export const RecruitersModule = getModule(Recruiters)
