
import { Component, Prop, Vue } from 'vue-property-decorator'
@Component({
  name: 'WcIconCalendarCircle',
  components: {
  }
})
export default class extends Vue {
  @Prop({ default: '#000000' }) private color!: String
}
