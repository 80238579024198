// A plugin file where you could register global components used across the app
import GlobalComponents from './global-components'
// A plugin file where you could register global directives
import GlobalDirectives from './global-directives'

// element ui language configuration
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'

// asset imports
import '@/assets/sass/custom.scss'
import { extend, configure } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import { messages } from 'vee-validate/dist/locale/en.json'
import { isNumber } from 'chart.js/helpers'
locale.use(lang)

Object.keys(rules).forEach(rule => {
  extend(rule, {
    ...rules[rule], // copies rule configuration
    message: messages[rule] // assign message
  })
})
extend('phoneNumber', {
  computesRequired: true,
  validate: value => {
    let digits = 0
    for (let i = value.length - 1; i >= 0; i--) {
      const d = value.charCodeAt(i)
      if (!(d < 48 || d > 57)) {
        digits++
      }
    }
    return digits >= 5
  },
  message: 'Please enter a valid phone number'
})

extend('minSalary', {
  computesRequired: true,
  validate: value => {
    return value >= 1
  },
  message: 'Please enter a valid input'
})

extend('noFalse', {
  computesRequired: true,
  validate: value => {
    return value === true
  },
  message: 'Please confirm'
})

extend('url', {
  computesRequired: true,
  validate: value => {
    if (!value) {
      return true
    }
    const res = value.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g)
    return res !== null
  },
  message: 'Please enter a valid url'
})

extend('linkedin', {
  computesRequired: true,
  validate: value => {
    if (!value) {
      return true // Field is required if empty
    }

    // Modify the regular expression to make 'http://' or 'https://' optional
    const regex = /^(https?:\/\/)?(www\.)?linkedin\.com\/in\/[a-zA-Z0-9-]+\/?$/

    return regex.test(value)
  },
  message: 'Please enter a valid LinkedIn url'
})

extend('only-alphabet-space', {
  computesRequired: true,
  validate: value => {
    if (!value) {
      return true
    }
    const regex = /^[a-zA-Z][a-zA-Z\s]*[a-zA-Z]$/
    return (regex.test(value))
  },
  message: 'Should contain only alphabets and spaces'
})

extend('only-alphabet-number-space', {
  computesRequired: true,
  validate: value => {
    if (!value) {
      return true
    }
    const regex = /^[a-zA-Z0-9][a-zA-Z0-9\s]*[a-zA-Z0-9]$/
    return regex.test(value)
  },
  message: 'Should contain only alphabets, numbers and spaces'
})

extend('max-10', {
  computesRequired: true,
  validate: value => {
    return (value <= 10)
  },
  message: 'Maximum 10 questions allowed'
})

extend('is-number', {
  computesRequired: true,
  validate: value => {
    return (isNumber(value) && value >= 0)
  },
  message: 'Enter a valid number'
})

extend('max30', {
  computesRequired: true,
  validate: value => {
    if (!value) {
      return true // If the value is empty, it's considered valid (required rule will handle this)
    }
    const len = value.length
    return len <= 30 // Return true if the length is less than or equal to 30 characters
  },
  message: 'Maximum 30 characters.'
})

export default {
  install(Vue) {
    Vue.use(GlobalComponents)
    Vue.use(GlobalDirectives)
    configure({
      classes: {
        valid: 'is-valid',
        invalid: 'is-invalid',
        dirty: ['is-dirty', 'is-dirty'] // multiple classes per flag!
      }
    })
  }
}
