export default {
  el: {
    colorpicker: {
      confirm: 'موافق',
      clear: 'مسح'
    },
    datepicker: {
      now: 'الآن',
      today: 'اليوم',
      cancel: 'إلغاء',
      clear: 'مسح',
      confirm: 'موافق',
      selectDate: 'اختر التاريخ',
      selectTime: 'اختر الوقت',
      startDate: 'تاريخ البدء',
      startTime: 'وقت البدء',
      endDate: 'تاريخ الانتهاء',
      endTime: 'وقت الانتهاء',
      prevYear: 'السنة السابقة',
      nextYear: 'السنة التالية',
      prevMonth: 'الشهر السابق',
      nextMonth: 'الشهر التالي',
      year: '',
      month1: 'يناير',
      month2: 'فبراير',
      month3: 'مارس',
      month4: 'أبريل',
      month5: 'مايو',
      month6: 'يونيو',
      month7: 'يوليو',
      month8: 'أغسطس',
      month9: 'سبتمبر',
      month10: 'أكتوبر',
      month11: 'نوفمبر',
      month12: 'ديسمبر',
      week: 'أسبوع',
      weeks: {
        sun: 'الأحد',
        mon: 'الإثنين',
        tue: 'الثلاثاء',
        wed: 'الأربعاء',
        thu: 'الخميس',
        fri: 'الجمعة',
        sat: 'السبت'
      },
      months: {
        jan: 'يناير',
        feb: 'فبراير',
        mar: 'مارس',
        apr: 'أبريل',
        may: 'مايو',
        jun: 'يونيو',
        jul: 'يوليو',
        aug: 'أغسطس',
        sep: 'سبتمبر',
        oct: 'أكتوبر',
        nov: 'نوفمبر',
        dec: 'ديسمبر'
      }
    },
    select: {
      loading: 'جار التحميل',
      noMatch: 'لا توجد بيانات مطابقة',
      noData: 'لا توجد بيانات',
      placeholder: 'اختر'
    },
    cascader: {
      noMatch: 'لا توجد بيانات مطابقة',
      loading: 'جار التحميل',
      placeholder: 'اختر',
      noData: 'لا توجد بيانات'
    },
    pagination: {
      goto: 'اذهب إلى',
      pagesize: '/صفحة',
      total: 'الإجمالي {total}',
      pageClassifier: ''
    },
    messagebox: {
      title: 'رسالة',
      confirm: 'موافق',
      cancel: 'إلغاء',
      error: 'إدخال غير قانوني'
    },
    upload: {
      deleteTip: 'اضغط حذف للإزالة',
      delete: 'حذف',
      preview: 'معاينة',
      continue: 'استمر'
    },
    table: {
      emptyText: 'لا توجد بيانات',
      confirmFilter: 'تأكيد',
      resetFilter: 'إعادة تعيين',
      clearFilter: 'الكل',
      sumText: 'المجموع'
    },
    tree: {
      emptyText: 'لا توجد بيانات'
    },
    transfer: {
      noMatch: 'لا توجد بيانات مطابقة',
      noData: 'لا توجد بيانات',
      titles: ['قائمة 1', 'قائمة 2'], // to be translated
      filterPlaceholder: 'أدخل الكلمة الرئيسية', // to be translated
      noCheckedFormat: '{total} عناصر', // to be translated
      hasCheckedFormat: '{checked}/{total} محدد' // to be translated
    },
    image: {
      error: 'فشل'
    },
    pageHeader: {
      title: 'الرجوع' // to be translated
    },
    popconfirm: {
      confirmButtonText: 'نعم',
      cancelButtonText: 'لا'
    },
    empty: {
      description: 'لا توجد بيانات'
    }
  }
}
