import { Route, RouteConfig } from 'vue-router'
import Layout from '@/layout/index.vue'
import { isValidUUID } from '@/utils/general'

export const CANDIDATE_ROUTES: RouteConfig[] = [
  {
    path: '/candidate-journey/:userId/:roleId/:jobApplicationId/:stageId/:moduleId/video-record',
    component: () => import(/* webpackChunkName: "videoRecord" */ '@/views/candidate-journey/video-interview/record.vue'),
    meta: {
      hidden: true,
      title: 'Video Record'
    },
    beforeEnter: (to: Route, from: Route, next): void => {
      const { moduleId, userId, roleId, jobApplicationId, stageId } = to.params

      if ([moduleId, userId, roleId, jobApplicationId, stageId].every(param => isValidUUID(param))) {
        next() // All params are valid UUIDs, allow access to the route
      } else {
        next({ path: '/404' }) // Redirect to 404 page if any param is not a valid UUID
      }
    }
  },
  {
    path: '/profile-builder/role/:roleId/user/:userId',
    component: () => import(/* webpackChunkName: "profileBuilder" */ '@/views/profile-builder/index.vue'),
    meta: {
      hidden: true,
      title: 'You deserve the best',
      icon: 'dashboard'
    }
  },
  {
    path: '/profile-builder/role/:roleId/user/:userId/application/:applicationId/offer/:offerId',
    component: () => import(/* webpackChunkName: "candidateOffer" */ '@/views/profile-builder/offer/CandidateOffer.vue'),
    meta: {
      hidden: true,
      title: 'Congratulations. You got the offer.',
      icon: 'dashboard'
    }
  },
  {
    path: '/profile-builder/role/job-not-found',
    component: () => import(/* webpackChunkName: "jobNotFound" */ '@/views/profile-builder/job-not-found.vue'),
    meta: {
      hidden: true,
      title: 'Sorry :(',
      icon: 'dashboard'
    }
  },
  {
    path: '/quiz',
    component: Layout,
    redirect: '/dashboard',
    meta: { hidden: true },
    children: [
      {
        path: ':quizType/user/:userId/role/:jobRoleId/:jobApplicationId/:stageId/:moduleId',
        component: () => import(/* webpackChunkName: "quizHome" */ '@/views/quiz/index.vue'),
        meta: {
          hidden: true,
          title: 'You deserve the best',
          icon: 'dashboard'
        }
      }
    ]
  },
  {
    path: '/user/assignment',
    component: Layout,
    redirect: '/dashboard',
    meta: { hidden: true },
    children: [
      {
        path: 'role/:roleId/user/:userId/:jobApplicationId/:stageId/:moduleId',
        component: () => import(/* webpackChunkName: "assignmentProgress" */ '@/views/user-assignment/UserAssignments.vue'),
        meta: {
          hidden: true,
          title: 'You deserve the best',
          icon: 'dashboard'
        }
      }
    ]
  },
  {
    path: '/company/assignment',
    component: Layout,
    redirect: '/dashboard',
    meta: { hidden: true },
    children: [
      {
        path: 'role/:roleId/user/:userId',
        component: () => import(/* webpackChunkName: "assignmentLanding" */ '@/views/company-assignment-landing/index.vue'),
        meta: {
          hidden: true,
          title: 'You deserve the best',
          icon: 'dashboard'
        }
      }
    ]
  },
  {
    path: '/reminder',
    component: Layout,
    redirect: '/dashboard',
    meta: { hidden: true },
    children: [
      {
        path: 'role/:jobRoleId/:jobApplicationId/:stageId',
        component: () => import(/* webpackChunkName: "reminderHome" */ '@/views/set-a-reminder/index.vue'),
        meta: {
          hidden: true,
          title: 'You deserve the best',
          icon: 'dashboard'
        }
      }
    ]
  }
]
