import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import store from '@/store'

export enum LanguageEnum {
  ENGLISH = 'en',
  ARABIC = 'ar',
}

@Module({ dynamic: true, store, name: 'language', namespaced: true })
class Language extends VuexModule {
  public language = LanguageEnum.ENGLISH

  @Mutation
  private SET_LANGUAGE(language: LanguageEnum) {
    this.language = language
  }

  @Action
  public setLanguage(language: LanguageEnum) {
    this.SET_LANGUAGE(language)
  }
}
export const LanguageModule = getModule(Language)
