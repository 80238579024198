import { render, staticRenderFns } from "./WcIconOffer.vue?vue&type=template&id=0067c216&scoped=true"
import script from "./WcIconOffer.vue?vue&type=script&lang=ts"
export * from "./WcIconOffer.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0067c216",
  null
  
)

export default component.exports