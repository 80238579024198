export const RoleTypesEnumArabic = {
  fullTime: 'دوام كامل',
  partTime: 'دوام جزئي',
  contract: 'دوام بعقد',
  intern: 'تدريب فقط'
}

export const RoleTypesEnumEnglish = {
  fullTime: 'Full time',
  partTime: 'Part time',
  contract: 'Contract',
  intern: 'Intern'
}
