
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'WcIconTrash',
  components: {
  },
  props: {
    color: String
  }
})
export default class extends Vue {
  @Prop({ default: '#ffffff' }) color!: String;
  @Prop({ default: 16 }) private height!: number;
  @Prop({ default: 16 }) private width!: number;
}
