
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class LinesShimmer extends Vue {
  @Prop({ default: 3 }) readonly lines!: number;
  @Prop({ default: false }) readonly showAvatar!: boolean;
  @Prop({ default: 'rgba(0, 0, 0, 0.1)' }) readonly backgroundColor!: string;
  @Prop({ default: '1rem' }) readonly lineHeight!: string;

  get containerStyle() {
    return {
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
      backgroundColor: this.backgroundColor,
      padding: '1rem',
      borderRadius: '0.75rem'
    }
  }

  get lineStyle() {
    return {
      flex: 1,
      marginLeft: this.showAvatar ? '8px' : 0,
      backgroundColor: '#f6f7f8',
      borderRadius: '0.75rem',
      height: this.lineHeight
    }
  }
}
