
import { Component, Prop, Vue } from 'vue-property-decorator'
@Component({
  name: 'WcIconHamburger',
  components: {
  }
})
export default class extends Vue {
  @Prop({ default: '#09121F' }) private color!: String
}
