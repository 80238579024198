<template>
  <!-- This should be empty -->
  <div></div>
</template>

<script>
import { PseudoNotificationModule } from '@/store/modules/notification'

export default {
  name: 'PseudoNotificationComponent',
  computed: {
    notification() {
      return PseudoNotificationModule.notification
    }
  },
  watch: {
    notification(newNotification) {
      if (newNotification) {
        this.$notify({
          title: newNotification.title,
          message: newNotification.message,
          type: newNotification.type
        })
      }
    }
  }
}
</script>
