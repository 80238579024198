<template>
  <BButton
      :type="nativeType"
      :disabled="disabled || loading"
      @click="handleClick"
      class="base-button"
      :variant="!outline ? type : `outline-${type}`"
      :size="size"
      :block="block"
      @mouseenter="onMouseEnter"
      @mouseleave="onMouseLeave"
      :style="computedStyles"
      :class="computedClasses"
  >
    <slot name="loading">
      <i class="el-icon-loading" v-if="loading"></i>
    </slot>
    <slot></slot>
  </BButton>
</template>
<script>
export default {
  name: 'BaseButton',
  props: {
    round: Boolean,
    icon: Boolean,
    block: Boolean,
    loading: Boolean,
    wide: Boolean,
    disabled: Boolean,
    type: {
      type: String,
      default: 'default',
      description: 'Button type (primary|secondary|danger etc)'
    },
    color: {
      type: String,
      default: '#5138EE',
      description: 'Button type (primary|secondary|danger etc)'
    },
    nativeType: {
      type: String,
      default: 'button',
      description: 'Button native type (e.g button, input etc)'
    },
    size: {
      type: String,
      default: '',
      description: 'Button size (sm|lg)'
    },
    outline: {
      type: Boolean,
      description: 'Whether button is outlined (only border has color)'
    },
    link: {
      type: Boolean,
      description: 'Whether button is a link (no borders or background)'
    },
    hoverColor: {
      type: String,
      description: 'Color change on hover'
    }
  },
  methods: {
    handleClick(evt) {
      this.$emit('click', evt)
    },
    onMouseEnter(e) {
      if (this.outline && this.color !== 'default') {
        e.target.style.color = 'white'
        e.target.style.backgroundColor = this.color
      } else if (this.type === 'primary' && this.hoverColor) {
        e.target.style.backgroundColor = this.hoverColor
      }
    },
    onMouseLeave(e) {
      if (this.outline && this.color !== 'default') {
        e.target.style.color = this.color
        e.target.style.borderColor = this.color
        e.target.style.backgroundColor = 'transparent'
      } else if (this.type === 'primary' && this.hoverColor) {
        e.target.style.backgroundColor = this.color
      }
    }
  },
  computed: {
    computedStyles() {
      if (this.outline) {
        return { color: this.color, 'border-color': this.color }
      } else if (this.link) {
        return { 'background-color': 'transparent', color: this.color, 'border-color': 'transparent', 'font-size': '0.75rem' }
      } else if (this.color === 'danger') {
        return { 'background-color': '#F11D47', 'border-color': '#F11D47' }
      } else if (this.color !== 'default') {
        return { 'background-color': this.color, 'border-color': this.color }
      } else {
        return {}
      }
    },
    computedClasses() {
      return [
        { 'rounded-circle': this.round },
        { 'btn-wd': this.wide },
        { 'btn-icon btn-fab': this.icon },
        { 'btn-link': this.link },
        { disabled: this.disabled }
      ]
    }
  }
}
</script>
<style lang="scss">
.base-button {
  border-radius:10px;
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    padding: 0 3px;
  }
}
</style>
