
import { Component, Prop, Vue } from 'vue-property-decorator'
import { WhiteLabelPartnerEnum } from '@/views/dashboard/dashboard-model'
import WcTypography from '@/components/UILibrary/Typography/WcTypography.vue'

@Component({
  name: 'company-logo',
  components: { WcTypography },
  computed: {
    WhiteLabelPartnerEnum() {
      return WhiteLabelPartnerEnum
    }
  }
})
export default class extends Vue {
  @Prop({ default: '' }) private partner!: WhiteLabelPartnerEnum
  @Prop({ default: '' }) private text!: string
  @Prop({ default: false }) private isExtension!: boolean
}
