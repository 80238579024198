
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'WcIconSolidPlus',
  components: {
  },
  props: {
    color: String,
    height: String,
    width: String,
    opacity: String
  }
})
export default class extends Vue {
  @Prop({ default: '#5138EE' }) color!: string;
  @Prop({ default: '14' }) height!: string;
  @Prop({ default: '14' }) width!: string;
  @Prop({ default: '1' }) opacity!: string;
}
