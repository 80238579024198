<template>
  <ValidationProvider :rules="rules" :name="name" v-bind="$attrs" v-slot="{errors, valid, invalid, validated}">
    <BFormGroup>
      <slot name="label">
        <label v-if="label" :class="labelClasses">
          {{label}}
          <span v-if="required" class="">*</span>
        </label>
      </slot>
      <div :class="[
       {'input-group': hasIcon},
       {'focused': focused},
       {'input-group-alternative': alternative},
       {'has-label': label || $slots.label},
       inputGroupClasses
       ]">
        <div v-if="prependIcon || $slots.prepend" class="input-group-prepend">
        <span :class="'input-group-text ' + iconClasses">
          <slot name="prepend">
            <i :class="prependIcon"></i>
          </slot>
        </span>
        </div>
        <slot v-bind="slotData">
          <input
            :value="value"
            :type="type"
            v-on="listeners"
            v-bind="$attrs"
            :valid="valid"
            :required="required"
            class= "form-control"
            :class="[{'is-valid': valid && validated && successMessage}, {'is-invalid': invalid && validated}, inputClasses]"
          >
        </slot>

        <div v-if="appendIcon || $slots.append" class="input-group-append">
          <span class="input-group-text">
              <slot name="append">
                  <i :class="appendIcon"></i>
              </slot>
          </span>
        </div>
        <slot name="infoBlock"></slot>
      </div>
      <slot name="success">
        <div class="valid-feedback" v-if="valid && validated && successMessage">
          {{successMessage}}
        </div>
      </slot>
      <slot name="error">
        <div v-if="errors[0]" class="invalid-feedback" style="display: block;">
          {{ error || errors[0] }}
        </div>
      </slot>
    </BFormGroup>
  </ValidationProvider>
</template>
<script>
export default {
  inheritAttrs: false,
  name: 'BaseInput',
  props: {
    required: {
      type: Boolean,
      description: 'Whether input is required (adds an asterix *)'
    },
    group: {
      type: Boolean,
      description: 'Whether input is an input group (manual override in special cases)'
    },
    alternative: {
      type: Boolean,
      description: 'Whether input is of alternative layout'
    },
    label: {
      type: String,
      description: 'Input label (text before input)'
    },
    error: {
      type: String,
      description: 'Input error (below input)'
    },
    successMessage: {
      type: String,
      description: 'Input success message',
      default: ''
    },
    labelClasses: {
      type: String,
      description: 'Input label css classes',
      default: 'form-control-label'
    },
    inputClasses: {
      type: String,
      description: 'Input css classes'
    },
    iconClasses: {
      type: String,
      description: 'Prepend css classes'
    },
    inputGroupClasses: {
      type: String,
      description: 'Input group css classes'
    },
    value: {
      type: [String, Number],
      description: 'Input value'
    },
    type: {
      type: String,
      description: 'Input type',
      default: 'text'
    },
    appendIcon: {
      type: String,
      description: 'Append icon (right)'
    },
    prependIcon: {
      type: String,
      description: 'Prepend icon (left)'
    },
    rules: {
      type: [String, Array, Object],
      description: 'Vee validate validation rules',
      default: ''
    },
    name: {
      type: String,
      description: 'Input name (used for validation)',
      default: ''
    },
    // eslint-disable-next-line vue/no-reserved-props
    style: {
      type: Object,
      description: 'Custom styles for the input element'
    }
  },
  data() {
    return {
      focused: false
    }
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: this.updateValue,
        focus: this.onFocus,
        blur: this.onBlur
      }
    },
    slotData() {
      return {
        focused: this.focused,
        error: this.error,
        ...this.listeners
      }
    },
    hasIcon() {
      const { append, prepend } = this.$slots
      return (
        append !== undefined ||
          prepend !== undefined ||
          this.appendIcon !== undefined ||
          this.prependIcon !== undefined ||
          this.group
      )
    }
  },
  methods: {
    updateValue(evt) {
      const value = evt.target.value
      this.$emit('input', value)
    },
    onFocus(evt) {
      this.focused = true
      this.$emit('focus', evt)
    },
    onBlur(evt) {
      this.focused = false
      this.$emit('blur', evt)
    }
  }
}
</script>
<style>

input.form-control.jobtextbox{
  border-radius: 4px;
  width: 100%;
  padding: 0 15px;
  color:#010113 !important;
  font: 400 14px/20px 'Inter', sans-serif;
}
input.form-control.jobtextboxmultiline{
  border-radius: 4px;
  width:462px;
  padding: 0 15px;
  color:#010113 !important;
  font: 400 14px/20px 'Inter', sans-serif;
  height: 234px;
}

input.form-control.jobtextboxmultiline ::placeholder{
  display: flex;
  align-items: flex-start;
}

.jlabel label.form-control-label{
  font: 600 16px/24px 'Inter', sans-serif;
  color: #010113;
  margin-top: 24px;
  margin-bottom: 8px;
}

input#example-color-input{
  border: 0.75px solid rgba(4, 4, 16, 0.38);
  border-radius: 10px;
}

.jlabel .form-group {
    margin: 0 !important;
}

</style>
