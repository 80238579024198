export const VideoConferencingIntegrationArabic = {
  title: 'دمج مؤتمرات الفيديو',
  desc: 'اختر التطبيقات التي ستستخدم لمؤتمرات الفيديو',
  removeIntegration: 'إزالة الدمج',
  dialog: 'هل أنت متأكد من أنك تريد إزالة دمج Google Meet؟',
  message: 'تمت إزالة الدمج بنجاح',
  googleMeet: 'Google Meet',
  googleMeetDesc: 'Google Meet هو خدمة لعقد الاجتماعات والمكالمات بالفيديو بأمان وبجودة عالية متاحة للجميع.',
  integrated: 'تم الدمج',
  removeApp: 'إزالة التطبيق'
}

export const VideoConferencingIntegrationEnglish = {
  title: 'Video conferencing integration',
  desc: 'Configure which conferencing apps to use',
  removeIntegration: 'Remove integration',
  dialog: 'Are you sure you want to remove Google Meet integration?',
  message: 'Successfully removed integration',
  googleMeet: 'Google Meet',
  googleMeetDesc: 'Google Meet is a service for secure, high-quality video meetings and calls available for everyone.',
  integrated: 'Integrated',
  removeApp: 'Remove app'
}
