
import { FadeTransition } from 'vue2-transitions'
import RecruiterNav from '@/components/RecruiterNavbar/RecruiterNav.vue'
import RecruiterSettings from '@/components/RecruiterSettings/RecruiterSettings.vue'
import { UserModule } from '@/store/modules/user'
import Permissions from '@/directive/permission'
import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'RecruiterSettingsDashboard',
  components: {
    FadeTransition,
    RecruiterNav,
    RecruiterSettings
  },
  directives: {
    Permissions
  }
})
export default class extends Vue {
    private companyId = '';
    private userDetails = {};

    mounted(): void {
      this.getCompanyId()
    }

    async getCompanyId(): Promise<void> {
      this.companyId = UserModule.companyId || ''
      this.userDetails = {
        username: UserModule.name,
        email: UserModule.email,
        avatar: UserModule.avatar
      }
    }
}
