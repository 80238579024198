
import { Vue, Component } from 'vue-property-decorator'
import WcTypography from '@/components/UILibrary/Typography/WcTypography.vue'
import WcIconSquareTick from '@/components/UILibrary/Icons/WcIconSquareTick.vue'
import WcIconActions from '@/components/UILibrary/Icons/WcIconActions.vue'
import WcIconArrowUp from '@/components/UILibrary/Icons/WcIconArrowUp.vue'
import WcIconSquaredMinus from '@/components/UILibrary/Icons/WcIconSquaredMinus.vue'
import WcIconTrash from '@/components/UILibrary/Icons/WcIconTrash.vue'
import WcIconBin from '@/components/UILibrary/Icons/WcIconBin.vue'
import { InboxActionsEnum } from '@/views/home-page/inbox/models/inbox-type.enum'
import InboxNotificationsResource from '@/api/inbox/inboxNotifications'
import WcDialog from '@/components/UILibrary/WcDialog.vue'
import Button from '@/components/UILibrary/Buttons/Button.vue'
import { handleError } from '@/utils/request'
import WcIconMenu from '@/components/UILibrary/Icons/WcIconMenu.vue'

@Component({
  name: 'InboxActions',
  computed: {
    InboxActionsEnum() {
      return InboxActionsEnum
    }
  },
  components: {
    WcIconMenu,
    Button,
    WcDialog,
    WcIconBin,
    WcIconTrash,
    WcIconSquaredMinus,
    WcIconArrowUp,
    WcIconActions,
    WcIconSquareTick,
    WcTypography
  }
})
export default class InboxActions extends Vue {
  inboxNotificationsResource = new InboxNotificationsResource()
  showDeleteNotificationsDialog = false
  deleteNotificationsLoading = false
  deleteAllReadNotificationsState = false
  async handleActions(type: InboxActionsEnum): Promise<void> {
    switch (type) {
      case InboxActionsEnum.MARK_ALL_READ:
        await this.inboxNotificationsResource.store({ isRead: true })
        this.$emit('refresh')
        break

      case InboxActionsEnum.MARL_ALL_UNREAD:
        await this.inboxNotificationsResource.store({ isRead: false })
        this.$emit('refresh')
        break

      case InboxActionsEnum.DELETE_ALL_NOTIFICATIONS:
        this.showDeleteNotificationsDialog = true
        this.deleteAllReadNotificationsState = false
        break

      case InboxActionsEnum.DELETE_ALL_READ_NOTIFICATIONS:
        this.showDeleteNotificationsDialog = true
        this.deleteAllReadNotificationsState = true
        break
    }
  }

  handleDeleteNotificationsDialog(): void {
    this.showDeleteNotificationsDialog = !this.showDeleteNotificationsDialog
  }

  get getDeleteNotificationsTitle(): string {
    if (this.deleteAllReadNotificationsState) {
      return this.$t('homePage.inbox.confirmationToDeleteAllReadNotifications').toString()
    }
    return this.$t('homePage.inbox.confirmationToDeleteAllNotifications').toString()
  }

  async deleteNotification(): Promise<void> {
    this.deleteNotificationsLoading = true
    try {
      await this.inboxNotificationsResource.deleteNotifications(this.deleteAllReadNotificationsState)
      if (this.deleteAllReadNotificationsState) {
        this.$message.success(this.$t('homePage.inbox.successMessages.deletedAllReadNotifications').toString())
      } else {
        this.$message.success(this.$t('homePage.inbox.successMessages.deletedAllNotifications').toString())
      }
      this.handleDeleteNotificationsDialog()
      this.$emit('refresh')
    } catch (e) {
      handleError(e, this.$message)
    } finally {
      this.deleteNotificationsLoading = false
    }
  }

  t(key: string): string {
    return this.$t(`homePage.inbox.${key}`).toString()
  }
}
