import { CandidateListArabic } from '@/lang/pages/candidateList'
import { BookingLinkArabic, InterviewBookingArabic } from '@/lang/pages/bookingLink'
import { ProfileBuilderArabic } from '@/lang/pages/profileBuilder'
import { SendOfferArabic } from '@/lang/pages/sendOffer'
import { ListOffersArabic } from '@/lang/pages/offerLetters'
import { AddEditJobArabic } from '@/lang/pages/addEditJob'
import { InterviewArabic } from '@/lang/pages/interview'
import { QuizArabic } from '@/lang/pages/quiz'
import { StageModulesArabic } from '@/lang/pages/stageModules'
import { ExtensionPagesArabic } from '@/lang/pages/extensionPages'
import { CalendarIntegrationPageArabic } from '@/lang/pages/calendarIntegrationPage'
import { SignupArabic } from '@/lang/pages/signup'
import { LoginArabic } from '@/lang/pages/login'
import { OtpArabic } from '@/lang/pages/otp'
import { userAssignmentArabic } from '@/lang/pages/userAssignment'
import { JobsArabic } from '@/lang/pages/jobs'
import { DummyDataArabic } from '@/lang/pages/dummyData'
import { AgenciesArabic } from '@/lang/pages/agencies'
import { ScreeningQuizArabic } from '@/lang/pages/screeningQuiz'
import { CandidateTimelineArabic } from '@/lang/pages/candidateTimeline'
import { TeamMembersArabic } from '@/lang/pages/teamMembers'
import { CompanyBrandArabic } from '@/lang/pages/companyBrand'
import { JobDescriptionArabic } from '@/lang/pages/jobDescription'
import { CareerEditArabic } from '@/lang/pages/careerEdit'
import { CareerArabic } from '@/lang/pages/career'
import { RecruiterSettingsArabic } from '@/lang/pages/recruiterSettings'
import { InterviewPageArabic } from '@/lang/pages/interviewPage'
import { ProfilePageArabic } from '@/lang/pages/profilePage'
import { FormPlaceholdersArabic } from '@/lang/pages/formPlaceholders'
import { AppIntegrationsArabic } from '@/lang/pages/appIntegrations'
import { HrmsIntegrationArabic } from '@/lang/pages/hrmsIntegration'
import { VideoConferencingIntegrationArabic } from '@/lang/pages/videoConferencingIntegration'
import { ProfileDropdownArabic } from '@/lang/pages/profileDropdown'
import { JobReportsArabic } from '@/lang/pages/jobReports'
import { InterviewSchedulingArabic } from '@/lang/pages/interviewScheduling'
import { InterviewRecordingArabic } from '@/lang/pages/interviewRecording'
import { StagePipelineArabic } from '@/lang/pages/stagePipeline'
import { ManageEmailsArabic } from '@/lang/pages/manageEmails'
import { TestArabic } from '@/lang/pages/test'
import { GeneralArabic } from '@/lang/pages/general'
import { AddCandidateArabic } from '@/lang/pages/addCandidate'
import { FileUploadErrorsArabic } from '@/lang/pages/fileUploadErrors'
import { InputValidationsArabic } from '@/lang/pages/inputValidations'
import { EmailComponentArabic } from '@/lang/pages/emailComponent'
import { MobileNavbarMenuArabic } from '@/lang/pages/mobileNavbarMenu'
import { WorkPlaceTypesEnumArabic } from '@/lang/pages/workPlaceTypesEnum'
import { RoleTypesEnumArabic } from '@/lang/pages/roleTypesEnum'
import { ReferralsArabic } from '@/lang/pages/referrals'
import { RecruiterNavigationArabic } from '@/lang/pages/recruiterNavigation'
import { ManageScoreCardArabic } from '@/lang/pages/manageScoreCardTemplates'
import { SetReminderArabic } from '@/lang/pages/setReminder'
import { HomePageArabic } from '@/lang/pages/homePage'
import { TalentPoolArabic } from '@/lang/pages/talentPool'
import { NotificationPreferencesArabic } from '@/lang/pages/notificationPreferences'
import { LocationsAndDepartmentsArabic } from '@/lang/pages/locationsAndDepartments'
import { JobBoardIntegrationsArabic } from '@/lang/pages/jobBoardIntegrations'
import { CvParsedArabic } from '@/lang/pages/cvParsed'
import { InterviewAvailabilityArabic } from '@/lang/pages/interviewAvailability'

export default {
  homePage: HomePageArabic,
  addEditJob: AddEditJobArabic,
  candidateList: CandidateListArabic,
  userAssignment: userAssignmentArabic,
  sendOffer: SendOfferArabic,
  listOffers: ListOffersArabic,
  agencies: AgenciesArabic,
  stageModules: StageModulesArabic,
  screeningQuiz: ScreeningQuizArabic,
  extensionPage: ExtensionPagesArabic,
  teamMembers: TeamMembersArabic,
  companyBrand: CompanyBrandArabic,
  jobDescription: JobDescriptionArabic,
  careerEdit: CareerEditArabic,
  career: CareerArabic,
  recruiterSettings: RecruiterSettingsArabic,
  interviewBooking: InterviewBookingArabic,
  interviewScorecardTemplate: ManageScoreCardArabic,
  bookingLink: BookingLinkArabic,
  interviewPage: InterviewPageArabic,
  recruiterNavigation: RecruiterNavigationArabic,
  referrals: ReferralsArabic,
  roleTypesEnum: RoleTypesEnumArabic,
  workPlaceTypesEnum: WorkPlaceTypesEnumArabic,
  mobileNavbarMenu: MobileNavbarMenuArabic,
  candidateTimeline: CandidateTimelineArabic,
  emailComponent: EmailComponentArabic,
  inputValidations: InputValidationsArabic,
  fileUploadErrors: FileUploadErrorsArabic,
  addCandidate: AddCandidateArabic,
  general: GeneralArabic,
  test: TestArabic,
  profileBuilder: ProfileBuilderArabic,
  manageEmails: ManageEmailsArabic,
  stagePipeline: StagePipelineArabic,
  interviewScheduling: InterviewSchedulingArabic,
  interviewRecordings: InterviewRecordingArabic,
  jobReports: JobReportsArabic,
  quiz: QuizArabic,
  profileDropdown: ProfileDropdownArabic,
  videoConferencingIntegration: VideoConferencingIntegrationArabic,
  hrmsIntegration: HrmsIntegrationArabic,
  interview: InterviewArabic,
  calendarIntegration: CalendarIntegrationPageArabic,
  appIntegrations: AppIntegrationsArabic,
  formPlaceholders: FormPlaceholdersArabic,
  signup: SignupArabic,
  login: LoginArabic,
  profilePage: ProfilePageArabic,
  otp: OtpArabic,
  jobs: JobsArabic,
  dummyData: DummyDataArabic,
  setReminder: SetReminderArabic,
  talentPool: TalentPoolArabic,
  notificationPreferences: NotificationPreferencesArabic,
  locationsAndDepartments: LocationsAndDepartmentsArabic,
  jobBoardIntegrations: JobBoardIntegrationsArabic,
  interviewAvailability: InterviewAvailabilityArabic,
  cvParsed: CvParsedArabic
}
