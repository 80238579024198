import request from '@/utils/request'

/**
 * Simple RESTful resource class
 */
class BaseResource<T = any> {
  uri: string
  constructor(uri: string) {
    this.uri = uri
  }

  list(query = {}) {
    return request({
      url: '/' + this.uri,
      method: 'get',
      params: query
    })
  }

  // TODO: this can be fixed like this
  // get(id: string): Promise<T> {
  //  return request({
  //    url: '/' + this.uri + '/' + id,
  //    method: 'get'
  //  }).then((response) => response.data as T)
  //    .catch((error) => {
  //    throw error
  //  })
  // }
  get(id: string) {
    return request({
      url: '/' + this.uri + '/' + id,
      method: 'get'
    })
  }

  store(resource: Partial<T>) {
    return request({
      url: '/' + this.uri,
      method: 'post',
      data: resource
    })
  }

  update(id: string, resource: Partial<T>) {
    return request({
      url: '/' + this.uri + '/' + id,
      method: 'put',
      data: resource
    })
  }

  destroy(id: string) {
    return request({
      url: '/' + this.uri + '/' + id,
      method: 'delete'
    })
  }
}

export { BaseResource as default }
