import { IQuery, IQueryEmailsToSend } from '@/views/models'
import { ElMessage } from 'element-ui/types/message'
import { RecruitersModule } from '@/store/modules/recruiters'
import i18n from '@/lang'

export const randomId = function() {
  return (
    '_' +
    Math.random()
      .toString(36)
      .substr(2, 9)
  )
}

export const MONTH_NAMES_SHORT = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
]

export const getQueryParams = function(query: IQuery): string {
  let strQuery = '?'
  const keys = Object.keys(query)
  for (const key of keys) {
    strQuery += `${key}=${query[key]}&`
  }
  return strQuery
}

export const getQueryParamsForEmail = function(query: IQueryEmailsToSend): string {
  let strQuery = '?'
  const keys = Object.keys(query)
  for (const key of keys) {
    strQuery += `${key}=${query[key]}&`
  }
  return strQuery
}

export const getMMMYYYYFormatted = function(date: Date): string {
  return MONTH_NAMES_SHORT[date.getMonth()] + ' ' + date.getFullYear()
}

export const getDayMonthYear = function(date: Date): string {
  return date.getDate() + ' ' + MONTH_NAMES_SHORT[date.getMonth()] + ' ' + date.getFullYear()
}

export const pluralize = function(count: number, word: string): string {
  if (count > 1) {
    return word + 's'
  } else {
    return word
  }
}

export const firstCapital = function(word: string): string {
  return word.charAt(0).toUpperCase() + word.slice(1)
}

export const isMobile = () => {
  let check = false;
  (function(a) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw(n|u)|c55\/|capi|ccwa|cdm|cell|chtm|cldc|cmd|co(mp|nd)|craw|da(it|ll|ng)|dbte|dcs|devi|dica|dmob|do(c|p)o|ds(12|d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(|_)|g1 u|g560|gene|gf5|gmo|go(\.w|od)|gr(ad|un)|haie|hcit|hd(m|p|t)|hei|hi(pt|ta)|hp( i|ip)|hsc|ht(c(| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i(20|go|ma)|i230|iac( ||\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|[a-w])|libw|lynx|m1w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|mcr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|([1-8]|c))|phil|pire|pl(ay|uc)|pn2|po(ck|rt|se)|prox|psio|ptg|qaa|qc(07|12|21|32|60|[2-7]|i)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h|oo|p)|sdk\/|se(c(|0|1)|47|mc|nd|ri)|sgh|shar|sie(|m)|sk0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h|v|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl|tdg|tel(i|m)|tim|tmo|to(pl|sh)|ts(70|m|m3|m5)|tx9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas|your|zeto|zte/i.test(a.substr(0, 4))) {
      check = true
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
  })(navigator.userAgent || navigator.vendor || window.opera)
  return check
}

export const removeHTMLTagsFromString = (str: string) => {
  return str.replace(/<[^>]*>/g, '')
}

export const getYoutubeEmbeddedLinkFromUrl = (url: string) => {
  const rx = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/|shorts\/)|(?:(?:watch)?\?v(?:i)?=|&v(?:i)?=))([^#&?]*).*/
  const r = url.match(rx)
  if (r) {
    return 'https://www.youtube.com/embed/' + r[1]
  }
  return ''
}

export const isValidUUID = (uuid: string) => {
  return uuid.match(/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-4[0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/)
}

// UN_RESPONSIVE to Un Responsive
export const capsUnderscoreSepToSentence = (input: string) => {
  return input.toLowerCase().split('_').map(w => (w[0] || '').toUpperCase() + (w || '').substring(1).toLowerCase())
    .join(' ')
}

// video-question to Video Question
export const smallHyphenSepToSentence = (input: string) => {
  return input.toLowerCase().split('-').map(w => (w[0] || '').toUpperCase() + (w || '').substring(1).toLowerCase())
    .join(' ')
}

export const convertTextToHTML = (str: string) => {
  const urlPattern = /((?:(?:https?|ftp|file):\/\/)?(?:[\w-]+\.)*[\w-]+(?:\.[a-zA-Z]{2,}))(?::\d+)?(?:\/\S*)?/gi

  // Regular expression pattern to match mentions
  const mentionPattern = /@([\w-]+)/g

  // Replace URLs with HTML links
  let result = str.replace(urlPattern, (url) => {
    if (url.startsWith('www.')) {
      return '<a href="https://' + url + '" target="_blank">' + url + '</a>'
    } else if (!url.startsWith('http')) {
      return '<a href="https://www.' + url + '" target="_blank">' + url + '</a>'
    } else {
      return '<a href="' + url + '" target="_blank">' + url + '</a>'
    }
  })

  // Add boldness and color to mentions
  result = result.replace(mentionPattern, '<b style="color: #5138EE;">$&</b>')

  // Replace newline characters with <br> tags
  result = result.replace(/\n/g, '<br>')

  return result
}

export const stringToSentenceCase = (inputString) => {
  // Split the input string into words
  const words = inputString.toLowerCase().split(' ')

  // Capitalize the first letter of each word
  const sentenceCaseWords = words.map(word => {
    if (word.length > 0) {
      return word[0].toUpperCase() + word.slice(1)
    } else {
      return ''
    }
  })

  // Join the words back together with spaces
  return sentenceCaseWords.join(' ')
}

export const convertMinutesToHoursAndMinutes = (minutes: number|undefined) => {
  if (!minutes) return '0 Min'
  const hours = Math.floor(minutes / 60)

  const remainingMinutes = minutes % 60

  let output = ''
  if (hours > 0) {
    output += `${hours} Hour${hours > 1 ? 's' : ''} `
  }
  if (remainingMinutes > 0 || hours === 0) {
    output += `${remainingMinutes} Minutes`
  }

  return output.trim()
}

export const encodeText = (text: string): string => {
  return text.replace(/{{([a-zA-Z0-9]+)}}/g, (match, p1) => {
    // Skip encoding for strings containing 'bookingLink'
    if (p1.includes('booking-link')) {
      return match
    }
    // Convert camelCase to hyphenated lowercase
    return `{{${p1.replace(/[A-Z]/g, (m) => '-' + m.toLowerCase())}}}`
  })
}

// Function to decode text: replacing kebab-case with camelCase
export const decodeText = (text: string): string => {
  return text.replace(/{{([a-z0-9]+(?:-[a-z0-9]+)+)}}/g, (match, p1) => {
    // Check if the matched string contains 'booking-link'
    if (p1.includes('booking-link')) {
      return match // Return the match as is, without modification
    }
    // Otherwise, perform the original replacement
    return `{{${p1.replace(/-([a-z])/g, (_, p) => p.toUpperCase())}}}`
  })
}
export const replaceTextWithString = (original: string, replaceString: string, replacement: string): string => {
  const escapedReplaceString = replaceString.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // escape special characters
  const regex = new RegExp(escapedReplaceString, 'g')
  return original.replace(regex, replacement)
}

export const stringWithoutSpacesFirstLetterCapital = (sentence: string): string => {
  const words: string[] = sentence.split(' ')
  return words.join('_')
}

export const copyTextToClipboard = async(text: string, log: ElMessage) => {
  try {
    await navigator.clipboard.writeText(text)
    const translated = i18n.t('general.copiedToClipboard').toString() || 'Copied to clipboard'
    log.success(translated)
  } catch (error) {
    const translated = i18n.t('general.unableToCopy').toString() || 'Unable to copy'
    log.warning(translated)
  }
}

export const getRecruiterName = (id: string): string => {
  const recruiters = RecruitersModule.getRecruiters
  const recruiter = recruiters.find(recruiter => recruiter.id === id)
  return recruiter ? recruiter.fullName : ''
}

/**
 * Check if file size is less than or equal to given size in MB
 * @params file: File | El-Upload file object
 * @params sizeInMB: number
 */
export const checkFileSize = (file: any, sizeInMB: number): boolean => {
  return file.size / 1024 / 1024 <= sizeInMB
}

/**
 * Sorts an array of objects based on a specified key and order.
 *
 * @param {T[]} data - The array of objects to be sorted.
 * @param {'asc' | 'desc'} order - The order of sorting ('asc' for ascending, 'desc' for descending).
 * @param {'date' | 'string' | 'number' | 'boolean'} comparisonValueType - The type of the values being compared.
 * @param {string} compareKey - The key in the object to compare for sorting.
 * @returns {T[]} - The sorted array.
 */
export const sortList = <T>(
  data: T[],
  order: 'asc' | 'desc',
  comparisonValueType: 'date' | 'string' | 'number' | 'boolean',
  compareKey: keyof T
): T[] => {
  return data.sort((a, b) => {
    const valueA = a[compareKey]
    const valueB = b[compareKey]

    let comparisonResult = 0

    switch (comparisonValueType) {
      case 'date':
        comparisonResult = compareDates(valueA, valueB)
        break
      case 'number':
        comparisonResult = compareNumbers(valueA, valueB)
        break
      case 'string':
        comparisonResult = compareStrings(valueA, valueB)
        break
      case 'boolean':
        comparisonResult = compareBooleans(valueA, valueB)
        break
    }

    return order === 'asc' ? comparisonResult : -comparisonResult
  })
}

function compareDates(valueA: unknown, valueB: unknown): number {
  const dateA = new Date(valueA as string)
  const dateB = new Date(valueB as string)
  return dateA.getTime() - dateB.getTime()
}

function compareNumbers(valueA: unknown, valueB: unknown): number {
  return (Number(valueA) - Number(valueB))
}

function compareStrings(valueA: unknown, valueB: unknown): number {
  return String(valueA).localeCompare(String(valueB))
}

function compareBooleans(valueA: unknown, valueB: unknown): number {
  const boolA = Boolean(valueA)
  const boolB = Boolean(valueB)
  return (boolA === boolB) ? 0 : boolA ? -1 : 1
}

export const humanReadableFileSize = (size: number) => {
  const units = ['B', 'KB', 'MB', 'GB', 'TB']
  let i = 0
  while (size > 1024) {
    size /= 1024
    i++
  }
  return size.toFixed(2) + ' ' + units[i]
}

export const downloadUrlFile = (url: string) => {
  const win = window.open(url, '_blank')
  win?.focus()
}

export const isSmallScreen = (): boolean => {
  return isMobile() || isPlugin()
}

/** Checking whether the current page is opened on plugin or not
 * All plugin pages contains /plugin/ in the url path
 * On login page contains /login?redirect=%2Fplugin%2Fdefault%3Fsource%3Dgmail in the url path
 * The condition is to check whether the text 'plugin' exist on the url path
 * Example: 'app.whitecarrot.io/#/plugin/default?source=gmail' => true
 * Example: 'app.whitecarrot.io/#/login?redirect=%2Fplugin%2Fdefault%3Fsource%3Dgmail' => true
 * Example: 'app.whitecarrot.io/#/recruiter/candidates' => false
 * **/
export const isPlugin = (): boolean => {
  return window.location.hash.includes('plugin')
}

export const redirectBaseLinks: string[] = [
  'https://wa.me/?text=',
  'https://www.linkedin.com/shareArticle?mini=true&url='
]
