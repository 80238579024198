export const FormPlaceholdersArabic = {
  enterFirstName: 'أدخل اسمك الأول',
  enterLastName: 'أدخل اسمك الأخير',
  enterEmail: 'أدخل البريد الإلكتروني',
  enterCode: 'أدخل الرمز',
  selectCategory: 'اختر الفئة'
}

export const FormPlaceholdersEnglish = {
  enterEmail: 'Enter email',
  enterFirstName: 'Enter your first name',
  enterLastName: 'Enter your last name',
  enterCode: 'Enter code',
  selectCategory: 'Select category'
}
