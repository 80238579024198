export const ProfilePageArabic = {
  title: 'الملف الشخصي',
  titleDesc: 'إدارة معلوماتك الشخصية',
  firstName: 'الاسم الأول',
  lastName: 'الاسم الأخير',
  email: 'البريد الإلكتروني',
  saveChanges: 'حفظ التغييرات',
  saveMessage: 'تم تحديث الاسم بنجاح',
  firstNameValidate: 'يرجى إدخال الاسم الأول',
  lastNameValidate: 'يرجى إدخال الاسم الأخير'
}

export const ProfilePageEnglish = {
  title: 'Profile',
  titleDesc: 'Manage your personal information',
  firstName: 'First name',
  lastName: 'Last name',
  email: 'Email',
  saveChanges: 'Save changes',
  saveMessage: 'Name updated successfully',
  firstNameValidate: 'Enter your first name',
  lastNameValidate: 'Enter your last name'
}
