
import { isMobile } from '@/utils/general'
import WcTypography from '@/components/UILibrary/Typography/WcTypography.vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import WcIconCross from '@/components/UILibrary/Icons/WcIconCross.vue'
import Tooltip from '@/components/UILibrary/tooltip.vue'

@Component({
  name: 'WcDialog',
  methods: { isMobile },
  components: { Tooltip, WcIconCross, WcTypography }
})
export default class WcDialog extends Vue {
  @Prop({ default: false, required: true }) closeSync!: boolean
  @Prop({ required: true, type: String, default: '' }) title!: string
  @Prop({ required: false, type: String }) description!: string
  @Prop({ default: false, required: false }) isExtension!: boolean
  @Prop({ default: false, required: false }) bodyScroll!: boolean
  @Prop({ default: '40rem', required: false }) minWidth!: string
  @Prop({ default: false }) footerBorder!: boolean

  handleDialogClose() {
    this.$emit('close-jdialog')
  }

  hasScrolled = false;
  handleScroll(event: Event): void {
    const target = event.target as HTMLElement
    this.hasScrolled = target.scrollTop > 0
  }
}
