
import { Component, Vue, Watch } from 'vue-property-decorator'
import { LanguageEnum, LanguageModule } from '@/store/modules/language'
import Button from '@/components/UILibrary/Buttons/Button.vue'
import WcIconArrowUp from '@/components/UILibrary/Icons/WcIconArrowUp.vue'
import WcIconUserFilled from '@/components/UILibrary/Icons/WcIconUserFilled.vue'
import WcTypography from '@/components/UILibrary/Typography/WcTypography.vue'
import WorldIcon from '@/components/UILibrary/Icons/WorldIcon.vue'
import { isMobile } from '@/utils/general'

@Component({
  methods: { isMobile },
  components: { WorldIcon, WcTypography, WcIconUserFilled, WcIconArrowUp, Button },
  computed: {
    LanguageEnum() {
      return LanguageEnum
    },
    getCurrentLanguage() {
      return LanguageModule.language
    }
  }
})
export default class extends Vue {
  switchLanguage(switchTo: LanguageEnum) {
    LanguageModule.setLanguage(switchTo)
    this.selectedLanguage.key = switchTo
    this.selectedLanguage.label = this.languageList.find((item) => item.key === LanguageModule.language)?.label || ''
  }

  languageList: {key: LanguageEnum, label: string}[] = [{
    key: LanguageEnum.ENGLISH,
    label: 'English - EN'
  },
  {
    key: LanguageEnum.ARABIC,
    label: 'العربية - AR'
  }
  ]

  selectedLanguage: {key: LanguageEnum, label: string} = {
    key: LanguageModule.language,
    label: this.languageList.find((item) => item.key === LanguageModule.language)?.label || ''
  }

  @Watch(LanguageModule.language)
  onLanguageChange() {
    this.switchLanguage(LanguageModule.language)
  }
}
