
import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'WcIconCross',
  components: {
  },
  props: {
    color: {
      default: '#222',
      type: String
    },
    height: {
      default: '12',
      type: String
    },
    width: {
      default: '12',
      type: String
    }
  }
})
export default class extends Vue {

}
