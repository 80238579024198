import { RouteConfig } from 'vue-router'

export const WILDCARD_ROUTE: RouteConfig[] = [
  {
    path: '*',
    redirect: () => {
      return '/404'
    }
  }
]
