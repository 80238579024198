export const RecruiterSettingsArabic = {
  company: 'الشركة',
  companyBrand: 'صفحة العلامة التجارية والمهنية',
  teamMembers: 'أعضاء الفريق',
  recruiting: 'رحلة التوظيف',
  defaultPipelineAndArchivalReasons: 'مراحل عمليات التوظيف المختلفة وأسباب أرشفة بعض الوظائف',
  interviewBookingLinks: 'روابط حجز المقابلات',
  interviewScorecardTemplates: 'قوالب ردود الفعل المقابلة',
  emailTemplates: 'نماذج جاهزة لايميلات لاستخدامها في مراحل التوظيف المختلفة',
  atsIntegration: 'تكامل نظام تتبع المتقدمين للوظائف',
  agencies: 'شركات التوظيف',
  personal: 'واجهتك الشخصية',
  profile: 'ملفك',
  calendarAndEmailIntegration: 'ربط التقويم والايميل بالمنصة',
  videoConferencingIntegration: 'تكامل مكالمات الفيديو',
  hrmsIntegration: 'دمج نظام إدارة الموارد البشرية',
  integrations: 'التكاملات',
  notificationPreferences: 'تفضيلات الإشعارات',
  locationAndDepartments: 'الموقع والإدارة',
  interviewAvailability: 'توفر المقابلات'
}

export const RecruiterSettingsEnglish = {
  company: 'COMPANY',
  companyBrand: 'Brand & career page',
  teamMembers: 'Team members',
  recruiting: 'RECRUITING',
  defaultPipelineAndArchivalReasons: 'Default pipeline and archival reasons',
  interviewBookingLinks: 'Interview booking links',
  interviewScorecardTemplates: 'Interview feedback templates',
  atsIntegration: 'ATS integration',
  emailTemplates: 'Email templates',
  agencies: 'Agencies',
  personal: 'PERSONAL',
  profile: 'Profile',
  calendarAndEmailIntegration: 'Calendar and email integration',
  videoConferencingIntegration: 'Video conferencing integration',
  hrmsIntegration: 'HRMS integration',
  integrations: 'Integrations',
  notificationPreferences: 'Notification preferences',
  locationAndDepartments: 'Location and department',
  interviewAvailability: 'Interviews availability'
}
