import Resource from '@/api/resources/base-resource'
import request from '@/utils/request'

export interface ChatBotPayload {
  userId: string
  conversationId: string
  query: string
}

class Chatbot extends Resource {
  constructor() {
    super('wc-chat-bot')
  }

  getResponseForQuery(data: ChatBotPayload) {
    return request({
      url: '/' + this.uri + '/query',
      method: 'post',
      data
    })
  }

  getUserChatThreads() {
    return request({
      url: '/' + this.uri + '/chat-threads',
      method: 'get'
    })
  }

  getChatMessages(conversationId: string) {
    return request({
      url: '/' + this.uri + `/chat-thread-messages/${conversationId}`,
      method: 'get'
    })
  }

  submitFeedback(data: {userId: string, rating: string}, messageId: string) {
    return request({
      url: '/' + this.uri + `/message/${messageId}/feedback`,
      method: 'post',
      data
    })
  }
}

export { Chatbot as default }
