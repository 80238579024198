import { ElForm } from 'element-ui/types/form'

export const isValidEmail = (str: string) => String(str)
  .toLowerCase()
  .match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  )

export const validateToEmails = (arr: string[]): boolean => {
  let isValid = true
  arr.forEach((email) => {
    if (!isValidEmail(email)) {
      arr.pop()
      isValid = false
    }
  })

  return isValid
}

export const isExternal = (path: string) => /^(https?:|mailto:|tel:)/.test(path)

export const isValidHttpUrlString = (rule: any, value: string, callback: Function) => {
  let url
  try {
    url = new URL(value)
  } catch (_) {
    callback(new Error('Please enter a valid url'))
  }
  if (url) {
    const isValid = url.protocol === 'http:' || url.protocol === 'https:'
    if (!isValid) callback(new Error('Please enter a valid url'))
    else callback()
  } else {
    callback(new Error('Please enter a valid url'))
  }
}

export const validateYoutubeUrl = (url?: string) => {
  try {
    const rx = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/|shorts\/)|(?:(?:watch)?\?v(?:i)?=|&v(?:i)?=))([^#&?]*).*/
    const r = url?.match(rx)
    return !!r
  } catch (_) {
    return false
  }
}

export const isValidUrl = (url: string) => {
  try {
    const u = new URL(url)
    return !!u
  } catch (_) {
    return false
  }
}

export const strictEmailValidator = (errorMessage?: string) => ({
  validator: (rule, value, callback) => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/
    if (regex.test(value)) {
      callback()
    } else {
      if (errorMessage) {
        callback(new Error(errorMessage))
      } else {
        callback(new Error('Please enter a valid email address'))
      }
    }
  },
  trigger: ['blur']
})

export const nonEmptyStringValidator = (errorMessage: string) => ({
  validator: (rule, value, callback) => {
    if (!value.trim()) {
      callback(new Error(errorMessage))
    } else {
      callback()
    }
  },
  trigger: ['change']
})

export const lenientEmailValidator = (errorMessage: string) => ({
  validator: (rule, value, callback) => {
    if (!value) {
      callback()
    }
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/
    if (regex.test(value)) {
      callback()
    } else {
      callback(new Error(errorMessage))
    }
  },
  trigger: ['blur']
})

export const emailRules = {
  email: [
    { required: true, message: 'Please enter valid email', trigger: 'blur', type: 'email' },
    strictEmailValidator
  ]
}

const phoneNumberValidation = (value: string, callback: Function) => {
  // Remove all whitespace for easier validation
  const cleanedValue = value.replace(/\s+/g, '')

  // Regex patterns
  const countryCodeRegex = /^\+[1-9]\d{0,2}/ // Matches the country code (e.g., +1, +91, +123)
  const phoneNumberRegex = /^\+[1-9]\d{0,2}[\s-]?\(?\d{2,4}\)?[\s-]?\d{3,5}[\s-]?\d{3,5}$/

  // Error messages
  const missingCountryCodeMessage = `Missing country code. Ex: ""+971 ${value}" or +1 ${value}"`
  const invalidFormatMessage = 'Invalid format. Use: "+{country-code} number". Ex: "+971 501234567" or "+1 (123) 456-7890"'
  const invalidCharactersMessage = 'Invalid characters. Use only digits, \'+\', \'-\', \'()\', and spaces.'

  // Check for invalid characters
  if (/[^+\d\s()-]/.test(cleanedValue)) {
    return callback(new Error(invalidCharactersMessage))
  }

  // Check for missing country code
  if (!countryCodeRegex.test(cleanedValue)) {
    return callback(new Error(missingCountryCodeMessage))
  }

  // Check for overall phone number format
  if (!phoneNumberRegex.test(cleanedValue)) {
    return callback(new Error(invalidFormatMessage))
  }

  // If all checks pass, the phone number is valid
  return callback()
}

export const strictPhoneNumberValidation = {
  validator: (rule, value, callback) => {
    phoneNumberValidation(value, callback)
  },
  trigger: ['blur']
}

export const lenientPhoneNumberValidation = {
  validator: (rule, value, callback) => {
    if (!value) {
      callback()
    }
    phoneNumberValidation(value, callback)
  },
  trigger: ['blur']
}

export const strictLinkedinValidation = {
  validator: (rule, value, callback) => {
    const regex = /^https?:\/\/(?:www\.)?linkedin\.com\/(?:in|company|profile)\/[\w-]+(?:\/?\?[^/\s]*)?$/
    if (regex.test(value)) {
      callback()
    } else {
      callback(new Error('Please enter a valid linkedin url'))
    }
  },
  trigger: ['blur']
}

export const lenientLinkedinValidation = (enterUrl: string, validUrl: string, isValidationRequired: boolean) => ({
  validator: (rule, value, callback) => {
    if (!isValidationRequired) {
      callback()
    }
    if (isValidationRequired && (value.trim() === 'linkedin.com/in/')) {
      return callback(new Error(enterUrl))
    }
    // Updated regex to include country-specific subdomains before 'linkedin.com'
    const regex = /^(https?:\/\/)?(www\.)?([a-z]{2}\.)?linkedin\.com\/in\/.{3,100}\/?$/

    if (regex.test(value)) {
      callback()
    } else {
      callback(new Error(validUrl))
    }
  }
})

export const strictUrlLinkValidation = {
  validator: (rule, value, callback) => {
    const regex = /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g
    if (regex.test(value)) {
      callback()
    } else {
      callback(new Error('Please enter a valid link'))
    }
  },
  trigger: ['blur']
}

/**
 * A normal url validation
 * Example: 'My portfolio link is : https://my.portfoliolink.com' => InValid
 * Example: 'https://my.portfoliolink.com' => Valid
 * Example: 'my.portfoliolink.com' => Valid
 * Example: 'myname@gmail.com' => InValid
 * Example: 'my.portfolio link spaced com' => InValid
 * Example: 'https:/my.portfolio link.com/some figma link etc....greater than 1024 characters' => False
 * **/
export const urlValidation = (errorMessage: string, maxLengthError: string, maxLength = 1024) => ({
  validator: (rule, value, callback) => {
    if (!value) {
      callback()
      return
    }

    if (value.length > maxLength) {
      callback(new Error(maxLengthError))
    }

    // Standard URL validation regex
    const regex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d+)?(\/[^\s]*)?$/

    if (regex.test(value)) {
      callback()
    } else {
      callback(new Error(errorMessage))
    }
  },
  trigger: ['blur']
})

export const phoneNumberRules = {
  phoneNumber: [
    { required: true, message: 'Please enter valid phone number', trigger: 'blur' },
    strictPhoneNumberValidation
  ]
}
export const formValidate = (formName: string, that: any): boolean => {
  let isValid: boolean = false;

  (that.$refs[formName] as ElForm).validate((valid: boolean): void => {
    isValid = valid
  })
  return isValid
}
