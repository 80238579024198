
import { Component, Vue } from 'vue-property-decorator'
import { isMobile } from '@/utils/general'
import InboxNotificationsResource from '@/api/inbox/inboxNotifications'
import { InboxEntity, InboxNotificationResponse, InboxQuery, PaginationOptions } from './models/inbox.entity'
import { handleError } from '@/utils/request'
import WcTypography from '@/components/UILibrary/Typography/WcTypography.vue'
import InboxNotification from '@/views/home-page/inbox/components/InboxNotification.vue'
import PrimaryFilter from '@/views/home-page/inbox/components/PrimaryFilter.vue'
import WcIconSearch from '@/components/UILibrary/Icons/WcIconSearch.vue'
import InboxActions from '@/views/home-page/inbox/components/InboxActions.vue'
import WcIconBell from '@/components/UILibrary/Icons/WcIconBell.vue'
import { InboxActionsEnum, InboxFilterEnum } from '@/views/home-page/inbox/models/inbox-type.enum'
import WcIconSetting from '@/components/UILibrary/Icons/WcIconSetting.vue'
import WcATag from '@/components/UILibrary/Typography/WcATag.vue'
import InboxLoading from '@/views/home-page/inbox/components/InboxLoading.vue'
import WcTooltip from '@/components/UILibrary/WcTooltip.vue'
import WcIconCross from '@/components/UILibrary/Icons/WcIconCross.vue'
import Button from '@/components/UILibrary/Buttons/Button.vue'

@Component({
  name: 'Inbox',
  computed: {
    InboxFilterEnum() {
      return InboxFilterEnum
    },
    InboxActionsEnum() {
      return InboxActionsEnum
    }
  },
  methods: { isMobile },
  components: {
    Button,
    WcIconCross,
    WcTooltip,
    InboxLoading,
    WcATag,
    WcIconSetting,
    WcIconBell,
    InboxActions,
    WcIconSearch,
    PrimaryFilter,
    InboxNotification,
    WcTypography
  }
})
export default class Inbox extends Vue {
  inboxNotificationsResource = new InboxNotificationsResource()
  inboxNotifications: InboxEntity[] = []
  loading: boolean = false
  actionLoading: boolean = false
  showInbox = false
  unreadNotificationsCount = 0;
  query: InboxQuery = { order: 'DESC', filter: InboxFilterEnum.UNREAD, page: 1, perPage: 20 }
  paginationOptions: PaginationOptions = { page: 1, perPage: 20, total: null, totalPages: null, hasNext: false }

  async mounted(): Promise<void> {
    await this.getUnreadNotificationCount()
  }

  async getUnreadNotificationCount(): Promise<void> {
    try {
      this.unreadNotificationsCount = await this.inboxNotificationsResource.listCount(InboxFilterEnum.UNREAD)
    } catch (e) {
      handleError(e, this.$message)
    }
  }

  get getUnreadNumber(): string {
    return this.unreadNotificationsCount > 99 ? '99+' : this.unreadNotificationsCount.toString()
  }

  async getInboxNotifications(append: boolean = false): Promise<void> {
    this.loading = true
    try {
      const data = (await this.inboxNotificationsResource.list(this.query)).data as InboxNotificationResponse
      if (append) {
        this.inboxNotifications.push(...data.notifications)
      } else {
        this.inboxNotifications = data.notifications
      }
      this.paginationOptions = data.paginationOptions as PaginationOptions
    } catch (e) {
      handleError(e, this.$message)
    } finally {
      this.loading = false
    }
  }

  loadMore(): void {
    if (this.paginationOptions.hasNext) {
      this.query.page = this.query.page + 1
      this.getInboxNotifications(true)
    }
  }

  deletedNotification(deletedNotificationId: string): void {
    const notification = this.inboxNotifications.find((each) => each.id === deletedNotificationId)
    if (notification && !notification.isRead) {
      this.readNotification(true)
    }
    this.inboxNotifications = this.inboxNotifications.filter((each) => each.id !== deletedNotificationId)
  }

  readNotification(isRead: boolean): void {
    this.unreadNotificationsCount = Math.max(this.unreadNotificationsCount + (isRead ? -1 : 1), 0)
  }

  initializePagination(): void {
    this.query.page = 1
  }

  async refresh(): Promise<void> {
    this.actionLoading = true
    this.initializePagination()
    await this.getInboxNotifications()
    await this.getUnreadNotificationCount()
    this.actionLoading = false
  }

  async toggleNotification(): Promise<void> {
    this.showInbox = !this.showInbox
    if (this.showInbox) {
      this.actionLoading = true
      await this.getInboxNotifications()
      this.actionLoading = false
    }
  }

  get getEmptyNotificationsTitle(): string {
    if (this.query.filter === InboxFilterEnum.UNREAD) {
      return this.$t('homePage.inbox.emptyUnread').toString()
    } else if (this.query.filter === InboxFilterEnum.STARRED) {
      return this.$t('homePage.inbox.emptyStarred').toString()
    }
    return this.$t('homePage.inbox.emptyAll').toString()
  }

  get getEmptyNotificationBody(): string {
    if (this.query.filter === InboxFilterEnum.UNREAD) {
      return this.$t('homePage.inbox.emptyUnreadDescription').toString()
    } else if (this.query.filter === InboxFilterEnum.STARRED) {
      return this.$t('homePage.inbox.emptyStarredDescription').toString()
    }
    return this.$t('homePage.inbox.emptyInboxDescription').toString()
  }

  t(key: string): string {
    return this.$t(`homePage.inbox.${key}`).toString()
  }
}
