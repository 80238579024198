import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import store from '@/store'

@Module({ dynamic: true, store, name: 'pseudoNotification', namespaced: true })
class PseudoNotification extends VuexModule {
  public notification = null

  @Mutation
  private SET_NOTIFICATION(notification) {
    this.notification = notification
  }

  @Action
  public triggerNotification(notification) {
    this.SET_NOTIFICATION(notification)
  }
}

export const PseudoNotificationModule = getModule(PseudoNotification)
