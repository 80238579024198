export const ProfileBuilderEnglish = {
  congratulations: 'Congratulations! 🎉',
  applicationLocked: {
    greatWork: 'Great work!',
    text: 'Your submission has been received. Next stage will get unlocked after the review of your submission. We will send you an email.',
    thanksLabel: 'Thanks. How can we do better in the future?',
    textBoxPlaceholder: 'Type your answer here...',
    feedbackText: 'Your feedback will help us improve the overall candidate experience. No worries, it will be shared with the recruiter anonymously.',
    feedbackButton: 'Submit feedback',
    feedbackThanks: 'Thanks for your feedback.',
    builtWith: 'Built with 💙 using',
    rateText: 'Rate your experience'
  },
  jobNotRelevant: {
    text: 'We did not find anything for you!',
    contactDetails: 'Please contact {{companyName}} for more details.'
  },
  question: 'Question',
  yourResponse: 'Your response',
  setReminder: 'Set a reminder',
  uploadFile: 'Upload file',
  goBack: 'Go back',
  nextStep: 'Next step',
  fillBasicInfo: {
    basicInformation: 'Basic information 📋',
    fillApplicationInfo: 'Fill in your application, this information will help us get to know you and see if you’re a good fit.',
    firstName: 'First name',
    lastName: 'Last name',
    phoneNumber: 'Phone number',
    linkedin: 'LinkedIn',
    portfolioLink: 'Portfolio link (GitHub, Behance, Dribbble, etc.)',
    otherPortfolioUrl: 'Any other portfolio URL',
    uploadResume: 'Upload your resume',
    fileUploadTip: '.pdf or .docx files accepted with file size less than 5MB',
    fileSize5MB: 'Files accepted with file size less than 5MB',
    resumeUploadedSuccessfully: 'Resume uploaded successfully',
    uploadMessage: 'Uploaded successfully.'
  },
  selectMultipleApplications: {
    statusText: 'You seem to have multiple ongoing applications. Please continue with the relevant application.',
    selectText: 'Select the application you would like to continue with:',
    currentStage: 'Current stage: ',
    createdOn: 'Created on: ',
    lastActive: 'Last active on: ',
    status: 'Status: ',
    selectAnyApplicationError: 'Please select a job application to continue.'
  },
  unlockedText: 'You have unlocked the ',
  stage: ' stage.',
  autofill: 'Autofill application',
  autofillSubText: 'Effortlessly save time by uploading your resume in one of these formats: .pdf, .docx',
  userEntryWelcome: 'Welcome to the application dashboard. Please enter your email to start or resume your application.',
  enterEmail: 'Enter your email',
  getStarted: 'Get started',
  jobDescription: '💼 Job description',
  resources: '🔗 Additional resources',
  messages: {
    resumeFormatError: 'Resume must be a PDF or Docx!',
    formatError: '{fileType} must be a {formats}',
    sizeError: '{formatType} size cannot exceed 5MB!',
    resumeSizeError: 'Resume size cannot exceed 5MB!',
    resumeUploadError: 'Unable to upload resume. Please try again later.',
    emptyFileError: 'Please upload a valid resume',
    pageNotFoundError: 'The page you are looking for is not found',
    invalidUrlWarning: 'Invalid URL',
    applicationStartError: 'Unable to start the application. Please try again!',
    resumeUploadPrompt: 'Please upload resume to continue',
    fileSizeError: 'File size cannot exceed 5MB!'
  },
  validations: {
    firstName: 'Please enter your first name',
    lastName: 'Please enter your last name',
    phoneNumber: 'Please enter a valid phone number',
    linkedIn: 'Please enter LinkedIn URL',
    gitHub: 'Please enter portfolio URL',
    response: 'Provide your response',
    validUrl: 'Please enter a valid URL',
    validLinkedinLink: 'Enter a valid LinkedIn URL like "www.linkedin.com/in/xyz"',
    linkMustBeLessThan1000Characters: 'Link must be less than 1000 characters'
  }
}

export const ProfileBuilderArabic = {
  congratulations: 'تهانينا! 🎉',
  applicationLocked: {
    greatWork: 'عمل عظيم!',
    text: 'تم استلام طلب التوظيف الخاص بك! سيتم فتح المرحلة التالية بعد مراجعة طلبك. سوف نرسل لك ايميلًا بالتفاصيل.',
    thanksLabel: 'شكرًا لك! كيف يمكننا أن نحسن تجربتك على منصتنا؟',
    textBoxPlaceholder: 'اكتب إجابتك هنا...',
    feedbackText: 'تساعدنا ملاحظاتك على تحسين تجربتك وتجربة المرشحين بشكل عام. لا تقلق، سيتم مشاركة هذه الملاحظات مع مسؤول التوظيف بشكل مجهول.',
    feedbackButton: 'أرسل ملاحظاتك',
    feedbackThanks: 'شكرًا لمساعدتك لنا!',
    builtWith: 'صممت بحب 💙 بواسطة',
    rateText: 'قيّم تجربتك!'
  },
  jobNotRelevant: {
    text: 'للأسف لم نجد فرصة تناسبك.',
    contactDetails: 'رجاءً تواصل مع {{companyName}} لمزيد من التفاصيل.'
  },
  question: 'سؤال',
  yourResponse: 'إجابتك',
  setReminder: 'اضبط تذكيرًا',
  uploadFile: 'تحميل الملف',
  goBack: 'العودة',
  nextStep: 'الخطوة التالية',
  fillBasicInfo: {
    basicInformation: 'المعلومات الأساسية 📋',
    fillApplicationInfo: 'املأ طلبك، ستساعدنا هذه المعلومات على التعرف عليك ومعرفة ما إذا كانت الوظيفة مناسبةً لك.',
    firstName: 'الاسم الأول',
    lastName: 'اسم العائلة',
    phoneNumber: 'رقم الهاتف',
    linkedin: 'رابط حسابك على LinkedIn',
    portfolioLink: 'رابط ملف الأعمال (Github, Behance, Dribble, إلخ.)',
    otherPortfolioUrl: 'أي رابط آخر لملف الأعمال',
    uploadResume: 'ارفع سيرتك الذاتية',
    fileUploadTip: 'نقبل صيغ ملفات .pdf أو .docx بحجم أقل من 5 ميغابايت',
    fileSize5MB: 'الملفات المقبولة بحجم ملف أقل من 5 ميجابايت',
    resumeUploadedSuccessfully: 'تم تحميل السيرة الذاتية بنجاح',
    uploadMessage: 'تم الرفع بنجاح.'
  },
  selectMultipleApplications: {
    statusText: 'يبدو أن لديك عدة طلبات توظيف جارية، رجاءً استمر في طلب واحد تختاره.',
    selectText: 'اختر الطلب الذي تود الاستمرار به:',
    currentStage: 'المرحلة الحالية:',
    createdOn: 'تاريخ الإنشاء:',
    lastActive: 'آخر نشاط في:',
    status: 'الحالة:',
    selectAnyApplicationError: 'يرجى اختيار طلب وظيفة للمتابعة'
  },
  unlockedText: 'لقد قمت بفتح',
  stage: 'المرحلة.',
  autofill: 'تعبئة تلقائية للطلب',
  autofillSubText: 'وفر الوقت عن طريق رفع سيرتك الذاتية بإحدى هذه الصيغ: .pdf, .docx',
  userEntryWelcome: 'مرحبًا بك في لوحة التحكم بطلبات التوظيف. رجاءً ادخل ايميلك لبدء أو استئناف طلبك.',
  enterEmail: 'ادخل ايميلك',
  getStarted: 'ابدأ!',
  jobDescription: '💼 وصف الوظيفة',
  resources: '🔗 مصادر إضافية',
  messages: {
    formatError: '{fileType} يجب أن يكون {formats}',
    sizeError: 'حجم {formatType} لا يمكن أن يتجاوز 5 ميجابايت!',
    resumeFormatError: 'يجب أن تكون صيغة السيرة الذاتية PDF أو Docx.',
    resumeUploadError: 'تعثر رفع السيرة الذاتية، رجاءً حاول مرة أخرى لاحقًا.',
    emptyFileError: 'يرجى تحميل السيرة الذاتية صالحة',
    pageNotFoundError: 'الصفحة التي تبحث عنها غير موجودة',
    invalidUrlWarning: 'رابط غير صحيح',
    applicationStartError: 'تعثر بدء الطلب، رجاءً حاول مرة أخرى.',
    resumeUploadPrompt: 'رجاءً ارفع السيرة الذاتية للمتابعة',
    fileSizeError: 'لا يمكن أن يتجاوز حجم الملف 5 ميغابايت!'
  },
  validations: {
    firstName: 'رجاءً ادخل اسمك الأول',
    lastName: 'رجاءً ادخل اسم العائلة',
    phoneNumber: 'رجاءً ادخل رقم هاتف صحيح',
    linkedIn: 'رجاءً ادخل رابط حسابك على LinkedIn ',
    gitHub: 'رجاءً ادخل رابط ملف الأعمال الخاص بك',
    response: 'رجاءً أجب على السؤال',
    validUrl: 'الرجاء إدخال عنوان URL صالح',
    validLinkedinLink: 'أدخل رابط لينكدإن صالح مثل "www.linkedin.com/in/xyz"',
    linkMustBeLessThan1000Characters: 'يجب أن يكون الرابط أقل من 1000 حرف'
  }
}
