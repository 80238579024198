
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class BoxesShimmer extends Vue {
  @Prop({ default: 3 }) readonly boxes!: number;
  @Prop({ default: 1 }) readonly rows!: number;
  @Prop({ default: '100px' }) readonly boxHeight!: string;
  @Prop({ default: '100px' }) readonly boxWidth!: string;
  @Prop({ default: 'rgba(0, 0, 0, 0.1)' }) readonly backgroundColor!: string;
  @Prop({ default: '0.4rem' }) borderRadius!: string;

  get containerStyle() {
    return {
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
      backgroundColor: this.backgroundColor,
      padding: '0.4rem',
      borderRadius: '0.4rem'
    }
  }

  get boxStyle() {
    return {
      flex: 1,
      height: this.boxHeight,
      width: this.boxWidth,
      backgroundColor: '#f6f7f8',
      borderRadius: this.borderRadius,
      margin: '0 8px'
    }
  }
}
