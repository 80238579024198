
interface LinkInterface {
  name?: string
  to?: string
  active?: string
  class?: string
  inputClass?: string
}
export default {
  name: 'LinkButton',
  props: {
    link: {
      type: Object as () => LinkInterface,
      required: true
    }
  },
  methods: {
    isActive() {
      const path = (this as any).$route.path
      return path.includes((this as any).link.active)
    }
  }

}
