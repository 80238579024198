
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
})
export default class extends Vue {
  @Prop({ default: 'primary' }) type!: 'primary' | 'success' | 'warning' | 'danger' | 'info' | 'text' | 'outline'
  @Prop({ default: '' }) elIcon!: string
  @Prop({ default: false }) disabled!: boolean
  @Prop({ default: false }) loading!: boolean
  @Prop({ default: false }) round!: boolean
  @Prop({ default: false }) plain!: boolean
  @Prop({ default: false }) circle!: boolean
  @Prop({ default: '' }) size!: 'medium' | 'small' | 'mini'
  @Prop({ default: 'button' }) nativeType!: 'button' | 'submit' | 'reset'
  @Prop({ default: false }) autoFocus!: boolean
  @Prop({ default: '' }) classes!: string
}
