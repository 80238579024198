export enum LinkedinIntegrationType {
  ALL_JOBS = 'ALL_JOBS',
  SELECTED_JOBS = 'SELECTED_JOBS',
  NONE= 'NONE'
}

export enum LinkedInFormPageFromEnum {
  SETTINGS_PAGE = 'SETTINGS_PAGE',
  PUBLISH_PAGE = 'PUBLISH_PAGE'
}
