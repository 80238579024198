export const JobDescriptionEnglish = {
  logo: 'Logo',
  jobDescription: 'Job description',
  applyForJob: 'Apply for this job',
  applyNow: 'Apply now',
  viewAllJobs: 'View all jobs',
  anyIssuesSendEmailTo: 'If you encounter any technical issues, send an email to',
  shareThisJob: 'Share this job',
  shareOnMediaOrCopy: 'Share this job on social media or copy the job link.'
}

export const JobDescriptionArabic = {
  logo: 'شعار',
  jobDescription: 'وصف الوظيفة',
  applyForJob: 'قدم لهذه الوظيفة',
  applyNow: 'قدم الآن',
  viewAllJobs: 'عرض جميع الوظائف',
  anyIssuesSendEmailTo: 'إذا واجهت أي مشاكل تقنية، أرسل بريدًا إلكترونيًا إلى',
  shareThisJob: 'شارك هذه الوظيفة',
  shareOnMediaOrCopy: 'شارك هذه الوظيفة على وسائل التواصل الاجتماعي أو انسخ رابط الوظيفة.'
}
