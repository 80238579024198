
import { Component, Prop, Vue } from 'vue-property-decorator'
@Component({
  name: 'WcIconDescription',
  components: {},
  props: {
    color: {
      type: String,
      default: '#ffffff'
    }
  }
})
export default class extends Vue {
  @Prop({ default: '#ffffff' }) color!: String;
  @Prop({ default: '20' }) height!: String;
  @Prop({ default: '20' }) width!: String;
}
