
import { Component, Vue } from 'vue-property-decorator'
import { UserModule } from '@/store/modules/user'
import { WhiteLabelPartnerEnum } from '@/views/dashboard/dashboard-model'
import CompanyResource from '@/api/company'
import { CompanyModule } from '@/store/modules/company'

@Component({
  name: 'company-logo',
  methods: {
    CompanyModule() {
      return CompanyModule
    },
    UserModule() {
      return UserModule
    }
  }
})
export default class extends Vue {
  filePath = ''
  private companyResource = new CompanyResource();
  async mounted() {
    const { data } = await this.companyResource.get(UserModule.companyId)
    CompanyModule.UpdateCompanyLogo(data.logo)
    const whiteLabelPartner = UserModule.whiteLabelPartner
    if (whiteLabelPartner === WhiteLabelPartnerEnum.BAYZAT) {
      this.filePath = 'https://res.cloudinary.com/whitecarrot/image/upload/v1694510305/company_logo/bayzat/bayzat-logo_mqwrxa.png'
    } else {
      this.filePath = 'https://res.cloudinary.com/whitecarrot/image/upload/v1667657049/company_logo/whitecarrot/full_text_logo_cpbn17.png'
    }
  }
}
