export const ReferralsArabic = {
  jobType: 'تعاقد الوظيفة | ({idx}) تعاقد الوظيفة ',
  location: 'موقع الوظيفة | ({idx}) موقع الوظيفة',
  workPolicy: 'نوع دوام الوظيفة| ({idx}) نوع دوام الوظيفة',
  searchJobToRefer: 'البحث عن وظيفة لترشيحها',
  searchByJobTitle: 'البحث بالمسمى الوظيفي',
  clearFilters: 'مسح عوامل التصفية',
  showFilters: 'إظهار عوامل التصفية',
  referralSubtext: 'هذا هو رابط الوظيفة الخاص بك، وأي شخص يتقدم للوظيفة باستخدام هذا الرابط سيتم إضافته إلى نظام إدارة التوظيف باعتباره ترشيحك أنت.',
  shareOnLinkedIn: 'شارك الرابط على LinkedIn',
  refer: 'إرسال رابط الوظيفة ',
  copyToClipboard: 'تم نسخ رابط الوظيفة إلى الحافظة',
  notCopied: 'لم يتم نسخ الرابط',
  referralPortal: 'بوابة الترشيحات والتزكية',
  employeePortal: 'بوابة الموظف',
  filtersApplied: 'تم تطبيق عوامل التصفية المختارة',
  filters: 'عوامل التصفية',
  noJobsToReferTitle: 'لم يتم العثور على وظائف للإحالة',
  noJobsToReferDescription: 'جميع الوظائف المنشورة ستظهر هنا!',
  noInternalJobsFoundTitle: 'لم يتم العثور على وظائف داخلية',
  noInternalJobsFoundDescription: 'جميع الوظائف الداخلية المنشورة ستظهر هنا!',
  jobsForReferrals: 'وظائف للإحالات',
  yourReferrals: 'الإحالات الخاصة بك',
  viewYourReferrals: 'عرض الإحالات الخاصة بك',
  table: {
    header: {
      candidateName: 'اسم المرشح',
      jobName: 'الوظيفة',
      stage: 'المرحلة',
      status: 'الحالة'
    }
  },
  noReferralFound: 'لم يتم العثور على إحالات',
  status: 'الحالة',
  jobs: 'وظائف',
  knowThem: '<b>كيف تعرفهم:</b> {about}',
  additionalComments: '<b>تعليقات إضافية: </b> {comments}',
  searchByCandidate: 'البحث بواسطة اسم المرشح',
  shareOnWhatsApp: 'شارك على واتساب',
  shareUniqueReferralLink: 'شارك رابط الإحالة الفريد الخاص بك مع المرشحين',
  shareJobDescription: 'شارك وصف الوظيفة',
  submitCandidateForJob: 'تقديم مرشح لـ {jobName}'
}

export const ReferralsEnglish = {
  searchJobToRefer: 'Search job to refer',
  searchByJobTitle: 'Search by job title',
  jobType: 'Job type | Job type ({idx})',
  location: 'Location | Location ({idx})',
  workPolicy: 'Work policy | Work policy ({idx})',
  clearFilters: 'Clear filters',
  showFilters: 'Show filters',
  shareUniqueReferralLink: 'Share your unique referral link with candidates',
  shareJobDescription: 'Share job description',
  referralSubtext: 'This is your referral link; anyone who applies using this link will be added to the hiring management system as your referral.',
  shareOnLinkedIn: 'Share on LinkedIn',
  refer: 'Refer',
  copyToClipboard: 'Referral link copied to clipboard',
  notCopied: 'Not copied',
  referralPortal: 'Referral portal',
  employeePortal: 'Employee portal',
  filtersApplied: 'Filters applied',
  filters: 'Filters',
  noJobsToReferTitle: 'No jobs found to refer',
  noJobsToReferDescription: 'All the published jobs will show up here!',
  noInternalJobsFoundTitle: 'No internal jobs found',
  noInternalJobsFoundDescription: 'All the published internal jobs will show up here!',
  knowThem: '<b>How do you know them:</b> {about}',
  additionalComments: '<b>Additional comments: </b> {comments}',
  jobsForReferrals: 'Jobs for referrals',
  yourReferrals: 'Your referrals',
  viewYourReferrals: 'View your referrals',
  table: {
    header: {
      candidateName: 'CANDIDATE NAME',
      jobName: 'JOB',
      stage: 'STAGE',
      status: 'STATUS'
    }
  },
  noReferralFound: 'No referrals found',
  status: 'Status',
  jobs: 'Jobs',
  searchByCandidate: 'Search by candidate name',
  shareOnWhatsApp: 'Share on WhatsApp',
  submitCandidateForJob: 'Submit candidate for {jobName}'
}
