
import { isMobile, randomId } from '@/utils/general'
import WcTypography from '@/components/UILibrary/Typography/WcTypography.vue'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import WcIconArrowLeftFull from '@/components/UILibrary/Icons/WcIconArrowLeftFull.vue'
import WcIconSend from '@/components/UILibrary/Icons/WcIconSend.vue'
import LoadingAnimation from '@/components/UILibrary/loading-animation.vue'
import Chatbot, { ChatBotPayload } from '@/api/chatbot/chatbot'
import { UserModule } from '@/store/modules/user'
import { handleError } from '@/utils/request'
import NewChatSection from '@/components/Chatbot/NewChatSection.vue'
import WcIconCross from '@/components/UILibrary/Icons/WcIconCross.vue'
import FullScreenLoading from '@/components/UILibrary/full-screen-loading.vue'
import ChatThreadSection from '@/components/Chatbot/ChatThreadSection.vue'
import MarkdownIt from 'markdown-it'
import toc from 'markdown-it-toc'
import highlightjs from 'markdown-it-highlightjs'
import mathjax from '@gerhobbelt/markdown-it-mathjax'
import mila from 'markdown-it-link-attributes'

@Component({
  name: 'ChatBotDialog',
  components: {
    ChatThreadSection,
    FullScreenLoading,
    WcIconCross,
    NewChatSection,
    LoadingAnimation,
    WcIconSend,
    WcIconArrowLeftFull,
    WcTypography
  },
  methods: {
    isMobile,
    handleDialogClose() {
      this.$emit('close-jdialog')
    }
  }
})
export default class extends Vue {
  @Prop({ required: false }) closeSync!: Boolean;
  userQuery = ''
  chatHistory: {
    id: string // messageId
    conversationId: string
    query: string
    answer: string
    createdAt: string
    role: string
  }[] = []

  chatbotResource = new Chatbot()
  chatThreads: {name: string, id: string}[] = []
  chatThreadHistoryLoading = false

  currentThreadId = 'new';
  threadsLoading = false;

  md = new MarkdownIt({
    html: true,
    linkify: true,
    breaks: true,
    typographer: true
  }).use(mathjax) // For LaTeX math rendering
    .use(toc) // For GitHub Flavored Markdown ToC
    .use(highlightjs).use(mila, {
      matcher(href) {
        return href.startsWith('https:')
      },
      attrs: {
        target: '_blank',
        rel: 'noopener'
      }
    })

  suggestedPrompts: string[] = ['How can I add a new job posting?', 'What\'s the process for pre-screening candidates?', 'I need help managing candidate information, where do I start?', 'How can I communicate effectively with candidates through the platform?']

  @Watch('closeSync')
  onDialogOpenOrClose(val: boolean) {
    if (val) {
      this.getThreads(true)
    }
  }

  setInput(val: string) {
    if (val) {
      this.userQuery = val
      this.sendQuery()
    }
  }

  preprocessMarkdownLinks(markdown: string) {
    return markdown.replace(/\[([^\]]+)\]\(([^)]+\.md)\)/g, (match, text, link) => {
      // Remove '.md' and add domain prefix to internal links
      const newLink = `https://whitecarrot.notion.site/${encodeURIComponent(link.replace('.md', ''))}`
      return `[${text}](${newLink})`
    })
  }

  addNewChat() {
    // if already includes new chat , don't add any more
    if (this.chatThreads.find((item) => item.id === 'new')) {
      return
    }
    this.chatThreads = [{ id: 'new', name: 'New Chat' }, ...this.chatThreads]
    this.chatHistory = []
    this.currentThreadId = 'new'
  }

  async sendSelectedPromptAsQuery(ind: number) {
    this.userQuery = this.suggestedPrompts[ind]
    this.sendQuery()
  }

  async fetchThread(threadId: string) {
    this.currentThreadId = threadId
    if (this.currentThreadId === 'new') {
      this.chatHistory = []
      return
    }
    try {
      this.chatHistory = []
      this.chatThreadHistoryLoading = true
      this.chatHistory = (await this.chatbotResource.getChatMessages(this.currentThreadId)).data
      // convert from markdown to html
      this.chatHistory.forEach((item) => {
        item.answer = this.md.render(this.preprocessMarkdownLinks(item.answer.replace(/\n$/, '')))
      })
      this.scrollToDiv()
    } catch (e) {
      handleError(e, this.$message)
    } finally {
      this.chatThreadHistoryLoading = false
    }
  }

  async getThreads(addNewChat = false) {
    try {
      this.threadsLoading = true
      this.chatThreads = []
      const response = (await this.chatbotResource.getUserChatThreads()).data
      for (const user of response.data.data) {
        this.chatThreads.push({
          name: user.name,
          id: user.id
        })
      }

      if (addNewChat && this.chatThreads.length === 0) {
        this.chatThreads = [{ id: 'new', name: 'New Chat' }]
        this.currentThreadId = 'new'
        this.chatHistory = []
      }
    } catch (e) {
      handleError(e, this.$message)
    } finally {
      this.threadsLoading = false
    }
  }

  async sendQuery() {
    if (!this.userQuery) {
      return
    }
    const payload: ChatBotPayload = {
      userId: UserModule.id,
      conversationId: this.currentThreadId === 'new' ? '' : this.currentThreadId,
      query: this.userQuery
    }
    this.chatHistory.push({
      id: String(randomId()),
      conversationId: String(randomId()),
      query: this.userQuery,
      answer: '',
      createdAt: 'Now',
      role: 'user'
    },
    {
      id: String(randomId()),
      conversationId: String(randomId()),
      query: this.userQuery,
      answer: 'true',
      createdAt: 'Now',
      role: 'assistant'
    })
    this.scrollToDiv()
    this.userQuery = ''
    try {
      const response = (await this.chatbotResource.getResponseForQuery(payload)).data
      const data = {
        id: response.data.id,
        conversationId: response.data.conversation_id,
        query: this.userQuery,
        answer: this.md.render(this.preprocessMarkdownLinks(response.data.answer.replace(/\n$/, ''))), // converting markdown to html
        createdAt: response.data.created_at,
        role: 'assistant'
      }
      if (this.currentThreadId === 'new') {
        this.currentThreadId = data.conversationId
        this.chatThreads[0].id = data.conversationId
        this.getThreads(false)
      }
      this.$set(this.chatHistory, this.chatHistory.length - 1, data)
      this.scrollToDiv()
    } catch (e) {
      handleError(e, this.$message)
    }
  }

  scrollToDiv(): void {
    setTimeout(() => {
      const element = document.getElementById('moveToChatEndDiv')
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }, 100)
  }

  async submitFeedback(messageId: string, rating: string, ind: number) {
    try {
      const data = {
        userId: UserModule.id,
        rating: rating
      }
      this.$set(this.chatHistory[ind], 'query', rating)
      await this.chatbotResource.submitFeedback(data, messageId)
      this.$message.success('Response submitted!')
    } catch (e) {
      handleError(e, this.$message)
    }
  }
}
