
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class WcIconMessage extends Vue {
  @Prop({ required: true }) isOpened!: boolean
  @Prop({ default: '#09121F' }) color!: string
  @Prop({ default: '16' }) height!: string
  @Prop({ default: '16' }) width!: string
}
