<template>
  <div :class="['w-full flex items-center button-type-1', {'active-link': isActiveLink}]">
    <RouterLink
      class="text-14px font-weight-400 text-decoration-none text-wcDarkGray h-100 flex items-center justify-center"
      v-if="isInternalLink" :to="to">
      <span v-if="isTextLabel" :class="['button-content', {'active-link': isActiveLink}]">{{ label }}</span>
      <span v-else v-html="icon" :class="['button-content', {'active-link': isActiveLink}]"></span>
    </RouterLink>
    <a v-else :href="to" target="_blank" rel="noopener noreferrer" class="text-14px font-weight-400 text-decoration-none">
      <span v-if="isTextLabel" :class="['button-content', {'active-link': isActiveLink}]">{{ label }}</span>
      <span v-else v-html="icon" :class="['button-content', {'active-link': isActiveLink}]"></span>
    </a>
  </div>
</template>

<script>
export default {
  name: 'RecruiterNavTab',
  props: {
    to: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: '#737475'
    }
  },
  computed: {
    isActiveLink() {
      return this.$route.path === this.to
    },
    isTextLabel() {
      return this.label !== '' && this.icon === ''
    },
    isInternalLink() {
      return this.to.startsWith('/') || this.to === ''
    }
  }
}
</script>

<style scoped>
* {
  font-family: 'Inter', sans-serif;
}

.button-type-1 {
  height: 56px;
  display: flex;
  align-items: center;
  position: relative;
  width: fit-content;
}

.button-type-1.active-link {
  transition: 0.75s ease-in;
  border-bottom: 2.5px solid #5138EE;
  height: 51px;
}

.button-content.active-link {
  color: #5138EE !important;
  fill: #5138EE !important;
}

.button-content.active-link svg {
  color: #5138EE !important;
  fill: #5138EE !important;
}</style>
