const metaKeywords = [
  'candidates',
  'list',
  'whitecarrot',
  'jobs',
  'timeline',
  'profile',
  'careers',
  'forgot password',
  'login',
  'apply job',
  'job not found',
  'quiz',
  'ats',
  'team',
  'reset password',
  'stage pipeline',
  'assignment'
]

export const meta = (title: string, description = title) => ({
  title,
  meta: [
    {
      vmid: 'description',
      name: 'description',
      content: description
    },
    {
      vmid: 'keywords',
      name: 'keywords',
      content: metaKeywords.join(', ')
    },
    {
      name: 'title',
      property: 'og:title',
      content: title
    },
    {
      name: 'description',
      property: 'og:description',
      content: description
    },
    {
      name: 'image',
      property: 'og:image',
      content:
        'https://res.cloudinary.com/whitecarrot/image/upload/v1667657049/company_logo/whitecarrot/full_text_logo_cpbn17.png'
    },
    {
      property: 'og:url',
      content: 'https://whitecarrot.io'
    }
  ]
})

export const isDevelopment = process.env.NODE_ENV === 'development'
export const isStaging = process.env.NODE_ENV === 'staging'
export const isProduction = process.env.NODE_ENV === 'production'
export const APP_DOMAIN = process.env.VUE_APP_DOMAIN || 'http://localhost:8080'
export const HIRED_STAGES = ['Hired', 'Joined']
