export const AgenciesEnglish = {
  title: 'Agencies',
  subTitle: 'Create agencies and assign jobs to let them submit the candidates.',
  tooltipText: 'Click here to learn more about agencies',
  addNewAgency: 'Add new agency',
  agencyLink: 'View agency portal',
  deleteNote: 'Are you sure to delete this agency?',
  deleteAgency: 'Delete agency',
  agencyDeletedSuccessfully: 'Agency deleted successfully',
  editAgency: 'Edit agency',
  agencyName: 'Agency name',
  primaryContactDetails: 'Agency primary contact details',
  invitationMailDescription: 'Add a person name and email from the agency who will receive invitation email.',
  assignJobs: 'Assign jobs',
  assignJobsDescription: 'Choose the job listing(s) that this agency is allowed to submit candidates for.',
  emailInvite: 'Email invite',
  sendEmailInvite: 'Send @:agencies.emailInvite',
  emailInviteDescription: 'This email will share agency portal link to contact person in the agency to submit candidates for assigned jobs',
  emailSubject: 'Email @:general.subject',
  invitationForAgencyPortal: 'Invitation for agency portal of {{company_name}}',
  placeHolders: {
    name: 'e.g. Innovation talent group',
    contactName: 'e.g. Sarah Gomez',
    email: 'e.g. sarah@abcdcorp.com',
    chooseJobPosting: 'Choose job posting'
  },
  table: {
    header: {
      agencyName: 'AGENCY NAME',
      primaryContact: 'PRIMARY CONTACT',
      assignedJobs: 'ASSIGNED JOBS',
      agencyLink: 'AGENCY LINK',
      actions: 'ACTIONS'
    },
    noAgenciesTitle: 'No agencies created yet!',
    noAgenciesDescription: 'All the agencies created will show up here!',
    createNewAgency: 'Create new agency'
  },
  errors: {
    inputEmailSubject: 'Please input email subject',
    inputAgencyName: 'Please input agency name',
    lengthAtleast3: 'Length should be at least {length} letters',
    inputPrimaryContact: 'Please input primary contact name',
    pleaseInput: 'Please input primary contact',
    primaryContactName: '@:agencies.errors.pleaseInput name',
    primaryContactEmail: '@:agencies.errors.pleaseInput email',
    inputValidEmail: 'Please input valid email',
    selectAtleastOneJob: 'Please select at least one job'
  },
  successMessages: {
    agencyCreated: 'Agency created successfully',
    agencyUpdated: 'Agency updated successfully',
    candidateSubmitted: 'Candidate submitted successfully.',
    uploaded: 'Uploaded successfully.'
  },
  dummyEmailBody: `
    <div>Hi,</div>
    <div>&nbsp;</div>
    <div>You have been assigned to jobs at {companyName}</div>
    <div>&nbsp;</div>
    <div>You can submit candidates using the link below:</div>
    <a style="color: #1c0b83; text-decoration: none;" href="{link}">Click here to access the portal</a>
    <div>&nbsp;</div>
    <div>If you have trouble accessing the above link, you can also copy and paste the following url in the browser.</div>
    <a style="color: #1c0b83; text-decoration: none; font-style: italic;" href="{link}">{link}</a>
    <div>&nbsp;</div>
    <div>Thank you</div>
    <div>{companyName}</div>
  `,
  agencyPortal: {
    agencyPortalFor: 'Agency portal for {name}',
    haveBeenAssignedJobs: 'You have been assigned <span style="color:#5138EE">{count}</span> jobs at {agency}.',
    searchByJobTitle: 'Search by job title',
    location: 'Location | Location ({count})',
    jobTypes: 'Job type | Job types ({count})',
    workPolicy: 'Work policy | Work policies ({count})',
    filterApplied: 'Filters applied:',
    submitCandidate: 'Submit candidate',
    jobsPoweredBy: 'Jobs powered by',
    fileUpload: 'File is uploaded successfully. Re upload to change',
    noFile: 'File not uploaded yet',
    candidateCV: 'Candidate CV',
    cv: 'CV',
    candidateEmail: 'Candidate email',
    candidateFirstName: 'Candidate first name',
    candidateLastName: 'Candidate last name',
    candidatePhone: 'Candidate phone number',
    submit: 'Submit candidate for {name}',
    resume: 'Resume must be a {var1} or {var2}',
    cannotExceed: 'Resume size can not exceed {value}MB!',
    pleaseInput: 'Please input {name}',
    pleaseUpload: 'Please upload candidate CV'
  }
}

export const AgenciesArabic = {
  title: 'شركات التوظيف',
  subTitle: 'أنشئ وكالات وقم بتعيين الوظائف للسماح لهم بتقديم المرشحين.',
  tooltipText: 'انقر هنا لمعرفة المزيد عن الوكالات',
  addNewAgency: 'إضافة شركة توظيف جديدة',
  agencyLink: 'عرض بوابة الوكالة',
  deleteNote: 'هل أنت متأكد من رغبتك في حذف هذه الشركة؟',
  deleteAgency: 'حذف الشركة',
  agencyDeletedSuccessfully: 'تم حذف هذه الشركة بنجاح',
  editAgency: 'تعديل ملف شركة التوظيف',
  agencyName: 'اسم شركة التوظيف',
  primaryContactDetails: 'بيانات الاتصال الرئيسية للشركة',
  invitationMailDescription: 'أضف اسم مستقبل الدعوة من قِبَل الشركة وبريده الإلكتروني.',
  assignJobs: 'تحديد الوظائف',
  assignJobsDescription: 'اختر مجموعة الوظائف التي يُسمح لهذه الشركة بتقديم مرشحين لها.',
  emailInvite: 'دعوة بواسطة الايميل',
  sendEmailInvite: 'إرسال @:agencies.emailInvite',
  emailInviteDescription: 'يحتوي هذا الايميل على رابط خاص ببوابة الشركة، يستطيع ممثل الشركة من خلاله ترشيح الموظفين للمناصب المفتوحة.',
  emailSubject: 'البريد الإلكتروني @:general.subject',
  invitationForAgencyPortal: 'الدعوة الخاصة برابط بوابة شركة {{company_name}}',
  placeHolders: {
    name: 'مثلًا؛ الوكالة السعودية',
    contactName: 'مثلًًا؛ سارة الشمري',
    email: 'مثلًا؛ sarah@abcdcorp.com',
    chooseJobPosting: 'انشر الوظيفة'
  },
  table: {
    header: {
      agencyName: 'اسم الشركة',
      primaryContact: 'بيانات الاتصال الأساسية',
      assignedJobs: 'الوظائف المعينة',
      agencyLink: 'رابط شركة التوظيف',
      actions: 'الإجراءات'
    },
    noAgenciesTitle: 'لم يتم إنشاء أي وكالات بعد!',
    noAgenciesDescription: 'جميع الوكالات التي تم إنشاؤها ستظهر هنا!',
    createNewAgency: 'إنشاء وكالة جديدة'
  },
  errors: {
    inputEmailSubject: 'رجاءً ادخل عنوان الايميل',
    inputAgencyName: 'رجاءً ادخل اسم شركة التوظيف',
    lengthAtleast3: 'يجب أن يكون طول الاسم على الأقل {length} حرفًا',
    inputPrimaryContact: 'رجاءً ادخل اسم جهة الاتصال الأساسية',
    pleaseInput: 'رجاءً ادخل بيانات جهة الاتصال الأساسية',
    primaryContactName: '@:agencies.errors.pleaseInput الاسم',
    primaryContactEmail: '@:agencies.errors.pleaseInput البريد الإلكتروني',
    inputValidEmail: 'رجاءً ادخل ايميل صحيح',
    selectAtleastOneJob: 'رجاءً حدد وظيفة واحدة على الأقل'
  },
  successMessages: {
    agencyCreated: 'تم إنشاء حساب الوكالة بنجاح',
    agencyUpdated: 'تم تحديث حساب الوكالة بنجاح',
    candidateSubmitted: 'تم تقديم المرشح بنجاح.',
    uploaded: 'تم التحميل بنجاح.'
  },
  dummyEmailBody: `
    <div>مرحبًا،</div>
    <div>&nbsp;</div>
    <div>لقد تم تعيينك في وظائف لدى {companyName}</div>
    <div>&nbsp;</div>
    <div>يمكنك تقديم المرشحين باستخدام الرابط أدناه:</div>
    <a style="color: #1c0b83; text-decoration: none;" href="{link}">انقر هنا للوصول إلى البوابة</a>
    <div>&nbsp;</div>
    <div>إذا واجهت مشكلة في الوصول إلى الرابط أعلاه، يمكنك أيضًا نسخ الرابط التالي ولصقه في المتصفح.</div>
    <a style="color: #1c0b83; text-decoration: none; font-style: italic;" href="{link}">{link}</a>
    <div>&nbsp;</div>
    <div>شكرًا لك</div>
    <div>{companyName}</div>
  `,
  agencyPortal: {
    agencyPortalFor: 'بوابة الوكالة لـ {name}',
    haveBeenAssignedJobs: 'تم تعيينك لـ <span style="color:#5138EE">{count}</span> وظائف في {agency}.',
    searchByJobTitle: 'البحث حسب عنوان الوظيفة',
    location: 'الموقع | الموقع ({count}',
    jobTypes: 'نوع الوظيفة | أنواع الوظائف ({count})',
    workPolicy: 'سياسة العمل | سياسات العمل ({count})',
    filterApplied: 'تم تطبيق الفلاتر:',
    submitCandidate: 'تقديم مرشح',
    jobsPoweredBy: 'وظائف يتم تشغيلها بواسطة',
    fileUpload: 'تم تحميل الملف بنجاح. إعادة التحميل لتغيير',
    noFile: 'لم يتم تحميل الملف بعد',
    candidateCV: 'السيرة الذاتية للمرشح',
    cv: 'السيرة الذاتية',
    candidateEmail: 'البريد الإلكتروني للمرشح',
    candidateFirstName: 'الاسم الأول للمرشح',
    candidateLastName: 'الاسم الأخير للمرشح',
    candidatePhone: 'رقم هاتف المرشح',
    submit: 'تقديم المرشح لـ {name}',
    resume: 'يجب أن تكون السيرة الذاتية {var1} أو {var2}',
    cannotExceed: 'لا يمكن تجاوز حجم السيرة الذاتية {value}MB!',
    pleaseInput: 'الرجاء إدخال {name}',
    pleaseUpload: 'الرجاء تحميل سيرة ذاتية للمرشح'
  }
}
