import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import store from '@/store'
import { IBookingLinkObject } from '@/views/recruiter/calendar/calendar.models'
import CalendarResource from '@/api/calendar'
import { CompanyEmailTemplatesEntity } from '@/views/company-email-templates/models'
import CompanyEmailTemplatesResource from '@/api/companyEmailTemplates'
import { IArchivalReasons } from '@/views/candidate-timeline/models/candidate-profile-response'
import ArchivalReasonsResource from '@/api/archivalReasons'

export interface ISendEmails {
  companyEmailTemplates: CompanyEmailTemplatesEntity[]
  bookingLinks: IBookingLinkObject[]
  archivalReasons: IArchivalReasons[]
}

@Module({ dynamic: true, store, name: 'emails', namespaced: true })
class SendEmails extends VuexModule implements ISendEmails {
  companyEmailTemplates: CompanyEmailTemplatesEntity[] = []
  bookingLinks: IBookingLinkObject[] = []
  archivalReasons: IArchivalReasons[] = []

  @Mutation
  private SET_BOOKING_LINKS(bookingLinks: IBookingLinkObject[]) {
    this.bookingLinks = bookingLinks
  }

  @Mutation
  private SET_COMPANY_EMAILS(companyEmailTemplates: CompanyEmailTemplatesEntity[]) {
    this.companyEmailTemplates = companyEmailTemplates
  }

  @Mutation
  public SET_COMPANY_ARCHIVAL_REASONS(archivalReasons: IArchivalReasons[]) {
    this.archivalReasons = archivalReasons
  }

  @Action
  private async fetchCompanyEmails() {
    try {
      const companyEmailTemplatesResource = new CompanyEmailTemplatesResource()
      return (await companyEmailTemplatesResource.getTemplatesForCompany()).data as CompanyEmailTemplatesEntity[]
    } catch (e) {
      return []
    }
  }

  @Action
  private async fetchBookingLinks() {
    try {
      const calendarResource = new CalendarResource()
      return (await calendarResource.getBookingLinksForCompany('userIds=true')).data as IBookingLinkObject[]
    } catch (error) {
      return []
    }
  }

  @Action
  public async fetchArchivalReasons() {
    try {
      const archivalReasonsResource = new ArchivalReasonsResource()
      return (await archivalReasonsResource.getArchivalReasonsForCompany()).data as IArchivalReasons[]
    } catch (e) {
      return []
    }
  }

  @Action
  public async setBookingLinks(force = false) {
    try {
      if (force || this.bookingLinks.length === 0) {
        const bookingLinks = await this.context.dispatch('fetchBookingLinks')
        this.context.commit('SET_BOOKING_LINKS', bookingLinks)
      }
    } catch (e) {

    }
  }

  @Action
  public async setCompanyEmails(force = false) {
    try {
      if (force || this.companyEmailTemplates.length === 0) {
        const emails = await this.context.dispatch('fetchCompanyEmails')
        this.context.commit('SET_COMPANY_EMAILS', emails)
      }
    } catch (e) {

    }
  }

  @Action
  public async setArchivalReasons(force = false) {
    try {
      if (force || this.archivalReasons.length === 0) {
        const archivalReasons = await this.context.dispatch('fetchArchivalReasons')
        this.context.commit('SET_COMPANY_ARCHIVAL_REASONS', archivalReasons)
      }
    } catch (e) {

    }
  }

  get getCompanyEmailTemplates(): CompanyEmailTemplatesEntity[] {
    return this.companyEmailTemplates
  }

  get getBookingLinks(): IBookingLinkObject[] {
    return this.bookingLinks
  }

  get getArchivalReasons(): IArchivalReasons[] {
    return this.archivalReasons
  }
}

export const SendEmailsModule = getModule(SendEmails)
