
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'WcIconMenu',
  components: {
  }
})
export default class extends Vue {
  @Prop({ default: '#5138ee' }) private color!: String;
}
