
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'WcIconLike',
  components: {
  },
  props: {
    color: String
  }
})
export default class extends Vue {
  @Prop({ default: 'none' }) color!: string;
}
