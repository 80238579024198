export const ManageScoreCardArabic = {
  title: 'قوالب ملاحظات المقابلة',
  subTitle: 'قم بإنشاء قوالب تعليقات المقابلة لإضافة أسئلة قائمة على المهارات وتقييم المرشحين بعد المقابلة.',
  tooltipText: 'انقر هنا لمعرفة المزيد حول قالب التعليقات على المقابلة',
  newTemplate: 'نموذج جديد',
  table: {
    templateName: 'اسم القالب',
    skills: 'المهارات',
    actions: 'الإجراءات',
    noTemplatesTitle: 'لم يتم إنشاء قوالب تعليقات المقابلة حتى الآن!',
    noTemplatesDescription: 'ستظهر جميع قوالب التعليقات التي تم إنشاؤها هنا!',
    createNewScorecard: 'إنشاء قالب ملاحظات جديد'
  },
  dialogue: {
    create: 'إنشاء نموذج بريد إلكتروني جديد',
    edit: 'تعديل النموذج',
    deleteConfirmation: 'هل أنت متأكد من حذف هذا النموذج؟',
    delete: 'حذف النموذج',
    cannotDelete: 'لا يمكن حذف هذا النموذج'
  },
  successMessages: {
    created: 'تم إنشاء النموذج بنجاح!',
    updated: 'تم تحديث النموذج بنجاح!',
    deleted: 'تم حذف النموذج بنجاح!',
    successfullySubmittedResponse: 'تم تقديم الاستجابة بنجاح',
    successfullyUpdatedResponse: 'تم تحديث الاستجابة بنجاح'
  },
  templateCreateForm: {
    addInterviewScoreCardTemplate: 'أضف قالب تعليقات المقابلة',
    templateName: 'اسم النموذج',
    subTitle: 'سيتم عرض هذا عند تحديد التعليقات أثناء جدولة المقابلة أو إنشاء رابط الحجز.',
    addTemplateName: 'اسم النموذج',
    skillsPlaceholder: 'أضف أسئلة إرشادية للمحاور لمساعدتهم في تقييم هذه المهارة',
    addQuestion: 'إضافة سؤال',
    generateQuestionsUsingAI: 'توليد الأسئلة باستخدام الذكاء الاصطناعي',
    addAnotherSkillOrCompetency: 'إضافة مهارة أو كفاءة أخرى',
    addSkillName: 'إضافة اسم المهارة',
    addSampleAnswer: 'إضافة إجابة نموذجية',
    removeSampleAnswer: 'إزالة الإجابة النموذجية',
    generateScoreCardUsingAI: 'إنشاء قالب تعليقات للمقابلة باستخدام الذكاء الاصطناعي'
  },
  addScoreForm: {
    addInterviewNotes: 'أضف ملاحظات المقابلة هنا (اختياري)',
    addSecretNotes: 'أضف ملاحظات سرية هنا. (اختياري)',
    proceedToNextStage: 'هل يجب أن ينتقل هذا المرشح إلى المرحلة التالية؟',
    interviewStatus: 'حالة المقابلة',
    secretNoteInfo: 'سيكون هذا مرئيًا لجميع الأشخاص ذوي الوصول الكامل والوصول المحدود بإذن الملاحظات السرية',
    addSecretNotesComment: 'أضف ملاحظات سرية',
    removeSecretNotesComment: 'إزالة الملاحظات السرية',
    lowest: 'أدنى',
    highest: 'أعلى',
    scorecardTemplate: 'قالب ردود الفعل',
    chooseScorecardTemplate: 'اختر قالب ردود الفعل لتقييم المرشحين بعد المقابلة.',
    viewCandidateProfile: 'عرض الملف الشخصي',
    interviewScorecard: 'ردود الفعل المقابلة',
    sampleAnswer: 'إجابة نموذجية',
    selectScorecard: 'حدد ردود الفعل',
    previousSubmittedScores: 'ردود الفعل السابقة',
    noPreviousScorecard: 'لا توجد تعليقات سابقة',
    viewScores: 'عرض ردود الفعل',
    scoresNotAdded: 'يرجى تقديم ملاحظاتك لجميع الحقول المطلوبة',
    goBackToCandidateProfile: 'العودة إلى ملف المرشح',
    markAsNoShow: 'وضع علامة على عدم العرض',
    markedAsNoShow: 'تم وضع علامة "عدم الحضور".'
  },
  noShowDialog: {
    confirmInterviewAsNoShowTitle: 'تأكيد وضع علامة على المقابلة على أنها \'لم يحضر\'',
    confirmInterviewAsNoShowSubTitle: 'هل أنت متأكد أنك تريد وضع علامة على هذه المقابلة على أنها \'لم يحضر\'؟ يمكنك تعديلها لاحقًا إذا لزم الأمر.',
    confirmInterviewAsNoShowButton: 'تأكيد',
    canNotSetInterviewAsNoShowTitle: 'تم إرسال التعليقات بالفعل لهذه المقابلة',
    canNotSetInterviewAsNoShowSubTitle: 'لا يمكنك وضع علامة على هذه المقابلة على أنها \'لم يحضر\' لأن التعليقات تم إرسالها بالفعل من قبل المقيمين الآخرين.',
    canNotSetInterviewAsNoShowButton: 'إلغاء'
  }
}

export const ManageScoreCardEnglish = {
  title: 'Interview feedback templates',
  subTitle: 'Create interview feedback templates to add skill-based questions and evaluate candidates after the interview.',
  tooltipText: 'Click here to learn more about interview feedback template',
  newTemplate: 'New template',
  table: {
    templateName: 'TEMPLATE NAME',
    skills: 'SKILLS',
    actions: 'ACTIONS',
    noTemplatesTitle: 'No interview feedback templates created yet!',
    noTemplatesDescription: 'All the feedback templates created will show up here!',
    createNewScorecard: 'Create new feedback template'
  },
  dialogue: {
    create: 'Create new email template',
    edit: 'Edit template',
    deleteConfirmation: 'Are you sure to delete this template?',
    delete: 'Delete template',
    cannotDelete: 'Cannot delete this template'
  },
  successMessages: {
    created: 'Template created successfully!',
    updated: 'Template updated successfully!',
    deleted: 'Template deleted successfully!',
    submittedResponse: 'Successfully submitted response',
    updatedResponse: 'Successfully updated response'
  },
  templateCreateForm: {
    addInterviewScoreCardTemplate: 'Add interview feedback template',
    templateName: 'Template name',
    subTitle: 'This will be displayed when selecting a feedback during interview scheduling or booking link creation.',
    addTemplateName: 'Add template name',
    skillsPlaceholder: 'Add guiding questions for the interviewer to help them evaluate this skill',
    addQuestion: 'Add question',
    generateQuestionsUsingAI: 'Generate questions using AI',
    addAnotherSkillOrCompetency: 'Add another skill or competency',
    addSkillName: 'Add skill name',
    addSampleAnswer: 'Add sample answer',
    removeSampleAnswer: 'Remove sample answer',
    generateScoreCardUsingAI: 'Generate interview feedback template using AI'
  },
  addScoreForm: {
    addInterviewNotes: 'Add interview notes here (Optional)',
    addSecretNotes: 'Add secret notes here. (Optional)',
    proceedToNextStage: 'Should this candidate proceed to the next stage?',
    interviewStatus: 'Interview status',
    secretNoteInfo: 'This will be visible to all full access and limited access with secret notes permission',
    addSecretNotesComment: 'Add secret notes',
    removeSecretNotesComment: 'Remove secret notes',
    lowest: 'Lowest',
    highest: 'Highest',
    scorecardTemplate: 'Feedback template',
    chooseScorecardTemplate: 'Choose a feedback template to evaluate candidates after the interview.',
    viewCandidateProfile: 'View profile',
    interviewScorecard: 'Interview feedback',
    sampleAnswer: 'Sample answer',
    selectScorecard: 'Select feedback',
    previousSubmittedScores: 'Previous feedback',
    noPreviousScorecard: 'No previous feedbacks',
    viewScores: 'View feedback',
    scoresNotAdded: 'Please give your feedback for all the required fields',
    goBackToCandidateProfile: 'Go back to candidate profile',
    markAsNoShow: 'Mark as no show',
    markedAsNoShow: 'Marked as No Show'
  },
  noShowDialog: {
    confirmInterviewAsNoShowTitle: 'Confirm marking interview as \'No Show\'',
    confirmInterviewAsNoShowSubTitle: 'Are you sure you want to mark this interview as \'No Show\'? You can edit it later if needed.',
    confirmInterviewAsNoShowButton: 'Confirm',
    canNotSetInterviewAsNoShowTitle: 'Feedback already submitted for this interview',
    canNotSetInterviewAsNoShowSubTitle: 'You cannot mark this interview as \'No Show\' because feedback has already been submitted by other interviewers.',
    canNotSetInterviewAsNoShowButton: 'Cancel'
  }
}
