
import { Component, Prop, Vue } from 'vue-property-decorator'
@Component({
  name: 'WorldIcon'

})
export default class extends Vue {
  @Prop({ default: '#ffffff' }) color!: String;
  @Prop({ default: '20' }) height!: String;
  @Prop({ default: '20' }) width!: String;
}
