
import { UserModule } from '@/store/modules/user'

export default {
  inserted(el, binding) {
    const { value } = binding
    const permissions = UserModule.permissions

    if (value && value instanceof Array && value.length > 0) {
      const hasPermission = value.every(permission => {
        return permissions.includes(permission)
      })

      if (!hasPermission) {
        el.parentNode && el.parentNode.removeChild(el)
      }
    } else {
      throw new Error('Permissions are required! Example: v-permission="[\'manage user\',\'manage permission\']"')
    }
  }
}
