export enum JobRoleAccessTypeEnum {
  FULL_ACCESS = 'Full Access',
  LIMITED_ACCESS = 'Limited Access',
  EMPLOYEE = 'Employee',
  CANDIDATE = 'Candidate',
  AGENCY_USER = 'Agency User',
  WC_ADMIN = 'Admin'
}

export enum PreviewPortalEnums {
  JOB_PREVIEW = 'job-preview',
  APPLY_PORTAL = 'apply-portal',
  APPLICATION_PREVIEW = 'application-collect-info',
  WELCOME_PAGE = 'welcome-page',
  ADD_CANDIDATE_TASKS = 'add-candidate-tasks'
}

export enum JobParamsMode {
  EDIT = 'edit',
  VIEW = 'VIEW'
}
