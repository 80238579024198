export const SetReminderEnglish = {
  pleaseNote: 'Please note',
  applicationRejectedMessage: 'Your application will be automatically rejected if you do not take the quiz within 7 days.',
  thankYou: 'Thank you',
  reminder: 'Reminder',
  appreciateYourInterest: 'We really appreciate your interest in joining {companyName} 😊',
  hearFromUsON: 'You will hear from us on <b>{Date}</b>',
  discoverMore: 'Discover more about {companyName}',
  formError: 'The reminder date field is required'
}
export const SetReminderArabic = {
  pleaseNote: 'يرجى ملاحظة',
  applicationRejectedMessage: 'سيتم رفض طلبك تلقائيًا إذا لم تقم بإجراء الاختبار في غضون 7 days',
  thankYou: 'شكرًا لك',
  reminder: 'تذكير',
  appreciateYourInterest: 'نحن نقدر حقًا اهتمامك بالانضمام إلى {companyName} 😊',
  hearFromUsON: 'ستسمع منا في <b>{Date}</b>',
  discoverMore: 'اكتشف المزيد عن {companyName}',
  formError: 'حقل تاريخ التذكير مطلوب'
}
