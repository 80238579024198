export const InterviewPageEnglish = {
  title: 'Interviews',
  upcoming: 'Upcoming',
  noUpcoming: 'You do not have any interviews scheduled for this date.',
  noCompleted: 'No completed interviews',
  completed: 'Completed',
  scheduledFor: 'Scheduled for',
  doneOn: 'Done on',
  meetingLink: 'Join',
  addScore: 'Add feedback',
  viewScore: 'View feedback',
  youAreOrganizer: 'You are only the organiser',
  noPendingScoreToSubmit: 'You do not have any pending feedbacks to submit!'
}

export const InterviewPageArabic = {
  title: 'المقابلات',
  upcoming: 'القادمة',
  noUpcoming: 'ليس لديك أي مقابلات مجدولة لهذا التاريخ.',
  noCompleted: 'لا توجد مقابلات مكتملة',
  completed: 'تمت',
  scheduledFor: 'مجدولة لـ',
  doneOn: 'تمت في',
  meetingLink: 'رابط الاجتماع',
  addScore: 'إضافة ملاحظات',
  viewScore: 'عرض الملاحظات',
  youAreOrganizer: 'أنت فقط المنظم',
  noPendingScoreToSubmit: 'ليس لديك أي ملاحظات معلقة لتقديمها!'
}
