import { RouteConfig } from 'vue-router'
import AdminLayout from '@/admin-layout/index.vue'
import { RolesEnum } from '@/views/recruiter/models/roles-model'
import { checkRole } from '@/store/modules/permission'

export const ADMIN_ROUTES: RouteConfig[] = [
  {
    path: '/admin',
    component: AdminLayout,
    meta: {
      title: 'Admin',
      icon: 'dashboard',
      roles: [RolesEnum.WC_ADMIN]
    },
    beforeEnter: (to, from, next): void => {
      if (checkRole(RolesEnum.WC_ADMIN)) {
        next()
      } else {
        next('/recruiter/home')
      }
    },
    children: [
      {
        path: 'dashboard',
        component: () => import(/* webpackChunkName: "dashboardHome" */ '@/views/dashboard/index.vue'),
        meta: {
          title: 'Dashboard',
          icon: 'dashboard',
          roles: [RolesEnum.WC_ADMIN]
        }
      },
      {
        path: 'create-user',
        component: () => import(/* webpackChunkName: "createNewUser" */ '@/views/admin/users/Users.vue'),
        meta: {
          title: 'Create User',
          icon: 'tree',
          roles: [RolesEnum.WC_ADMIN]
        }
      },
      {
        path: 'dynamic-configs',
        component: () => import(/* webpackChunkName: "dynamicConfigs" */ '@/views/admin-dynamic-configs/index.vue'),
        meta: {
          title: 'Dynamic Configs',
          icon: 'tree',
          roles: [RolesEnum.WC_ADMIN]
        }
      },
      {
        path: 'company',
        component: () => import(/* webpackChunkName: "createCompany" */ '@/views/admin/company/Company.vue'),
        meta: {
          title: 'Companies',
          icon: 'tree',
          roles: [RolesEnum.WC_ADMIN]
        }
      },
      {
        path: 'analytics',
        component: () => import(/* webpackChunkName: "adminAnalytics" */ '@/views/admin/analytics/Analytics.vue'),
        meta: {
          title: 'Analytics',
          icon: 'tree',
          roles: [RolesEnum.WC_ADMIN]
        }
      },
      {
        path: 'admin-job-applications',
        component: () => import(/* webpackChunkName: "adminApplicationsHome" */ '@/views/admin-job-applications/index.vue'),
        meta: {
          title: 'Job Applications',
          icon: 'tree',
          roles: [RolesEnum.WC_ADMIN]
        }
      },
      {
        path: 'emails-to-send',
        component: () => import(/* webpackChunkName: "emailsToSendHome" */ '@/views/emails-to-send/index.vue'),
        meta: {
          title: 'Emails',
          icon: 'tree',
          roles: [RolesEnum.WC_ADMIN]
        }
      },
      {
        path: 'user-assignments',
        component: () => import(/* webpackChunkName: "adminAssignmentsHome" */ '@/views/admin-assignments/index.vue'),
        meta: {
          title: 'User Assignments',
          icon: 'tree',
          roles: [RolesEnum.WC_ADMIN]
        }
      },
      {
        path: 'company-roles',
        component: () => import(/* webpackChunkName: "adminRolesHome" */ '@/views/admin-company-roles/index.vue'),
        meta: {
          title: 'All Roles',
          icon: 'tree',
          roles: [RolesEnum.WC_ADMIN]
        }
      }
    ]
  },
  {
    path: '/questions',
    component: AdminLayout,
    meta: {
      title: 'Questions',
      icon: 'tree',
      roles: [RolesEnum.WC_ADMIN]
    },
    children: [
      {
        path: '/questions',
        component: () => import(/* webpackChunkName: "adminQuestionsHome" */ '@/views/admin-questions/index.vue'),
        meta: {
          title: 'Questions',
          icon: 'tree',
          roles: [RolesEnum.WC_ADMIN]
        }
      },
      {
        path: '/add-new',
        component: () => import(/* webpackChunkName: "addQuestion" */ '@/views/admin-questions/add-question.vue'),
        meta: {
          title: 'New Questions',
          icon: 'tree',
          roles: [RolesEnum.WC_ADMIN]
        }
      },
      {
        path: '/excel-upload',
        component: () => import(/* webpackChunkName: "excelUpload" */ '@/views/admin-questions/excel-upload.vue'),
        meta: {
          title: 'Excel Upload',
          icon: 'tree',
          roles: [RolesEnum.WC_ADMIN]
        }
      },
      {
        path: '/quiz-departments',
        component: () => import(/* webpackChunkName: "adminDepartment" */ '@/views/admin-questions/admin-department.vue'),
        meta: {
          title: 'Departments',
          icon: 'tree',
          roles: [RolesEnum.WC_ADMIN]
        }
      },
      {
        path: '/quiz-skill-blocks',
        component: () => import(/* webpackChunkName: "adminSkillBlock" */ '@/views/admin-questions/admin-skill-blocks.vue'),
        meta: {
          title: 'Skill Blocks',
          icon: 'tree',
          roles: [RolesEnum.WC_ADMIN]
        }
      },
      {
        path: '/job-role-name',
        component: () => import(/* webpackChunkName: "jobRoleNames" */ '@/views/admin-questions/job-role-names.vue'),
        meta: {
          title: 'Job Role Variations',
          icon: 'tree',
          roles: [RolesEnum.WC_ADMIN]
        }
      }
    ]
  },
  {
    path: '/tests',
    component: AdminLayout,
    meta: {
      title: 'Tests',
      icon: 'tree',
      roles: [RolesEnum.WC_ADMIN]
    },
    children: [
      {
        path: '/tests',
        component: () => import(/* webpackChunkName: "screeningTests" */ '@/views/admin/tests/Tests.vue'),
        meta: {
          title: 'Tests',
          icon: 'tree',
          roles: [RolesEnum.WC_ADMIN]
        }
      }
    ]
  }
]
