
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'WcIconEmail',
  components: {}
})
export default class extends Vue {
  @Prop({ default: '#040410' }) private color!: String
  @Prop({ default: '18' }) width!: string
  @Prop({ default: '18' }) height!: string
}
