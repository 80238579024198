
import { Vue, Component } from 'vue-property-decorator'
import BoxesShimmer from '@/components/UILibrary/Shimmers/BoxesShimmer.vue'
import LinesShimmer from '@/components/UILibrary/Shimmers/LinesShimmer.vue'

@Component({
  components: { LinesShimmer, BoxesShimmer }
})
export default class InboxLoading extends Vue {

}
