export const WorkPlaceTypesEnumArabic = {
  onsite: 'الحضور بالمكتب',
  hybrid: 'نظام حضور هجبن',
  remote: 'عمل عن بُعد'
}

export const WorkPlaceTypesEnumEnglish = {
  onsite: 'Onsite | ONSITE',
  hybrid: 'Hybrid | HYBRID',
  remote: 'Remote | REMOTE'
}
