import VueRouter from 'vue-router'
import Vue from 'vue'
import { PermissionService } from '@/permission'
import { PUBLIC_ROUTES } from '@/routes/public-routes'
import { WILDCARD_ROUTE } from '@/routes/wild-card.routes'
import { RECRUITER_ROUTES } from '@/routes/recruiter-routes'
import { CANDIDATE_ROUTES } from '@/routes/candidate-routes'
import { ADMIN_ROUTES } from '@/routes/admin-routes'

Vue.use(VueRouter)

const createRouter = () => new VueRouter({
  // mode: 'history', // Enable this if you need.
  scrollBehavior: () => { // we can access params to, from , savedPosition
    return { x: 0, y: 0 } // we don't want the scroll saved position for page switching
  },
  base: process.env.BASE_URL,
  routes: [...PUBLIC_ROUTES, ...CANDIDATE_ROUTES, ...RECRUITER_ROUTES, ...WILDCARD_ROUTE, ...ADMIN_ROUTES]
})

const router = createRouter()
router.beforeEach(PermissionService.beforeEach)
router.afterEach(PermissionService.afterEach)

export const resetRouter = (): void => {
  const newRouter = createRouter();
  (router as any).matcher = (newRouter as any).matcher // reset router
}

export default router
