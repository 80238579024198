export const QuizEnglish = {
  additionalResources: 'Additional resources',
  quiz: 'Quiz',
  quizSummary: 'You have to spend about {minutes} minutes of your uninterrupted time to take the quiz and complete your application.',
  testSummary: 'This psychometric test will take approximately {minutes} minutes of your time. It’s designed to help us understand your personality and behavior as part of the application process.',
  aboutTheTest: 'About the test',
  instructions: 'Instructions',
  setARemainder: 'Set a reminder',
  startQuiz: 'Start quiz',
  startTest: 'Start test',
  quizConfirmation: 'Quiz confirmation',
  quizConfirmCheckboxLabel: 'I understand that I have to complete the test in the next {minutes} to avoid rejection of my application.',
  pleaseConfirm: 'Please confirm',
  quizResume: {
    completeToSubmit: 'Complete the quiz to submit your application',
    receivedPartially: 'Your application for {roleName} is received partially. Complete the quiz to submit your application.'
  },
  timeout: {
    outOfTime: 'Time ran out for that question',
    instructions: 'For the rest of the questions, try to make your best guess in the time given. You can keep an eye on the timer in the top right corner.'
  },
  submitTimeout: {
    submit: 'Your quiz is auto submitted because of timeout.'
  },
  reportDialog: {
    reportProblem: 'Report problem',
    selectCategory: 'Select a category for this issue',
    provideDetails: 'Please provide the detailed description if possible'
  },
  reportProblem: 'Report a problem',
  submissionReceived: 'Your submission is received.',
  instructionsFields: {
    estimatedTime: 'Estimated time: ',
    format: 'Format',
    totalQuestions: 'Total number of questions',
    aboutQuiz: 'This quiz is built to test the basics required for this role.',
    avoidSwitching: 'Avoid switching between tabs or browsing the web during the test.',
    useBrowser: 'Use a standard browser window (avoid incognito mode).',
    readEachStatementCarefully: 'Read each statement carefully and choose your option.',
    psychometricTestNote: 'This is purely about your personality and behaviour, so focus on that as you work through the questions.',
    bestGuess: 'Make your best guess on every question, using as much of the provided time as you like. Your score is not impacted by how fast you answer.',
    unanswerdQuestions: 'Unanswered questions are considered incorrect.',
    ensureDedicatedTime: 'Ensure you can dedicate {minutes} uninterrupted minutes to complete the test.'
  },
  note: {
    minAndMaxTime: 'The quiz only takes {minTime} to complete, but if you are interrupted, you can come back to it within {maxTime}.',
    goBack: 'Go back if you don\'t have uninterrupted {minutes} or if you want to update your application.'
  },
  answerResponse: {
    submit: 'Submit',
    selectYourAnswer: 'Select your answer',
    chooseTheAnswer: 'Choose the answer'
  },
  errorMessage: {
    select: 'Please select',
    chooseAnswer: 'Please choose the answer before submitting',
    reviewSubmitted: 'Your review is submitted. We will try to resolve it earliest.',
    answerSubmitted: 'Answer submitted successfully.',
    quizCompleted: 'Your quiz is completed.'
  },
  completed: {
    applicationReceived: 'Your application for {roleName} is received'
  }
}

export const QuizArabic = {
  additionalResources: 'موارد إضافية',
  quiz: 'اختبار',
  quizSummary: 'يجب عليك تخصيص حوالي {minutes} من وقتك المستمر لإجراء الاختبار وإكمال طلبك.',
  testSummary: 'سيستغرق هذا الاختبار النفسي حوالي {minutes} دقيقة من وقتك. إنه مصمم لمساعدتنا على فهم شخصيتك وسلوكك كجزء من عملية التقديم.',
  aboutTheTest: 'عن الاختبار',
  instructions: 'التعليمات',
  setARemainder: 'تعيين تذكير',
  startQuiz: 'ابدأ الاختبار',
  startTest: 'ابدأ الاختبار',
  quizConfirmation: 'تأكيد الاختبار',
  quizConfirmCheckboxLabel: 'أفهم أنه يجب علي إكمال الاختبار في غضون {minutes} لتجنب رفض طلبي.',
  pleaseConfirm: 'يرجى التأكيد',
  quizResume: {
    completeToSubmit: 'أكمل الاختبار لتقديم طلبك',
    receivedPartially: 'تم استلام طلبك لوظيفة {roleName} جزئيًا. أكمل الاختبار لتقديم طلبك.'
  },
  timeout: {
    outOfTime: 'نفد الوقت لهذا السؤال',
    instructions: 'بالنسبة لبقية الأسئلة، حاول تقديم أفضل تخمين لديك في الوقت المحدد. يمكنك مراقبة المؤقت في الزاوية العلوية اليمنى.'
  },
  submitTimeout: {
    submit: 'تم إرسال اختبارك تلقائيًا بسبب انتهاء الوقت.'
  },
  reportDialog: {
    reportProblem: 'الإبلاغ عن مشكلة',
    selectCategory: 'اختر فئة لهذه المشكلة',
    provideDetails: 'يرجى تقديم وصف مفصل إن أمكن'
  },
  reportProblem: 'الإبلاغ عن مشكلة',
  submissionReceived: 'تم استلام تقديمك.',
  instructionsFields: {
    estimatedTime: 'الوقت المقدر: ',
    format: 'التنسيق',
    totalQuestions: 'العدد الإجمالي للأسئلة',
    aboutQuiz: 'تم تصميم هذا الاختبار لاختبار الأساسيات المطلوبة لهذه الوظيفة.',
    avoidSwitching: 'تجنب التبديل بين علامات التبويب أو تصفح الإنترنت أثناء الاختبار.',
    useBrowser: 'استخدم نافذة متصفح عادية (تجنب وضع التصفح المتخفي).',
    readEachStatementCarefully: 'اقرأ كل بيان بعناية واختر خيارك.',
    psychometricTestNote: 'هذا الاختبار يتعلق بشخصيتك وسلوكك فقط، لذا ركز على ذلك أثناء الإجابة على الأسئلة.',
    bestGuess: 'قم بأفضل تخمين لديك لكل سؤال، باستخدام الوقت المقدم لك كما تشاء. لا يتأثر تقييمك بسرعة الإجابة.',
    unanswerdQuestions: 'تعتبر الأسئلة غير المجابة غير صحيحة.',
    ensureDedicatedTime: 'تأكد من أنك تستطيع تخصيص {minutes} دقيقة متواصلة لإكمال الاختبار.'
  },
  note: {
    minAndMaxTime: 'يستغرق الاختبار فقط {minTime} لإكماله، ولكن إذا تم مقاطعتك، يمكنك العودة إليه في غضون {maxTime}.',
    goBack: 'ارجع إذا لم يكن لديك {minutes} متواصلة أو إذا كنت ترغب في تحديث طلبك.'
  },
  answerResponse: {
    submit: 'إرسال',
    selectYourAnswer: 'اختر إجابتك',
    chooseTheAnswer: 'اختر الإجابة'
  },
  errorMessage: {
    select: 'يرجى الاختيار',
    chooseAnswer: 'يرجى اختيار الإجابة قبل الإرسال',
    reviewSubmitted: 'تم إرسال مراجعتك. سنحاول حلها في أقرب وقت ممكن.',
    answerSubmitted: 'تم إرسال الإجابة بنجاح.',
    quizCompleted: 'تم اكتمال اختبارك.'
  },
  completed: {
    applicationReceived: 'تم استلام طلبك لوظيفة {roleName}'
  }
}
