
import { Vue, Component } from 'vue-property-decorator'
import WcDialog from '@/components/UILibrary/WcDialog.vue'
import { isMobile } from '@/utils/general'
import WcTypography from '@/components/UILibrary/Typography/WcTypography.vue'
import Button from '@/components/UILibrary/Buttons/Button.vue'
import WcIconOutDatedApp from '@/components/UILibrary/Icons/WcIconOutDatedApp.vue'
import WcIconExternalLink from '@/components/UILibrary/Icons/WcIconExternalLink.vue'
import WcIconScheduleInterview from '@/components/UILibrary/Icons/WcIconScheduleInterview.vue'

@Component({
  methods: { isMobile },
  components: { WcIconScheduleInterview, WcIconExternalLink, WcIconOutDatedApp, Button, WcTypography, WcDialog }
})
export default class OutDatedApp extends Vue {
  click(): void {
    this.$emit('click')
  }

  t(key: string): string {
    return this.$t(`general.${key}`).toString()
  }
}

