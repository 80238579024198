
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'WcIconOffer',
  components: {}
})
export default class extends Vue {
  @Prop({ default: '#040410' }) private color!: String
}
