export const sendMessage = (message: { type: string, key: string, value?: string}): void => {
  window.parent.postMessage(message, '*')
}

export const openLinkInNewTab = (link: string): void => {
  window.parent.postMessage({ url: link, action: 'openInNewTab' }, '*')
}

export const storeToExtension = (key: string, value: string): void => {
  sendMessage({ type: 'set', key, value })
}

export const getFromExtension = (key: string): void => {
  sendMessage({ type: 'get', key })
}
