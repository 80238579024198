export const DummyDataEnglish = {
  dummyJobs: 'DUMMY JOBS',
  deleteDummyData: 'Delete dummy data',
  exploreWithDummyData: 'Explore the product with dummy data',
  getToKnowBetterWithDummyData: 'Get to know Whitecarrot better with dummy jobs, candidates, email templates, stage pipeline, and archival reasons. You can also see how Whitecarrot\'s dummy job reports reveal helpful insights and data to inform your hiring.',
  loadDummyData: 'Load dummy data',
  preparingDummyData: 'Preparing dummy data',
  processing: 'processing',
  dialog: {
    creatingDummyData: 'Creating dummy jobs, candidates, email templates, stage pipeline, and archival reasons',
    deleteDummyJobData: 'You\'re about to delete dummy job data, including related candidates and reports.',
    cantRetrieveDummyData: 'You can\'t retrieve dummy data after you delete it.'
  },
  dummyData: 'Dummy data',
  message: {
    addedSuccessfully: 'Dummy data added successfully'
  }
}
export const DummyDataArabic = {
  dummyJobs: 'الوظائف الوهمية',
  deleteDummyData: 'حذف البيانات الوهمية',
  exploreWithDummyData: 'استكشاف المنتج باستخدام بيانات وهمية',
  getToKnowBetterWithDummyData: 'تعرف على وايت كاروت بشكل أفضل مع وظيفة وهمية، مرشحين، قوالب بريد إلكتروني، خطوط مرحلة وأسباب الأرشفة الوهمية. يمكنك أيضًا رؤية كيف تكشف تقارير الوظيفة الوهمية لوايت كاروت عن رؤى وبيانات مفيدة لإعلام عملية التوظيف الخاصة بك.',
  loadDummyData: 'تحميل البيانات الوهمية',
  preparingDummyData: 'جارٍ إعداد البيانات الوهمية',
  processing: 'جار التجهيز',
  dialog: {
    creatingDummyData: 'إنشاء وظيفة وهمية، ومرشحين، وقوالب بريد إلكتروني، وخطوط مرحلة وأسباب الأرشفة الوهمية',
    deleteDummyJobData: 'أنت على وشك حذف بيانات الوظائف الوهمية، بما في ذلك المرشحين والتقارير المتعلقة.',
    cantRetrieveDummyData: 'لا يمكنك استعادة البيانات الوهمية بعد حذفها.'
  },
  dummyData: 'البيانات الوهمية',
  message: {
    addedSuccessfully: 'تمت إضافة البيانات الوهمية بنجاح'
  }
}
