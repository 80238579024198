
import { Component, Prop, Vue } from 'vue-property-decorator'
import WcTypography from '@/components/UILibrary/Typography/WcTypography.vue'
import WcIconPencil from '@/components/UILibrary/Icons/WcIconPencil.vue'
import { InboxEntity } from '@/views/home-page/inbox/models/inbox.entity'
import InboxNotificationsResource from '@/api/inbox/inboxNotifications'
import { InboxTypeEnum } from '@/views/home-page/inbox/models/inbox-type.enum'
import WcIconCalendarCircle from '@/components/UILibrary/Icons/WcIconCalendarCircle.vue'
import WcIconDescription from '@/components/UILibrary/Icons/WcIconDescription.vue'
import WcIconNotes from '@/components/UILibrary/Icons/WcIconNotes.vue'
import { getTimeDifferenceText } from '@/utils/date'
import { APP_DOMAIN } from '@/utils/constants'
import WcIconEmail from '@/components/UILibrary/Icons/WcIconEmail.vue'
import WcIconSuitcase from '@/components/UILibrary/Icons/WcIconSuitcase.vue'
import WcIconUser from '@/components/UILibrary/Icons/WcIconUser.vue'
import WcIconBin from '@/components/UILibrary/Icons/WcIconBin.vue'
import WcIconOffer from '@/components/UILibrary/Icons/WcIconOffer.vue'
import WcIconStar from '@/components/UILibrary/Icons/WcIconStar.vue'
import { handleError } from '@/utils/request'
import WcTooltip from '@/components/UILibrary/WcTooltip.vue'
import WcIconMessage from '@/components/UILibrary/Icons/WcIconMessage.vue'
import WcDialog from '@/components/UILibrary/WcDialog.vue'
import Button from '@/components/UILibrary/Buttons/Button.vue'

@Component({
  name: 'InboxNotification',
  computed: {
    InboxTypeEnum() {
      return InboxTypeEnum
    }
  },
  components: { Button, WcDialog, WcIconMessage, WcTooltip, WcIconStar, WcIconOffer, WcIconBin, WcIconUser, WcIconSuitcase, WcIconEmail, WcIconNotes, WcIconDescription, WcIconCalendarCircle, WcIconPencil, WcTypography }
})
export default class InboxNotification extends Vue {
  @Prop({ required: true }) inboxNotification!: InboxEntity
  inboxNotificationsResource = new InboxNotificationsResource()
  baseUrl = APP_DOMAIN
  deleteNotificationLoading = false
  showDeleteNotificationDialog = false

  get isOfferAcceptedOrRejected(): boolean {
    return this.inboxNotification.type === InboxTypeEnum.OFFER_ACCEPTED || this.inboxNotification.type === InboxTypeEnum.OFFER_REJECTED
  }

  get getText(): string {
    return getTimeDifferenceText(this.inboxNotification.createdAt, new Date())
  }

  async redirectTo(): Promise<void> {
    if (!this.inboxNotification.isRead) {
      await this.inboxNotificationsResource.update(this.inboxNotification.id, { isRead: true })
    }
    if (!this.inboxNotification.isRead) {
      this.inboxNotification.isRead = true
      this.$emit('read', this.inboxNotification.isRead)
    }
    if (this.inboxNotification.jobApplicationId) {
      const candidateProfileLink = this.getRedirectionLink()
      window.open(candidateProfileLink)
    }
  }

  async deleteNotification(): Promise<void> {
    this.deleteNotificationLoading = true
    try {
      await this.inboxNotificationsResource.destroy(this.inboxNotification.id)
      this.$emit('deleted', this.inboxNotification.id)
      this.$message.success(this.t('successMessages.deleteNotification'))
    } catch (e) {
      handleError(e, this.$message)
    } finally {
      this.deleteNotificationLoading = false
    }
  }

  async handleImportant(): Promise<void> {
    try {
      this.inboxNotification.isImportant = !this.inboxNotification.isImportant
      await this.inboxNotificationsResource.update(this.inboxNotification.id, { isImportant: this.inboxNotification.isImportant })
    } catch (e) {
      handleError(e, this.$message)
    }
  }

  async markAsReadUnread(): Promise<void> {
    try {
      this.inboxNotification.isRead = !this.inboxNotification.isRead
      await this.inboxNotificationsResource.update(this.inboxNotification.id, { isRead: this.inboxNotification.isRead })
      this.$emit('read', this.inboxNotification.isRead)
    } catch (e) {
      handleError(e, this.$message)
    }
  }

  getRedirectionLink(): string {
    const url = `${this.baseUrl}/#/recruiter/candidate-profile/${this.inboxNotification.jobApplicationId}`
    if (this.inboxNotification.redirectionData.commentId) {
      return `${url}?tabId=${this.inboxNotification.redirectionData.commentId}`
    } else if (this.inboxNotification.redirectionData.bamId) {
      return `${url}?tabId=${this.inboxNotification.redirectionData.bamId}`
    } else if (this.inboxNotification.redirectionData.emailSentId) {
      return `${url}?tabId=${this.inboxNotification.redirectionData.emailSentId}`
    } else {
      return url
    }
  }

  handleDeleteNotificationDialog(): void {
    this.showDeleteNotificationDialog = !this.showDeleteNotificationDialog
  }

  get getHighlightColor(): string {
    return this.inboxNotification.isRead ? 'rgba(4, 4, 16, 0.38)' : 'rgba(4, 4, 16, 0.87)'
  }

  get getStarredText(): string {
    if (this.inboxNotification.isImportant) {
      return this.t('removeStarred')
    }
    return this.t('markAsStarred')
  }

  get getInboxReadText(): string {
    if (this.inboxNotification.isRead) {
      return this.t('markAsUnread')
    }
    return this.t('markAsRead')
  }

  t(key: string): string {
    return this.$t(`homePage.inbox.${key}`).toString()
  }
}
