
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'WcIconPlusUnfilled',
  components: {
  },
  props: {
    color: String
  }
})
export default class extends Vue {
  @Prop({ default: '#000000' }) color!: string;
}
