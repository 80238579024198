export const ExtensionPagesEnglish = {
  placeholder: 'Search for a candidate...',
  searchMatch: '{count} results found match {search}',
  noMatch: 'We couldn\'t find a match for {candidateSearchKey}. Please try another search',
  loading: 'Loading...',
  viewProfile: 'View profile'
}

export const ExtensionPagesArabic = {
  placeholder: 'البحث عن مرشح...',
  searchMatch: '{count} نتائج تم العثور عليها تطابق {search}',
  noMatch: 'لم نتمكن من العثور على مطابقة لـ {candidateSearchKey}. يرجى المحاولة بحث آخر',
  loading: 'جار التحميل...',
  viewProfile: 'عرض الملف الشخصي'
}
