export const sendClarityEvent = (eventName: string, eventDescription: string): void => {
  if (window.clarity) {
    window.clarity('set', eventName, eventDescription)
  }
}

export const sendErrorsToClarity = (errorName: string, properties: object): void => {
  if (window.clarity) {
    window.clarity('event', errorName, properties)
  }
}
