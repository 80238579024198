import { Route } from 'vue-router'
import NProgress from 'nprogress'
import { UserModule } from '@/store/modules/user'
import { hasPermission, PermissionModule } from '@/store/modules/permission'
import router from '@/router'

NProgress.configure({ showSpinner: false })

export class PermissionService {
  private static setClaritySession(to: Route) {
    if (window.location.host.includes('app.whitecarrot.io')) {
      if (UserModule.email) {
        window.clarity('identify', UserModule.email.trim(), '', to.path, UserModule.name)
        window.clarity('set', 'Email', UserModule.email.trim())
      }
      if (UserModule.companyId && UserModule.companyId.length > 0) {
        window.clarity('set', 'CompanyId', UserModule.companyId)
      }
      if (UserModule.name) {
        window.clarity('set', 'UserName', UserModule.name)
      }
      if (UserModule.companyName) {
        window.clarity('set', 'CompanyName', UserModule.companyName)
      }
      if (UserModule.access) {
        window.clarity('set', 'UserRole', UserModule.access)
      }
    }
  }

  static async checkAndSetUserInfo(): Promise<void> {
    if (UserModule.token) {
      if (!UserModule.id) {
        await UserModule.GetUserInfo()
      }
    }
  }

  static async addRoutes(): Promise<void> {
    PermissionModule.GenerateRoutesV2()

    // Add the generated routes to the router
    PermissionModule.routes.forEach(route => {
      router.addRoute(route)
    })
    // add 10 ms delay
    await new Promise(resolve => setTimeout(resolve, 10))
  }

  static async beforeEach(to: Route, from: Route, next: Function): Promise<void> {
    NProgress.start()
    await PermissionService.checkAndSetUserInfo()
    // await PermissionService.addRoutes()  TODO: review with @abi

    if (hasPermission(to)) {
      PermissionService.setClaritySession(to)
      next()
    } else if (UserModule.token) {
      next('/404')
    } else {
      next(`/login?redirect=${to.path}`)
    }
    NProgress.done()
  }

  static async afterEach(to: Route): Promise<void> {
    NProgress.done()
    document.title = to.meta?.title
  }
}
