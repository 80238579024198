
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'WcIconUserFilled',
  components: {},
  props: {
    color: {
      type: String,
      default: '#ffffff'
    }
  }
})
export default class extends Vue {
  @Prop({ default: '#ffffff' }) color!: String;;
}
