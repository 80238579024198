import Cookies from 'js-cookie'
import { isValidEmail } from '@/utils/validate'

// App
const sidebarStatusKey = 'sidebar_status'
export const getSidebarStatus = () => Cookies.get(sidebarStatusKey)
export const setSidebarStatus = (sidebarStatus: string) => Cookies.set(sidebarStatusKey, sidebarStatus)

const languageKey = 'language'
export const getLanguage = () => Cookies.get(languageKey)
export const setLanguage = (language: string) => Cookies.set(languageKey, language)

const sizeKey = 'size'
export const getSize = () => Cookies.get(sizeKey)
export const setSize = (size: string) => Cookies.set(sizeKey, size)

// User
const tokenKey = '__chi_ava_kud'
const rfTokenKey = '__chi_ava_kud_rf_token'
export const getToken = () => Cookies.get(tokenKey)
export const setToken = (token: string) => Cookies.set(tokenKey, token)
export const removeToken = () => Cookies.remove(tokenKey)

export const getRefreshToken = () => Cookies.get(rfTokenKey)
export const setRefreshToken = (token: string) => Cookies.set(rfTokenKey, token)
export const removeRefreshToken = () => Cookies.remove(rfTokenKey)

// Superset Guest Session Token
const supersetGuestSessionKey = '__chi_ava_kud__superset_guest_session'
export const getSupersetGuestSession = () => Cookies.get(supersetGuestSessionKey)
export const setSupersetGuestSession = (token: string) => Cookies.set(supersetGuestSessionKey, token)
export const removeSupersetGuestSession = () => Cookies.remove(supersetGuestSessionKey)

const supersetTokenGeneratedAtKey = '__chi_ava_kud__superset_token_generated_at'
export const getSupersetTokenGeneratedAt = () => Cookies.get(supersetTokenGeneratedAtKey)
export const setSupersetTokenGeneratedAt = (generatedAt: string) => Cookies.set(supersetTokenGeneratedAtKey, generatedAt)
export const removeSupersetTokenGeneratedAt = () => Cookies.remove(supersetTokenGeneratedAtKey)

const supersetDashboardsKey = '__chi_ava_kud__superset_dashboards'
export const getSupersetDashboards = () => Cookies.get(supersetDashboardsKey)
export const setSupersetDashboards = (dashboards: string) => Cookies.set(supersetDashboardsKey, dashboards)
export const removeSupersetDashboards = () => Cookies.remove(supersetDashboardsKey)

const rolesKey = '__chi_ava_kud__'
export const getRoles = () => Cookies.get(rolesKey)
export const setRoles = (token: string) => Cookies.set(rolesKey, token)
export const removeRoles = () => Cookies.remove(rolesKey)

const permissionsKey = '__chi_ava_kud__y'
const permissionsKeyV1 = '__chi_ava_kud__y_v1'
export const getPermissions = () => Cookies.get(permissionsKey)

export const getPermissionsV1 = () => Cookies.get(permissionsKeyV1)
export const setPermissions = (token: string) => Cookies.set(permissionsKey, token)
export const removePermissions = () => Cookies.remove(permissionsKey)

const companyKey = '___chi_ava_kud___'
export const getCompanyId = () => Cookies.get(companyKey)
export const setCompanyId = (token: string) => Cookies.set(companyKey, token)
export const removeCompanyId = () => Cookies.remove(companyKey)

const versionKey = '___chi__kkj_ava_kud___'
export const getVersion = () => Cookies.get(versionKey)
export const setVersion = (version: string) => Cookies.set(versionKey, version)

const careerNameKey = '___chi_ava_kud___career_name'
export const getCareerName = () => Cookies.get(careerNameKey)
export const setCareerName = (name: string) => Cookies.set(careerNameKey, name)
export const removeCareerName = () => Cookies.remove(careerNameKey)

const trailPeriodEndedKey = '___chi_ava_kud___trail_period_ended'
export const getTrailPeriodEnded = () => Cookies.get(trailPeriodEndedKey) !== undefined ? Cookies.get(trailPeriodEndedKey) === 'true' : false
export const setTrailPeriodEnded = (value: string) => Cookies.set(trailPeriodEndedKey, value)
export const removeTrailPeriodEnded = () => Cookies.remove(trailPeriodEndedKey)

export const getImpersonatedMode = () => Cookies.get('___chi_ava_kud___impersonated_mode')
export const setImpersonatedMode = (value: string) => Cookies.set('___chi_ava_kud___impersonated_mode', value)

export const removeImpersonatedMode = () => Cookies.remove('___chi_ava_kud___impersonated_mode')

export const getSessionEndsIn = () => Cookies.get('___chi_ava_kud___session_ends_in')
export const setSessionEndsIn = (value: string) => Cookies.set('___chi_ava_kud___session_ends_in', value)

export const removeSessionEndsIn = () => Cookies.remove('___chi_ava_kud___session_ends_in')

export const validateEmail = (rule: any, value: string, callback: Function) => {
  if (!isValidEmail(value)) {
    callback(new Error('Please enter the valid email'))
  } else {
    callback()
  }
}

export const validatePassword = (rule: any, value: string, callback: Function) => {
  if (value.length < 6) {
    callback(new Error('The password can not be less than 6 digits'))
  } else {
    callback()
  }
}

// access token
export const getTokenFromLocal = () => localStorage.getItem(tokenKey)
export const setTokenToLocal = (token: string) => localStorage.setItem(tokenKey, token)
export const removeTokenFromLocal = () => localStorage.removeItem(tokenKey)
// refresh token
export const getRefreshTokenFromLocal = () => localStorage.getItem(rfTokenKey)
export const setRefreshTokenToLocal = (token: string) => localStorage.setItem(rfTokenKey, token)
export const removeRefreshTokenFromLocal = () => localStorage.removeItem(rfTokenKey)

export const getRolesFromLocal = () => localStorage.getItem(rolesKey)
export const setRolesToLocal = (token: string) => localStorage.setItem(rolesKey, token)
export const removeRolesFromLocal = () => localStorage.removeItem(rolesKey)

export const getPermissionsFromLocal = () => localStorage.getItem(permissionsKey)
export const setPermissionsToLocal = (token: string) => localStorage.setItem(permissionsKey, token)
export const removePermissionsFromLocal = () => localStorage.removeItem(permissionsKey)

export const getCompanyIdFromLocal = () => localStorage.getItem(companyKey)
export const setCompanyIdToLocal = (token: string) => localStorage.setItem(companyKey, token)
export const removeCompanyIdFromLocal = () => localStorage.removeItem(companyKey)

export const getCareerNameFromLocal = () => localStorage.getItem(careerNameKey)
export const setCareerNameToLocal = (name: string) => localStorage.setItem(careerNameKey, name)
export const removeCareerNameFromLocal = () => localStorage.removeItem(careerNameKey)
