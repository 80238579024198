export const InterviewAvailabilityEnglish = {
  title: 'Interviews availability for candidate self schedule ',
  subTitle: 'Manage your interview available time slots for candidates to self-schedule their interviews.',
  setWorkingDaysAndHours: 'Set your working days and hours',
  defaultSettings: 'Default settings',
  candidatesScheduleAtLeast: 'Candidates should schedule at-least',
  beforeTheInterview: 'before the interview',
  afterTheInterview: 'after the interview',
  addBufferTime: 'Add buffer time',
  interviewDateShouldBeOnOrBefore: 'Limit {candidate} to book interview on or before',
  restrictToOnly: 'Restrict to only',
  interviews: 'interview(s)',
  cannotChangeInterviewer: 'you cannot change the interviewer in the edit mode',
  fromTheInvite: 'from the invite sent date'
}
export const InterviewAvailabilityArabic = {
  title: 'المقابلات الخاصة بك للمرشحين',
  subTitle: 'إدارة أوقات توفر المقابلات الخاصة بك للسماح للمرشحين بجدولة المقابلات بأنفسهم.',
  setWorkingDaysAndHours: 'حدد أيام وساعات عملك',
  defaultSettings: 'الإعدادات الافتراضية',
  candidatesScheduleAtLeast: 'يجب على المرشحين تحديد موعد المقابلة على الأقل',
  beforeTheInterview: 'قبل المقابلة',
  afterTheInterview: 'بعد المقابلة',
  addBufferTime: 'إضافة وقت استراحة',
  interviewDateShouldBeOnOrBefore: 'يجب أن يكون تاريخ المقابلة في أو قبل',
  restrictToOnly: 'يجب ان يكون',
  interviews: 'مقابلة (مقابلات)',
  cannotChangeInterviewer: 'لا يمكنك تغيير المقابل في وضع التحرير',
  fromTheInvite: 'من تاريخ إرسال الدعوة'
}
