export const AppIntegrationsArabic = {
  calendarScopesCheck: {
    reIntegrateCalendar: 'لم تمنح كل الأذونات المطلوبة لجدولة مقابلة. رجاءً أعد دمج تقويمك واسمح بكل الصلاحيات.',
    missingScopes: 'أذونات مفقودة',
    notAllowedToCreateDeleteUpdateEvents: 'لم تمنح الإذن بإنشاء أو حذف أو تعديل مقابلات في تقويمك.',
    notAllowedToCreateConferencingEvents: 'لم تمنح الإذن بإنشاء مقابلات فيديو في تقويمك.',
    notAllowedToReadEvents: 'لم تمنح الإذن بعرض المقابلات في تقويمك.',
    notAllowedToSeeFreeBusyEvents: 'لم تمنح الإذن برؤية الوقت المتاح أو المشغول بالمقابلات في تقويمك.'
  },
  removeIntegrationDialog: {
    title: 'هل ترغب في إزالة التكامل لأعضاء شركتك؟',
    body: 'لا يمكن التراجع عن هذا الإجراء. هل أنت متأكد؟'
  },
  errorMessage: {
    removeIntegration: 'تم إزالة التكامل'
  },
  whichServiceIsUsed: 'ما هي خدمة التقويم والبريد الإلكتروني التي تستخدمها {company}؟',
  save: 'حفظ'
}

export const AppIntegrationsEnglish = {
  calendarScopesCheck: {
    reIntegrateCalendar: 'You haven\'t allowed all required permissions to schedule an interview. Please re-integrate your calendar with all permissions.',
    missingScopes: 'Missing scopes',
    notAllowedToCreateDeleteUpdateEvents: 'You have not allowed to create, delete and update events in your calendar.',
    notAllowedToCreateConferencingEvents: 'You have not allowed to create video conferencing events in your calendar.',
    notAllowedToReadEvents: 'You have not allowed to read events in your calendar.',
    notAllowedToSeeFreeBusyEvents: 'You have not allowed to see free busy events in your calendar.'
  },
  mailScopesCheck: {
    reIntegrateMail: 'You haven\'t allowed all required permissions to manage your emails. Please re-integrate your email with all permissions.',
    notAllowedToReadMail: 'You have not allowed access to read emails in your mailbox.',
    notAllowedToReadMailBasic: 'You have not allowed access to read basic email properties.',
    notAllowedToReadMailBasicShared: 'You have not allowed access to read basic properties of emails in shared mailboxes.',
    notAllowedToReadWriteMail: 'You have not allowed to create, modify, or delete emails in your mailbox.',
    notAllowedToSendMail: 'You have not allowed sending emails on your behalf.',
    notAllowedToReadUserProfile: 'You have not allowed access to read your user profile.',
    notAllowedToReadUserEmail: 'You have not allowed access to read emails in your Gmail account.'
  },
  removeIntegrationDialog: {
    title: 'Do you want to remove integration of members in your company?',
    body: 'This action cannot be undone. Are you sure?'
  },
  whichServiceIsUsed: 'Which calendar and email service is used by {company}?',
  save: 'Save',
  errorMessage: {
    removeIntegration: 'Removed integration'
  }
}
