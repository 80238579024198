import { render, staticRenderFns } from "./WcATag.vue?vue&type=template&id=d2ee502e&scoped=true"
import script from "./WcATag.vue?vue&type=script&lang=ts"
export * from "./WcATag.vue?vue&type=script&lang=ts"
import style0 from "./WcATag.vue?vue&type=style&index=0&id=d2ee502e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d2ee502e",
  null
  
)

export default component.exports