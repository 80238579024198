
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'WcIconPencil',
  computed: {
    handleSize() {
      switch (this.$props.size) {
        case 'xl': return 28
        case 'lg': return 20
        case 'md': return 16
        case 'sm': return 14
        case 'xs': return 11
        default: return 16
      }
    }
  }
})
export default class extends Vue {
  @Prop({ default: '#ffffff' }) color!: String;
  @Prop({ default: 'md' }) private size!: String
  @Prop({ default: false }) private outlined!: Boolean
}
