import BaseInput from '@/components/Inputs/BaseInput.vue'
import BaseCard from '@/components/Cards/BaseCard.vue'
import BaseButton from '@/components/BaseButton.vue'
import BaseCheckbox from '@/components/Inputs/BaseCheckbox.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import LinkButton from '@/components/LinkButton.vue'
import { Input, Tooltip, Popover } from 'element-ui'
/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */

const GlobalComponents = {
  install(Vue) {
    Vue.component(BaseButton.name, BaseButton)
    Vue.component(BaseCheckbox.name, BaseCheckbox)
    Vue.component(BaseInput.name, BaseInput)
    Vue.component(BaseCard.name, BaseCard)
    Vue.component(Input.name, Input)
    Vue.component('ValidationProvider', ValidationProvider)
    Vue.component('ValidationObserver', ValidationObserver)
    Vue.component(LinkButton.name, LinkButton)
    Vue.use(Tooltip)
    Vue.use(Popover)
  }
}

export default GlobalComponents
