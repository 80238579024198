import { render, staticRenderFns } from "./AppMain.vue?vue&type=template&id=eb2cd790&scoped=true"
import script from "./AppMain.vue?vue&type=script&lang=ts"
export * from "./AppMain.vue?vue&type=script&lang=ts"
import style0 from "./AppMain.vue?vue&type=style&index=0&id=eb2cd790&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eb2cd790",
  null
  
)

export default component.exports