
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'WcIconFilter',
  components: {}
})
export default class extends Vue {
  @Prop({ default: '#040410' }) private color!: string
  @Prop({ default: 24 }) private size!: number | string
}
