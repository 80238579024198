import Resource from '@/api/resources/base-resource'
import request from '@/utils/request'
import { INewCompany } from '@/views/dashboard/dashboard-model'

class CompanyResource extends Resource {
  constructor() {
    super('company')
  }

  getAllCompanyNames() {
    return request({
      url: '/' + this.uri,
      method: 'get'
    })
  }

  createCompany(data: INewCompany) {
    return request({
      url: '/' + this.uri + '/createCompany',
      method: 'post',
      data
    })
  }

  getAtsDetails() {
    return request({
      url: '/' + this.uri + '/get-ats-settings',
      method: 'get'
    })
  }

  updateOrCreateAtsDetails(data) {
    return request({
      url: '/' + this.uri + '/update-or-create-ats-key',
      method: 'patch',
      data
    })
  }

  removeATSConnection(companyId: string) {
    return request({
      url: '/' + this.uri + '/' + companyId + '/remove-ats-connection',
      method: 'patch'
    })
  }

  updateOrCreateAtsOwner(data: { ownerId: string }) {
    return request({
      url: '/' + this.uri + '/update-or-create-ats-owner',
      method: 'patch',
      data
    })
  }

  getOwnersOnAts() {
    return request({
      url: '/' + this.uri + '/get-owners-on-ats',
      method: 'get'
    })
  }

  testAtsConnection() {
    return request({
      url: '/' + this.uri + '/test-ats-connection',
      method: 'get'
    })
  }

  getAtsConnectionStatus() {
    return request({
      url: '/' + this.uri + '/get-ats-connection-status',
      method: 'get'
    })
  }

  getCompany(id: string) {
    return request({
      url: '/' + this.uri + '/' + id,
      method: 'get'
    })
  }

  addDummyData(id: string) {
    return request({
      url: '/' + this.uri + `/${id}/dummy`,
      method: 'get'
    })
  }
}

export { CompanyResource as default }
