
import { Component, Vue } from 'vue-property-decorator'
import { UserModule } from '@/store/modules/user'
import { WhiteLabelPartnerEnum } from '@/views/dashboard/dashboard-model'
import CompanyWcLogo from '@/views/logos/company-wc-logo.vue'
import LanguageSwitcher from '@/components/UILibrary/LanguageSwitcher.vue'

@Component({
  name: 'PluginLayout',
  components: { LanguageSwitcher, CompanyWcLogo },
  computed: {
    WhiteLabelPartnerEnum() {
      return WhiteLabelPartnerEnum
    }
  },
  methods: {
    UserModule() {
      return UserModule
    }
  }
})
export default class extends Vue {
  closeMe() {
    const iframe = document.getElementsByClassName('lever-hire-frame')[0]
    if (iframe) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      iframe.contentWindow.postMessage({ type: 'togglePanel' }, '*')
    }
    try {
      window.postMessage({ type: 'togglePanel' }, '*')
    } catch (e) {
    }

    try {
      parent.postMessage({ type: 'togglePanel' }, '*')
    } catch (e) {
    }
    try {
      window.parent.postMessage({ action: 'wc-closeIframe' }, '*')
    } catch (e) {
    }
  }

  get companyPartnerText() {
    if (UserModule.whiteLabelPartner === WhiteLabelPartnerEnum.BAYZAT) {
      return 'Bayzat ATS'
    } else {
      return 'Whitecarrot ATS'
    }
  }

  get companyPartner() {
    return UserModule.whiteLabelPartner as WhiteLabelPartnerEnum
  }
}
