import Vue from 'vue'
import Vuex from 'vuex'
import { IAppState } from './modules/app'
import { IUserState } from './modules/user'
import { IPermissionState } from '@/store/modules/permission'
import { ICandidateJourneyState } from '@/store/modules/candidate-journey'
import { ISocket } from '@/store/modules/socket'
import { IOffersState } from '@/store/modules/offers'
import { IReportsState } from '@/store/modules/reports'
import { ICandidateListQueryState } from '@/store/modules/candidate-list.query'
import { JobsListQuery } from '@/views/jobs-list/models'

Vue.use(Vuex)

export interface IRootState {
  app: IAppState
  user: IUserState
  permission: IPermissionState
  candidateJourney: ICandidateJourneyState
  socket: ISocket
  offers: IOffersState
  reports: IReportsState
  candidateListQuery: ICandidateListQueryState
  jobsListQuery: JobsListQuery
}

// Declare empty store first, dynamically register all modules later.
export default new Vuex.Store<IRootState>({})
