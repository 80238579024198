
import { Component, Prop, Vue } from 'vue-property-decorator'
import WcIconHamburger from '@/components/UILibrary/Icons/WcIconHamburger.vue'

@Component({
  name: 'Hamburger',
  components: { WcIconHamburger }
})
export default class extends Vue {
  @Prop({ default: true }) private isActive!: boolean

  private toggleClick() {
    this.$emit('toggle-click')
  }
}
