export enum InboxTypeEnum {
  MENTIONS = 'MENTIONS',
  NOTES = 'NOTES',
  INTERVIEWS = 'INTERVIEWS',
  INTERVIEW_SCORES = 'SCORES',
  CANDIDATE_OWNER_ASSIGNED = 'CANDIDATE_OWNER_ASSIGNED',
  JOB_OWNER_COLLABORATOR_ASSIGNED_REMOVED = 'JOB_OWNER_COLLABORATOR_ASSIGNED_REMOVED',
  CANDIDATE_UNDER_REVIEW = 'CANDIDATE_UNDER_REVIEW',
  STAGE_CHANGED = 'STAGE_CHANGED',
  CANDIDATE_ARCHIVED = 'CANDIDATE_ARCHIVED',
  CANDIDATE_UNARCHIVED = 'CANDIDATE_UNARCHIVED',
  EMAIL_SENT = 'EMAIL_SENT',
  REPLY_FROM_CANDIDATE = 'REPLY_FROM_CANDIDATE',
  OFFER_SENT = 'OFFER_SENT',
  OFFER_ACCEPTED = 'OFFER_ACCEPTED',
  OFFER_REJECTED = 'OFFER_REJECTED',
}

export enum InboxActionsEnum {
  MARK_ALL_READ = 'MARK_ALL_READ',
  MARL_ALL_UNREAD = 'MARL_ALL_UNREAD',
  DELETE_ALL_NOTIFICATIONS = 'DELETE_ALL_NOTIFICATIONS',
  DELETE_ALL_READ_NOTIFICATIONS = 'DELETE_ALL_READ_NOTIFICATIONS'
}

export enum InboxFilterEnum {
  ALL = 'ALL',
  UNREAD = 'UNREAD',
  STARRED = 'STARRED'
}
