export const CalendarIntegrationPageEnglish = {
  title: 'Calendar and email integration',
  subTitle: 'Manage your calendar and email integrations to streamline your scheduling and communication processes.',
  tooltipText: 'Click here to learn more about calendar and email integrations',
  connectCalendar: 'Connect your calendar',
  connectCalendarDesc: 'Integrate your calendar to schedule interviews or ask candidates to book the slot.',
  googleCalendar: 'Google calendar',
  calendarSubText: 'Google calendar allows user to create and edit events. | Allowed to create and edit events on',
  integrated: 'Integrated',
  removeApp: 'Remove app',
  emailsTitle: 'Sync emails',
  emailsDesc: 'Integrate your email to send emails from your email address and sync candidates replies.',
  gmail: 'Gmail',
  emailsSubText: 'Gmail keeps your account & emails encrypted and under your control. | Allowed to send emails from',
  dialog: {
    gmail: 'Are you sure to remove Gmail integration?',
    remove: 'Remove integration',
    calendar: 'Are you sure to remove Google calendar integration?'
  },
  messages: {
    removed: 'Successfully removed integration',
    error: 'Something went wrong. Pls try again later'
  },
  outlookCalendar: 'Outlook calendar',
  outlookCalendarDesc: 'Schedule interviews and avoid double bookings when asking candidates to book',
  outlook: 'Outlook',
  outlookMailDesc: 'Outlook keeps your account & emails encrypted, private and under your control.',
  confirmRemove: 'Are you sure to remove {title} integration?',
  signInOutlook: 'Sign in using {title}'
}
export const CalendarIntegrationPageArabic = {
  title: 'التكامل مع التقويم والبريد الإلكتروني',
  subTitle: 'قم بإدارة تكامل التقويم والبريد الإلكتروني لتبسيط عمليات جدولة المواعيد والاتصالات الخاصة بك.',
  tooltipText: 'انقر هنا للتعرف على المزيد حول تكامل التقويم والبريد الإلكتروني',
  connectCalendar: 'قم بربط تقويمك',
  connectCalendarDesc: 'قم بتكامل التقويم الخاص بك لجدولة المقابلات أو طلب من المرشحين حجز المكان.',
  googleCalendar: 'تقويم Google',
  calendarSubText: 'مسموح بإنشاء الأحداث وتعديلها | تتيح تقنية Google Calendar للمستخدمين إنشاء وتحرير الأحداث.',
  integrated: 'متكامل',
  removeApp: 'إزالة التطبيق',
  emailsTitle: 'مزامنة البريد الإلكتروني',
  emailsDesc: 'قم بتكامل بريدك الإلكتروني لإرسال الرسائل البريدية من عنوان بريدك الإلكتروني ومزامنة ردود المرشحين.',
  gmail: 'Gmail',
  emailsSubText: 'مسموح بإرسال رسائل البريد الإلكتروني من | يحافظ Gmail على حسابك ورسائلك مشفرة وتحت سيطرتك.',
  dialog: {
    gmail: 'هل أنت متأكد من إزالة تكامل Gmail؟',
    remove: 'إزالة التكامل',
    calendar: 'هل أنت متأكد من إزالة تكامل تقويم Google؟'
  },
  messages: {
    removed: 'تم إزالة التكامل بنجاح',
    error: 'حدث خطأ ما. يرجى المحاولة مرة أخرى في وقت لاحق'
  },
  outlookCalendar: 'تقويم Outlook',
  outlookCalendarDesc: 'جدولة المقابلات وتجنب الحجوزات المزدوجة عند طلب المرشحين بالحجز',
  outlook: 'Outlook',
  outlookMailDesc: 'يحافظ Outlook على حسابك ورسائل البريد الإلكتروني مشفرة وخاصة وتحت سيطرتك.',
  confirmRemove: 'هل أنت متأكد من إزالة تكامل {title}؟',
  signInOutlook: 'تسجيل الدخول باستخدام {title}'
}
