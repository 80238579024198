export const InterviewEnglish = {
  tipsForRecording: 'Top tips for recording your video responses.',
  instructions: {
    grantAccessToCameraMicrophone: {
      sideHeading: 'Allow Access to Camera & Microphone',
      instruction: 'When prompted, click "Allow" to enable access.'
    },
    quietEnvironment: {
      sideHeading: 'Choose a Quiet Environment',
      instruction: 'Find a distraction-free space for recording.'
    },
    checkYourInternet: {
      sideHeading: 'Check Your Internet',
      instruction: 'Make sure your device has a strong and stable internet connection.'
    },
    practiceFirst: {
      sideHeading: 'Practice First',
      instruction: 'Do a few practice recordings to get comfortable.'
    }
  },
  navigation: {
    tryItOut: 'Try it out',
    getStarted: 'Get started',
    backToInstructions: 'Go back to instructions',
    continue: 'Continue'
  },
  completeToSubmit: 'Complete the video interview to submit your application.',
  receivedPartially: 'Your application for {roleName} is received partially. Complete the video interview to submit your application.',
  ready: 'Ready',
  recording: 'Recording',
  stopRecording: 'Stop recording',
  saveAndContinue: 'Save & continue',
  almostDone: 'Almost done',
  reviewCarefully: 'Please review your answers carefully and ensure that you are happy with them.',
  reTake: 'Re-take',
  confirmSubmission: 'Confirm submission',
  submissionReceived: 'Your submission is received.',
  applicationReceived: 'Your application for {roleName} is received.',
  setARemainder: 'Set a reminder',
  startRecording: 'Start recording',
  reRecord: 'Re-record',
  errorMessage: {
    responseSaved: 'Your response is saved successfully.',
    unableToSave: 'Unable to save your response. Please record your answer again.',
    interviewCompleted: 'Your video interview is completed.',
    failedToSubmit: 'Failed to submit. Please try again.',
    stopTheRecordBeforeLeave: 'Please stop the recording before you leave this page.',
    unableToSaveResponse: 'Unable to save your response. Please try again.',
    urlCopied: 'URL copied to clipboard successfully!',
    failedToCopy: 'Failed to copy the URL to clipboard.'
  },
  permission: {
    cameraAndMicrophone: 'Allow camera and microphone',
    camera: 'Allow camera',
    microphone: 'Allow microphone'
  },
  copyLinkInstructions: 'Please copy the link and open in mobile browser (e.g., Chrome, Safari).',
  copyUrl: 'Copy URL',
  videoRecordingNotSupported: 'Video recording not supported in this {browserName} browser.',
  openInExternalBrowser: 'Open in external browser',
  savingStatusText: 'Great job so far! We are saving your response for this question, which may take up to 5 minutes. Please stay on this page and avoid refreshing to ensure everything is processed smoothly. Thank you for your patience!'
}
export const InterviewArabic = {
  tipsForRecording: 'أفضل النصائح لتسجيل إجاباتك بالفيديو',
  instructions: {
    grantAccessToCameraMicrophone: {
      sideHeading: 'السماح بالوصول إلى الكاميرا والميكروفون',
      instruction: 'عند ظهور المطالبة، انقر على "السماح" لتمكين الوصول.'
    },
    quietEnvironment: {
      sideHeading: 'اختر بيئة هادئة',
      instruction: 'ابحث عن مكان خالٍ من التشويش للتسجيل.'
    },
    checkYourInternet: {
      sideHeading: 'تحقق من اتصال الإنترنت',
      instruction: 'تأكد من أن جهازك متصل باتصال إنترنت قوي ومستقر.'
    },
    practiceFirst: {
      sideHeading: 'قم بالتدرب أولاً',
      instruction: 'قم ببعض التسجيلات التجريبية لتشعر بالراحة.'
    }
  },
  navigation: {
    tryItOut: 'جرب ذلك',
    getStarted: 'البدء',
    backToInstructions: 'العودة إلى التعليمات',
    continue: 'متابعة'
  },
  completeToSubmit: 'أكمل مقابلة الفيديو لتقديم طلبك',
  receivedPartially: 'تم استلام طلبك للمنصب {roleName} جزئيًا. أكمل مقابلة الفيديو لتقديم طلبك.',
  ready: 'جاهز',
  recording: 'جار التسجيل',
  stopRecording: 'إيقاف التسجيل',
  saveAndContinue: 'حفظ ومتابعة',
  almostDone: 'تقريبًا انتهى.',
  reviewCarefully: 'يرجى مراجعة إجاباتك بعناية والتأكد من رضاك عنها.',
  reTake: 'إعادة التسجيل',
  confirmSubmission: 'تأكيد التقديم',
  submissionReceived: 'تم استلام تقديمك.',
  applicationReceived: 'تم استلام طلبك للمنصب {roleName}',
  setARemainder: 'تعيين تذكير',
  startRecording: 'بدء التسجيل',
  reRecord: 'إعادة التسجيل',
  errorMessage: {
    responseSaved: 'تم حفظ إجابتك بنجاح.',
    unableToSave: 'تعذر حفظ إجابتك. يرجى تسجيل إجابتك مرة أخرى.',
    interviewCompleted: 'اكتملت مقابلتك بالفيديو.',
    failedToSubmit: 'فشل في التقديم. يرجى المحاولة مرة أخرى.'
  },
  permission: {
    cameraAndMicrophone: 'السماح للكاميرا والميكروفون',
    camera: 'السماح للكاميرا',
    microphone: 'السماح للميكروفون'
  },
  copyLinkInstructions: 'يرجى نسخ الرابط وفتحه في متصفح الهاتف المحمول. (مثل Chrome وSafari)',
  copyUrl: 'نسخ عنوان URL',
  videoRecordingNotSupported: 'تسجيل الفيديو غير مدعوم في هذا المتصفح',
  openInExternalBrowser: 'فتح في متصفح خارجي',
  savingStatusText: 'عمل عظيم حتى الآن! نقوم حاليًا بحفظ إجابتك على هذا السؤال، والتي قد تستغرق ما يصل إلى 5 دقائق. يرجى البقاء على هذه الصفحة وتجنب التحديث لضمان معالجة كل شيء بسلاسة. شكرا لك على صبركم!'
}
