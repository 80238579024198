import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { Route, RouteConfig } from 'vue-router'
import store from '@/store'
import { UserModule } from '@/store/modules/user'
import { RolesEnum } from '@/views/recruiter/models/roles-model'
import { WILDCARD_ROUTE } from '@/routes/wild-card.routes'
import { ADMIN_ROUTES } from '@/routes/admin-routes'
import { RECRUITER_ROUTES } from '@/routes/recruiter-routes'

export const hasPermission = (route: RouteConfig | Route): boolean => {
  const userNewPermissions = UserModule.permissionV1
  const userRoles = UserModule.roles
  if (route.meta) {
    let hasPermission = true
    let hasRole = true
    if (route.meta.roles || route.meta.permissions) {
      hasPermission = true
      hasRole = true
      if (route.meta.permissions) {
        hasPermission = false
        hasPermission = hasPermission || route.meta.permissions.some((permission: string) => checkPermissionMatching(userNewPermissions, permission))
      }
      if (route.meta.roles) {
        // TODO-permissions resolve after v1
        hasRole = true
        hasRole = route.meta.roles.some((role: string) => userRoles.includes(role))
      }
    }
    return hasPermission && hasRole
  } else {
    return true
  }
}

/*
     Each permission will look like COMPANY::<RESOURCE>::<SUB_RESOURCE>::ACTION

     ACTION includes  ( * , EDIT , DELETE , ADD , LIST , CLOSE , PUBLISH )

     At Component level we write complete permission without having any action like (*)
     But for company Admin we have COMPANY::JOBS::*
     for Limited access we have COMPANY::JOBS::EDIT
     To make sure company admin have accesss on given permission COMPANY::<RESOURCE>::<SUB_RESOURCE>::ACTION ,
     we also add wild card permission ( COMPANY::<RESOURCE>::<SUB_RESOURCE>::* )
 */
export const getFormattedPermissions = function(permission: string): string[] {
  // Split permission into parts based on '::'
  const permissionParts = permission.split('::')

  // If permission itself have * action return permission
  if (permissionParts[permissionParts.length - 1] === '*') {
    return [permission]
  }

  // Generate wildcard for the last part (action)
  const wildcardPermission = `${permissionParts
    .slice(0, -1)
    .join('::')}::*`

  return [wildcardPermission, permission]
}

/** Helper function matching for regex permission
 * Returns boolean if requiredPermission is regex matching with given permission
 * permissionMatches('COMPANY::JOBS::EDIT', 'COMPANY::JOBS::EDIT') // true
 * permissionMatches('COMPANY::JOBS::*', 'COMPANY::JOBS::EDIT')   // true
 * permissionMatches('COMPANY::*', 'COMPANY::JOBS::EDIT')  // true
 * permissionMatches('COMPANY::USERS::*', 'COMPANY::JOBS::EDIT') // false
 * **/
export const regexMatch = function(permission: string, requiredPermission: string): boolean {
  const permissionParts = permission.split('::')
  const requiredPermissionParts = requiredPermission.split('::')
  const minLength = Math.min(
    permissionParts.length,
    requiredPermissionParts.length
  )

  for (let i = 0; i < minLength; i++) {
    if (permissionParts[i] === '*') {
      return true
    }

    if (permissionParts[i] !== requiredPermissionParts[i]) {
      return false
    }
  }

  // If all parts are equal, permission1 is not less than permission2
  // Unhandled test cases
  return permissionParts.length <= requiredPermissionParts.length
}

/** Checks if givePermission is regex matching with any one of the permissions
 * Returns boolean if givePermission is regex matching with any one of the permissions
 * **/
export const checkPermissionMatching = function(
  permissions: string[],
  givenPermission: string
): boolean {
  return permissions.some((permission) =>
    // permissionMatches('COMPANY::JOBS::*', 'COMPANY::JOBS::EDIT')); => true
    regexMatch(permission, givenPermission)
  )
}

export const filterRoutes = (routes: RouteConfig[]): RouteConfig[] => {
  const res: RouteConfig[] = []
  routes.forEach(route => {
    const r = { ...route }
    if (hasPermission(r)) {
      if (r.children) {
        r.children = filterRoutes(r.children)
      }
      res.push(r)
    }
  })
  return res
}

export const checkRole = (role: RolesEnum): boolean => {
  return UserModule.roles.includes(role)
}

export interface IPermissionState {
  routes: RouteConfig[]
}

@Module({ dynamic: true, store, name: 'permission', namespaced: true })
class Permission extends VuexModule implements IPermissionState {
  public routes: RouteConfig[] = []

  @Mutation
  private SET_ROUTES(routes: RouteConfig[]) {
    this.routes = [...filterRoutes(routes), ...WILDCARD_ROUTE]
  }

  @Action
  public GenerateRoutes() {
    if (checkRole(RolesEnum.WC_ADMIN)) {
      this.SET_ROUTES(ADMIN_ROUTES)
    } else if (checkRole(RolesEnum.COMPANY_ADMIN) || checkRole(RolesEnum.COMPANY_INTERVIEWER) || checkRole(RolesEnum.COMPANY_EMPLOYEE)) {
      this.SET_ROUTES(RECRUITER_ROUTES)
    }
  }

  @Action
  public GenerateRoutesV2() {
    this.SET_ROUTES(RECRUITER_ROUTES)
  }
}

export const PermissionModule = getModule(Permission)
