
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'WcIconNotes'
})
export default class extends Vue {
  @Prop({ default: '#040410' }) private color!: String
  @Prop({ default: '20' }) height!: String;
  @Prop({ default: '20' }) width!: String;
}
