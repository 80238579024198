import { JobRoleAccessTypeEnum } from '@/views/recruiter/job-role/models/enums'

export interface IRolesEntity {
  id: string
  name: string
  roleLevel: number
  isSystem: boolean
  updatedAt: string
  createdAt: string
}

export const RolesDisplayTitle = {
  COMPANY_INTERVIEWER: 'Limited access',
  COMPANY_ADMIN: 'Full access',
  COMPANY_EMPLOYEE: 'Employee'
}

export const RolesToAccessType = {
  COMPANY_INTERVIEWER: JobRoleAccessTypeEnum.LIMITED_ACCESS,
  COMPANY_ADMIN: JobRoleAccessTypeEnum.FULL_ACCESS,
  COMPANY_EMPLOYEE: JobRoleAccessTypeEnum.EMPLOYEE
}

export enum RolesEnum {
  WC_SUPER_ADMIN = 'WC_SUPER_ADMIN',
  WC_ADMIN = 'WC_ADMIN',
  WC_USER = 'WC_USER',
  COMPANY_ADMIN = 'COMPANY_ADMIN',
  COMPANY_INTERVIEWER = 'COMPANY_INTERVIEWER',
  COMPANY_EMPLOYEE = 'COMPANY_EMPLOYEE',
  CANDIDATE = 'CANDIDATE',
  AGENCY_USER = 'AGENCY_USER',
}

export enum CompanyRoleStatusEnum {
  PUBLISHED = 'PUBLISHED',
  DRAFT = 'DRAFT',
  CLOSED = 'CLOSED',
  DELETED = 'DELETED'
}
