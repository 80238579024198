export const InterviewSchedulingArabic = {
  schedule: 'الجدولة',
  addOrganizer: 'إضافة منظم',
  addInterviewer: 'إضافة مقابل',
  addTeamMember: 'إضافة عضو جديد في الفريق',
  addTitle: 'إضافة العنوان',
  addLocation: 'إضافة الموقع',
  refreshUsers: 'تحديث قائمة المستخدمين',
  organizer: '- المنظم',
  userNotIntegrated: 'لم تقم بدمج التقويم الخاص بك. يرجى دمج التقويم الخاص بك | لم يقم هذا المستخدم بدمج التقويم. يرجى أن تطلب منهم دمج التقويم الخاص بهم مع نظام تتبع المتقدمين.',
  notAbleToFetchCalendarEvents: 'لا يمكننا الوصول إلى {name} ({email})، يرجى الاتصال بمدير النظام لتمكين مشاركة التقويم',
  userNotAllowedToReadEvents: 'لم يسمح للمستخدم بقراءة الأحداث في تقويمه',
  userNotAllowedToCreateDeleteUpdateEvents: 'لم يسمح للمستخدم بإنشاء، حذف، أو تحديث الأحداث في تقويمه.',
  selectTime: 'تحديد الوقت على التقويم',
  eventLocation: 'تحديد موقع الحدث',
  googleMeetLink: 'سنقوم بإنشاء رابط Google Meet لهذا الحدث.',
  microsoftTeamsLink: 'سنقوم بإنشاء رابط Microsoft Teams لهذا الحدث.',
  addDescription: 'إضافة وصف',
  withIntegration: 'الرجاء ملء جميع الحقول المطلوبة',
  withoutIntegration: 'الرجاء إزالة المستخدمين الذين ليس لديهم تكامل التقويم',
  today: 'اليوم',
  send: 'إرسال',
  dontSend: 'لا ترسل',
  dialog: {
    selfSchedulingMessage: 'سيؤدي هذا إلى إرسال بريد إلكتروني إلى المرشح لحجز موعد للمقابلة. لا يمكن التراجع عن هذا الإجراء.',
    interviewSchedulingMessage: 'سيتم إرسال بريد إلكتروني إلى المرشح والمقابلين مع تفاصيل الحدث.',
    title: 'هل أنت متأكد من',
    scheduleEventTitle: 'هل أنت متأكد من إرسال الدعوة',
    likeToSendUpdates: 'هل ترغب في إرسال رسائل بريد إلكتروني محدثة إلى المرشح والمقابلات؟',
    description: 'سيتم إضافة المقابلين المحددين كشركاء تعاون إلى هذه الوظيفة ويمكنهم عرض جدول زمني للمرشح.',
    sendBulkConfirmation: 'هل أنت متأكد أنك تريد إرسال الدعوات بشكل جماعي',
    sureToSendBulkEmails: 'هل أنت متأكد أنك تريد إرسال دعوة إلى {length} مرشحين؟ (ملاحظة: لا يمكن التراجع عن هذا الإجراء.)'
  },
  successMessages: {
    rescheduled: 'تم إعادة جدولة المقابلة.',
    sentInvite: 'تمت دعوة المرشح بنجاح.'
  },
  refreshTemplateList: 'تحديث قوالب التعليقات',
  addNewTemplate: 'إضافة قالب جديد',
  addInterviewScorecard: 'أضف قالب تعليقات المقابلة',
  interviewScorecardNotNeeded: 'قالب ردود الفعل المقابلة ليست هناك حاجة',
  atLeastOneInterviewer: 'يرجى اختيار محاور واحد على الأقل',
  candidate: '- المرشح',
  pickADate: 'اختر تاريخًا',
  interviewers: 'المحاورين',
  title: 'عنوان',
  interviewScorecard: 'قالب ردود الفعل المقابلة',
  location: 'قالب ردود الفعل',
  description: 'وصف',
  descriptionAndEmail: 'الوصف والبريد الإلكتروني',
  howToScheduleInterview: 'كيف تريد جدولة المقابلة؟',
  scheduleBookingLink: 'جدولة رابط الحجز',
  recordMeeting: 'سجل ودوّن الملاحظات في هذا الاجتماع'
}

export const InterviewSchedulingEnglish = {
  schedule: 'Schedule',
  addOrganizer: 'Add organizer',
  addInterviewer: 'Add interviewer',
  addTeamMember: 'Add new team member',
  addTitle: 'Add title',
  addLocation: 'Add location',
  refreshUsers: 'Refresh users list',
  organizer: '- Organizer',
  userNotIntegrated: 'You haven\'t integrated your calendar. Please integrate your calendar | We can\'t access {email}, Please contact your admin to enable calendar sharing',
  notAbleToFetchCalendarEvents: 'We can\'t access {name} ({email}), Please contact your admin to enable calendar sharing',
  userNotAllowedToCreateDeleteUpdateEvents: 'User had not allowed to create, delete and update events in their calendar',
  selectTime: 'Select time on calendar',
  eventLocation: 'Select event location',
  googleMeetLink: 'We will generate a Google Meet link for this event.',
  microsoftTeamsLink: 'We will generate a Microsoft Teams link for this event.',
  addDescription: 'Add description',
  today: 'Today',
  send: 'Send',
  dontSend: 'Don\'t send',
  withoutIntegration: 'Please remove uses who do not have their calendars integrated',
  withIntegration: 'Please fill all required fields',
  dialog: {
    selfSchedulingMessage: 'This will send an email to the candidate to book the interview slot. This action cannot be undone.',
    interviewSchedulingMessage: 'This will send an email to the candidate and interviewers with event details.',
    title: 'Are you sure to',
    scheduleEventTitle: 'Are you sure to send invite',
    likeToSendUpdates: 'Would you like to send update emails to the candidate and interviewers?',
    description: 'Selected interviewers will be added as collaborators to this job and can view candidates timeline.',
    sendBulkConfirmation: 'Are you sure you want to send invitations in bulk',
    sureToSendBulkEmails: 'Are you sure you want to send invite to {length} candidates? (Note: This action cannot be undone.)'
  },
  successMessages: {
    rescheduled: 'Interview rescheduled.',
    sentInvite: 'Successfully invited the candidate.'
  },
  refreshTemplateList: 'Refresh feedback templates',
  addNewTemplate: 'Add new template',
  addInterviewScorecard: 'Add interview feedback template',
  interviewScorecardNotNeeded: 'Interview feedback template not needed',
  atLeastOneInterviewer: 'Please select at least 1 interviewer',
  candidate: '- Candidate',
  interviewers: 'Interviewers',
  title: 'Title',
  interviewScorecard: 'Interview feedback template',
  location: 'Location',
  description: 'Description',
  pickADate: 'Pick a Date',
  descriptionAndEmail: 'Description and email',
  howToScheduleInterview: 'How do you want to schedule interview?',
  scheduleBookingLink: 'Schedule booking link',
  recordMeeting: 'Record and take notes in this meeting'
}
