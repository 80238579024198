export const CvParsedEnglish = {
  education: 'Education',
  grade: 'Grade: {grade}',
  percentage: 'Percentage: {percentage}%',
  industry: 'Industry',
  languages: 'Languages',
  skills: 'Skills',
  workExperience: 'Work experience',
  location: 'Location'
}

export const CvParsedArabic = {
  education: 'تعليم',
  grade: 'الدرجة: {grade}',
  percentage: 'النسبة المئوية: {percentage}%',
  industry: 'صناعة',
  languages: 'لغات',
  skills: 'مهارات',
  workExperience: 'الخبرة العملية',
  location: 'موقع'
}
