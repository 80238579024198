
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  name: 'WcIconQuestionMark'
})
export default class WcIconQuestionMark extends Vue {
  @Prop({ default: '#000000' })color !: string
  @Prop({ default: '20' }) height!: string
  @Prop({ default: '20' }) width!: string
}
