
import { Component, Prop, Vue } from 'vue-property-decorator'
@Component({
  name: 'WcIconArrowLeftFull'
})
export default class extends Vue {
  @Prop({ default: '#ffffff' }) color!: String
  @Prop({ default: '24' }) height!: string
  @Prop({ default: '24' }) width!: string
}
