
import { Component, Prop, Vue } from 'vue-property-decorator'
@Component({
  name: 'WcIconSuitcase',
  components: {}
})
export default class WcIconCircleCheck extends Vue {
  @Prop({ default: '16' }) width!: string;
  @Prop({ default: '16' }) height!: string;
  @Prop({ default: '#000000' }) color!: string
}
