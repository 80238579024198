
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'WcIconSearch',
  components: {},
  props: {
    color: String
  }
})
export default class extends Vue {
  @Prop() private color!: String
}
