import Resource from '@/api/resources/base-resource'
import request from '@/utils/request'
import {
  LinkedinIntegrationJobsCount
} from '@/views/settings/integrations/job-boards/linked-in-job-board/models/linkedin-integration'

class CompanyRolesResource extends Resource {
  constructor() {
    super('company-roles')
  }

  getAll() {
    return request({
      url: '/' + this.uri + '/admin/all',
      method: 'get'
    })
  }

  companyUsers(query = '') {
    return request({
      url: '/' + this.uri + '/company-users' + query,
      method: 'get'
    })
  }

  companyUsersDropdown(query = '') {
    return request({
      url: '/' + this.uri + '/company-users/dropdown' + query,
      method: 'get'
    })
  }

  dropdown() {
    return request({
      url: '/' + this.uri + '/dropdown',
      method: 'get'
    })
  }

  userJobs(userId: string) {
    return request({
      url: '/' + this.uri + '/user/' + userId,
      method: 'get'
    })
  }

  getLink() {
    return request({
      url: '/' + this.uri + '/merge/link-token',
      method: 'get'
    })
  }

  getAccountToken(payload) {
    return request({
      url: '/' + this.uri + '/merge/account-token',
      method: 'post',
      data: payload
    })
  }

  getRolesForCompany(query: string) {
    return request({
      url: '/' + this.uri + '' + query,
      method: 'get'
    })
  }

  listJobs(query: string) {
    return request({
      url: '/' + this.uri + '/list' + query,
      method: 'get'
    })
  }

  listJobsForReferrals(query: string) {
    return request({
      url: '/' + this.uri + '/referrals' + query,
      method: 'get'
    })
  }

  getOneRole(roleId) {
    return request({
      url: '/' + this.uri + '/' + roleId,
      method: 'get'
    })
  }

  updateStatus(jobRoleId: string, status: string) {
    return request({
      url: '/' + this.uri + '/updateRoleStatus/' + jobRoleId,
      method: 'post',
      data: { status: status }
    })
  }

  deleteJobRole(jobRoleId: string) {
    return request({
      url: '/' + this.uri + '/deleteJob/' + jobRoleId,
      method: 'delete'
    })
  }

  updateRoleByJobRoleId(payload: any, jobRoleId: string) {
    return request({
      url: '/' + this.uri + '/update-role/' + jobRoleId,
      method: 'post',
      data: payload
    })
  }

  getAtsRolesForCompany() {
    return request({
      url: '/' + this.uri + '/getAtsRoles',
      method: 'get'
    })
  }

  async copyJobJourney(jobRoleId: string, payload): Promise<{ id: string }> {
    return request({
      url: '/' + this.uri + '/copyJobJourney/' + jobRoleId,
      method: 'post',
      data: payload
    }).then((res) => res.data as { id: string })
  }

  publishJobJourney(jobRoleId: string) {
    return request({
      url: '/' + this.uri + '/publish/' + jobRoleId,
      method: 'patch'
    })
  }

  generateCustomLink(jobRoleId, payload) {
    return request({
      url: '/' + this.uri + `/${jobRoleId}` + '/custom-links',
      data: payload,
      method: 'post'
    })
  }

  deleteCustomLinkByPayload(jobRoleId, payload) {
    return request({
      url: '/' + this.uri + `/${jobRoleId}` + '/delete-custom-links',
      data: payload,
      method: 'patch'
    })
  }

  refreshRolesByForce() {
    return request({
      url: '/' + this.uri + '/refresh-ats-roles',
      method: 'get'
    })
  }

  createOrUpdateJobCollaboratorPermissions(payload) {
    return request({
      url: '/' + this.uri + '/collaborator-permissions',
      method: 'post',
      data: payload
    })
  }

  async getLinkedInIntegrationCountsOfPublishedJobs(): Promise<LinkedinIntegrationJobsCount> {
    return request({
      url: `/${this.uri}/linkedin-integrations`,
      method: 'GET'
    }).then((response) => response.data as LinkedinIntegrationJobsCount)
  }

  async updateLinkedInPostingStatusForAllJobs(payload: { canPostToLinkedin: boolean}): Promise<void> {
    await request({
      url: `/${this.uri}/update-linkedin-integrations`,
      method: 'POST',
      data: payload
    })
  }

  userAccessibleJobs() {
    return request({
      url: '/' + this.uri + '/user-accessible-jobs',
      method: 'get'
    })
  }

  /**
   * Returns boolean
   * @params companyId
   */
  showExploreProductCard(companyId: string) {
    return request({
      url: '/' + this.uri + '/show-explore-product-card/' + companyId,
      method: 'get'
    })
  }
}

export { CompanyRolesResource as default }
