
import { UserModule } from '@/store/modules/user'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { JobRoleAccessTypeEnum } from '@/views/recruiter/job-role/models/enums'
import { isMobile } from '@/utils/general'
import WcTypography from '@/components/UILibrary/Typography/WcTypography.vue'
import { PermissionsEnum } from '@/permissions/permissions-enum'
import {
  CAREER_BRAND_PAGE_PERMISSIONS,
  COMPANY_ARCHIVAL_REASONS_MANAGE_PERMISSIONS,
  COMPANY_BOOKING_LINKS_MANAGE, USER_CALENDAR_EMAIL_INTEGRATIONS_MANAGE, COMPANY_EMAIL_TEMPLATES_MANAGE,
  COMPANY_STAGE_PIPELINE_MANAGE_PERMISSIONS,
  COMPANY_TEAM_MEMBERS_MANAGE
} from '@/permissions/permissions-constants'

@Component({
  name: 'recruiter-nav-dropdown',
  components: { WcTypography },
  computed: {
    JobRoleAccessTypeEnum() {
      return JobRoleAccessTypeEnum
    },
    PermissionsEnum() {
      return PermissionsEnum
    }
  },
  methods: {
    isMobile,
    UserModule() {
      return UserModule
    },
    openExtensionPage() {
      const url = 'https://chrome.google.com/webstore/detail/whitecarrot-extension/kihdfipgbnhlckakgichedgelmkpagck?hl=en'
      window.open(url, '_blank')
    },
    openHelpCenter() {
      const url = 'https://whitecarrot.notion.site/Whitecarrot-io-Help-Centre-9add74c9165a4a2aa6dec8d57cb64f1b'
      window.open(url, '_blank')
    }
  }
})
export default class RecruiterNavbarDropdownMenu extends Vue {
  @Prop() user!: {
    username: string
    email: string
    avatar: string
  }

  get getCareerBrandPagePermissions(): PermissionsEnum[] {
    return CAREER_BRAND_PAGE_PERMISSIONS
  }

  get getTeamsPagePermissions(): PermissionsEnum[] {
    return COMPANY_TEAM_MEMBERS_MANAGE
  }

  get stagePipelineArchivalReasonPermissions(): PermissionsEnum[] {
    return [...COMPANY_STAGE_PIPELINE_MANAGE_PERMISSIONS, ...COMPANY_ARCHIVAL_REASONS_MANAGE_PERMISSIONS]
  }

  get getCompanyBookingLinksManagePermissions(): PermissionsEnum[] {
    return COMPANY_BOOKING_LINKS_MANAGE
  }

  get getCompanyEmailTemplateManagePermissions(): PermissionsEnum[] {
    return COMPANY_EMAIL_TEMPLATES_MANAGE
  }

  get getCalendarEmailIntegrationsPermissions(): PermissionsEnum[] {
    return USER_CALENDAR_EMAIL_INTEGRATIONS_MANAGE
  }

  get getRecruitingPagesPermissions(): PermissionsEnum[] {
    return [
      ...this.stagePipelineArchivalReasonPermissions,
      ...COMPANY_BOOKING_LINKS_MANAGE,
      ...COMPANY_EMAIL_TEMPLATES_MANAGE
    ]
  }
}

