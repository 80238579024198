import Resource from '@/api/resources/base-resource'

export enum AppConfigEnum {
  LANGUAGE_SUPPORT = 'language_support',
  REPORTS = 'reports',
  MEETING_BOT_RECORDING_INTEGRATION = 'meeting_bot_recording_integration',
  LINKEDIN_INTEGRATION = 'linkedin_integration',
  JOB_TARGET_INTEGRATION = 'job_target_integration'
}

export interface IDynamicConfigs {
  id: string
  name: AppConfigEnum
  value: string
  createdAt: Date
  updatedAt: Date
  companyId: string
}

class DynamicConfigsResource extends Resource {
  constructor() {
    super('dynamic-app-configs')
  }
}

export { DynamicConfigsResource as default }
