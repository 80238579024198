
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class WcIconStar extends Vue {
  @Prop({ default: '#5138EE' }) color!: string
  @Prop({ default: 'rgba(4, 4, 16, 0.87)' }) strokeColor!: string
  @Prop({ default: '16' }) height!: string
  @Prop({ default: '16' }) width!: string
}
